/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
// import Modal from "@mui/material/Modal";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormGroup from "@mui/material/FormGroup";
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Divider from "@mui/material/Divider";
import Select from '@mui/material/Select';
import { Icon, Input } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import { FormHelperText } from "@mui/material";

import { getInventoryRawMaterials, getInventoryFinishedGoods, createRecipe, updateRecipe, getRecipeIngredients, getUOM } from "network/service";
import { useNotification } from "context/notification.context";

export default function AddEditRecipePopup(props) {
    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(false);
    const [newField, setNewField] = useState({ name: "", code: "", product: "", quantity: "0", description: "" });
    const [allIngredients, setAllIngredients] = useState([]);
    const [selIngredient, setSelIngredient] = useState(null);
    const [allFinishedProducts, setAllFinishedProducts] = useState([]);
    const [tempIngredient, setTempIngredient] = useState({ ingredient: null, quantity: 0 });
    const [recipeIngredients, setRecipeIngredients] = useState([]);
    const [errors, setErrors] = useState({ name: false, code: false, product: false, quantity: false, description: false, count: false });
    const [subErrors, setSubError] = useState({ ingredient: false, quantity: false });
    const [submitted, setSubmitted] = useState(false);
    useEffect(() => {
        if (props.details) {
            setNewField({ name: props.details.RecipeName, code: props.details.RecipeCode, product: props.details.Product, quantity: props.details.Quantity.toString(), description: props.details.Description });
            getRecipeIngredients(props.details._id).then((resp) => {
                console.log("Recipe Ingredients", resp.data);
                let ingrdnts = [...resp.data];
                getUOM().then(async (resp1) => {
                    console.log(resp1.data);
                    for (let ing in ingrdnts) {
                        for (let mu of resp1.data) {
                            if (ingrdnts[ing].Product.UnitOfMeasure === mu._id) {
                                ingrdnts[ing].Product.UnitOfMeasure = { ...mu };
                                break;
                            }
                        }
                    }
                    // console.log("Formed ingredient rows", ingrdnts)
                    setRecipeIngredients(ingrdnts);
                })
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [setNewField, setRecipeIngredients]);


    useEffect(() => {
        getInventoryRawMaterials().then((resp) => {
            console.log(resp.data);
            setAllIngredients(resp.data);
        }).catch((err) => {
            console.log("Error in getting Inventory Raw Materials", err);
        })
    }, [setAllIngredients]);

    useEffect(() => {
        getInventoryFinishedGoods().then(async (resp) => {
            console.log(resp.data);
            let temp = [];
            for (let rst of resp.data) {
                temp.push(rst.Product);
            }
            setAllFinishedProducts(temp);
        }).catch((err) => {
            console.log("Error in getting Inventory Finished Goods", err);
        })
    }, [setAllFinishedProducts]);

    const getIngredientOptions = (allIngrediets, recipeIngrdnts) => {
        let result = [];
        for (let ingrdnt of allIngrediets) {
            let found = false;
            for (let ringrdnt of recipeIngrdnts) {
                if (ringrdnt.Product._id === ingrdnt.Product._id) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                result.push(ingrdnt);
            }
        }
        return result;
    }


    const formRecipeIngredientRows = (datum) => {
        let result = [];
        for (let data in datum) {
            result.push({
                name: recipeIngredients[data].Product.ProductName,
                quantity: recipeIngredients[data].Quantity,
                uom: recipeIngredients[data].Product.UnitOfMeasure.name,
                action: <MDButton variant="outlined" color="error"
                    iconOnly
                    onClick={() => { deleteRecipeIngredient(data) }}>
                    <Icon>delete</Icon>
                </MDButton>
            });
        }
        return result;
    }

    const addIngredient = () => {
        if (selIngredient && (parseFloat(tempIngredient.quantity) > 0)) {
            setSubError({
                ingredient: false,
                quantity: false
            });
            setRecipeIngredients([...recipeIngredients, { ...selIngredient, Quantity: parseFloat(tempIngredient.quantity) }]);
            setTempIngredient({ quantity: "0" });
            setSelIngredient(null);
        }
        else {
            setSubError({
                ingredient: (!selIngredient),
                quantity: ((parseFloat(tempIngredient.quantity) <= 0) || (tempIngredient.quantity === ''))
            });
        }
    }

    const deleteRecipeIngredient = (index) => {
        let tempCopy = [...recipeIngredients];
        tempCopy.splice(index, 1);
        setRecipeIngredients([...tempCopy]);
    }
    const validate = () => {
        const newErrors = {
            name: newField.name.length === 0,
            code: newField.code.length === 0,
            product: !newField.product,
            quantity: (newField.quantity === '') || (parseFloat(newField.quantity) <= 0),
            description: newField.description.length === 0,
            ingredients: recipeIngredients.length === 0
        };

        setErrors(newErrors);

        // Check if any field has an error
        return !Object.values(newErrors).some(error => error);
    };


    const Create = async () => {
        setSubmitted(true);
        if (validate()) {
            try {
                setLoading(true);
                if (props.details?._id) {
                    //Update Procedure
                    updateRecipe(props.details._id, {
                        Product: newField.product._id,
                        RecipeCode: newField.code,
                        RecipeName: newField.name,
                        Description: newField.description,
                        Quantity: parseFloat(newField.quantity),
                        ingredients: [...recipeIngredients]
                    }).then((resp) => {
                        console.log(resp.data);
                        setLoading(true);
                        showNotification("success", "Success", "Added the recipe successfully");
                        props.handlesuccess();
                    }).catch((err) => {
                        setLoading(true);
                        showNotification("error", "Failed", "Failed to add recipe details.");
                    })
                }
                else {
                    createRecipe({
                        Product: newField.product._id,
                        RecipeCode: newField.code,
                        RecipeName: newField.name,
                        Description: newField.description,
                        Quantity: parseFloat(newField.quantity),
                        ingredients: [...recipeIngredients]
                    }).then((resp) => {
                        console.log(resp.data);
                        setLoading(true);
                        showNotification("success", "Success", "Updated the recipe edited successfully");
                        props.handlesuccess();
                    }).catch((err) => {
                        setLoading(true);
                        showNotification("error", "Failed", "Failed to add recipe details.");
                    })
                }
            }
            catch (err) {
                console.log(err);
                setLoading(false);
            }

        }
    }


    return (
        <Dialog
            id="modal-display"
            open={props.isopen}
            aria-labelledby="form-dialog-title"
            aria-describedby="modal-modal-description"
            maxWidth="90%"
            maxHeight="90%"
            onClose={props.handleclose}
            disableBackdropClick
        >
            <MDBox>
                <DialogTitle>Add/Edit Recipe</DialogTitle>
            </MDBox>
            <DialogContent sx={{ minWidth: 500 }}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6} xl={8}>
                                <MDInput
                                    label="Recipe Name"
                                    value={newField.name}
                                    fullWidth

                                    onChange={(e) => {
                                        setNewField({
                                            ...newField,
                                            name: e.target.value.toUpperCase()
                                        });
                                    }}
                                    helperText={<FormHelperText sx={{ color: 'red', fontWeight: 800, margin: 0 }}>{errors.name && "Recipe name is required"}</FormHelperText>}
                                />

                            </Grid>
                            <Grid item xs={12} md={6} xl={4}>
                                <MDInput
                                    fullWidth
                                    label="Recipe Code"
                                    value={newField.code}

                                    onChange={(e) => {
                                        setNewField({
                                            ...newField,
                                            code: e.target.value.toUpperCase()
                                        });
                                    }}
                                    helperText={<FormHelperText sx={{ color: 'red', fontWeight: 800, margin: 0 }}>{errors.code && "Recipe code is required"}</FormHelperText>}

                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6} xl={6}>
                                <Autocomplete
                                    disableClearable
                                    label={"Product"}
                                    sx={{ flexGrow: 1 }}
                                    options={allFinishedProducts}
                                    getOptionLabel={(option) => option.ProductName || ''}
                                    renderInput={(params) => <MDInput {...params} label={"Product"} fullWidth />}
                                    onChange={(event, newValue) => {
                                        setNewField({
                                            ...newField,
                                            product: newValue
                                        });
                                    }}
                                    error={!newField.uom || newField.uom === ""}
                                    value={newField.product}
                                />
                                {submitted && (!newField.product || newField.product === "") && (
                                    <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 2 }}>
                                        Product is required
                                    </FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12} md={4} xl={4}>
                                <MDInput
                                    fullWidth
                                    label="Quantity"
                                    value={newField.quantity}
                                    type="number"

                                    onChange={(e) => {
                                        setNewField({
                                            ...newField,
                                            quantity: e.target.value.toUpperCase()
                                        });
                                    }}
                                    helperText={<FormHelperText sx={{ color: 'red', fontWeight: 800, margin: 0 }}>{errors.quantity && "Quantity  is required"}</FormHelperText>}
                                />
                            </Grid>
                            <Grid item xs={12} md={2} xl={2}>
                                <MDInput
                                    fullWidth
                                    label="UOM"
                                    disabled
                                    value={newField.product ? newField.product.UnitOfMeasure.name : ""}
                                    onChange={(e) => { }}

                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <MDInput
                            label="Description"
                            multiline
                            rows={1}
                            fullWidth
                            value={newField.description}
                            onChange={(e) => {
                                setNewField({
                                    ...newField,
                                    description: e.target.value
                                });
                            }}
                            helperText={<FormHelperText sx={{ color: 'red', fontWeight: 800, margin: 0 }}>{errors.description && "Description  is required"}</FormHelperText>}

                        />
                    </Grid>
                    <Divider />
                    <Grid item>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6} xl={6}>
                                <Autocomplete
                                    disableClearable
                                    label={"Ingredient"}
                                    sx={{ flexGrow: 1 }}
                                    options={getIngredientOptions(allIngredients, recipeIngredients)}
                                    getOptionLabel={(option) => option.Product.ProductName || ''}
                                    renderInput={(params) => <MDInput {...params} label={"Ingredient"} fullWidth
                                        helperText={<FormHelperText sx={{ color: 'red', fontWeight: 800, margin: 0 }}>{subErrors.ingredient && "Choose an ingredient"}</FormHelperText>} />}
                                    onChange={(event, newValue) => {
                                        setSelIngredient(newValue);
                                    }}
                                    value={selIngredient}

                                />
                            </Grid>
                            <Grid item xs={12} md={6} xl={6}>
                                <Grid container sx={{ alignItems: 'center' }}>
                                    <MDInput
                                        sx={{ flexGrow: 1 }}
                                        label="Quantity"
                                        value={tempIngredient.quantity}
                                        type="number"
                                        error={tempIngredient.quantity.length <= 0}
                                        onChange={(e) => {
                                            setTempIngredient({
                                                ...tempIngredient,
                                                quantity: e.target.value
                                            });
                                        }}
                                        helperText={<FormHelperText sx={{ color: 'red', fontWeight: 800, margin: 0 }}>{subErrors.quantity && "Enter a valid Quantity"}</FormHelperText>}
                                    />
                                    <MDButton variant="outlined" color="success"
                                        iconOnly
                                        disabled={loading}
                                        sx={{ marginLeft: 1 }}
                                        onClick={() => { addIngredient() }}>
                                        <Icon>add</Icon>
                                    </MDButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <DataTable
                            table={{
                                columns: [
                                    { Header: "Name", accessor: "name", align: "left" },
                                    { Header: "Quantity", accessor: "quantity", align: "center" },
                                    { Header: "Unit", accessor: "uom", align: "center" },
                                    { Header: "Action", accessor: "action", align: "center" },
                                ],
                                rows: formRecipeIngredientRows(recipeIngredients)
                            }}
                            showTotalEntries={false}
                            isSorted={false}
                            noEndBorder
                            entriesPerPage={false}
                        />
                        <MDBox>
                            <FormHelperText sx={{ color: 'red', alignSelf: 'center', fontWeight: 600, textAlign: "center", marginRight: "20px", marginTop: 4 }}>
                                {errors.ingredients ? 'Add atleast One Ingredient' : ""}
                            </FormHelperText>
                        </MDBox>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>

                <MDButton variant="gradient" color="error" onClick={props.handleclose}
                    disabled={loading}>
                    <Icon>close</Icon>&nbsp;
                    Cancel
                </MDButton>
                <MDButton variant="gradient" color="success"
                    disabled={loading}
                    onClick={() => { Create() }}>
                    <Icon>save</Icon>&nbsp;
                    Save
                </MDButton>
            </DialogActions>
        </Dialog>

    )
};
