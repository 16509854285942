import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import NotificationItem from "../NotificationItem";

function NotificationOverview({ isDark, notifications }) {
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Notifications overview
        </MDTypography>
        <MDBox mt={0} mb={2}>
          <MDTypography variant="button" color="text" fontWeight="regular">
            <MDTypography display="inline" variant="body2" verticalAlign="middle">
              <Icon sx={{ color: ({ palette: { warning } }) => warning.main }}>warning</Icon>
            </MDTypography>
            &nbsp;
            <MDTypography variant="button" color="text" fontWeight="medium">
              24
            </MDTypography>{" "}
            Alarms
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox p={2} sx={{ height: "100%", overflow: 'auto' }}>
        {notifications.map((data, ind) => {
          return (<NotificationItem
            color={data.color}
            icon={data.icon}
            title={data.title}
            isDark={isDark}
            dateTime={data.dateTime}
          />)
        })}
      </MDBox>
    </Card>
  );
}

export default NotificationOverview;
