/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
// import Modal from "@mui/material/Modal";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Divider from "@mui/material/Divider";
import { Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";

import { getBilldetails } from "network/service";
import { useNotification } from "context/notification.context";
import moment from "moment";

export default function ViewBillDetailsPopup(props) {
    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(false);
    const [newField, setNewField] = useState({ name: "", code: "", product: null, quantity: "0", description: "" });
    const [allIngredients, setAllIngredients] = useState([]);
    const [selIngredient, setSelIngredient] = useState(null);
    const [allFinishedProducts, setAllFinishedProducts] = useState([]);
    const [tempIngredient, setTempIngredient] = useState({ ingredient: null, quantity: 0 });
    const [recipeIngredients, setRecipeIngredients] = useState([]);

    const [billItems, setBillItems] = useState([]);

    useEffect(() => {
        if (props.details) {
            getBilldetails(`Bill=${props.details._id}`).then((resp) => {
                console.log("Bill details", resp.data);
                setBillItems(resp.data);
            }).catch((err) => {
                console.log(err);
            })
        }
    }, []);



    const formBillItemRows = (datum) => {
        let result = [];
        for (let data of datum) {
            result.push({
                name: data.InventoryID.Product.ProductName,
                quantity: data.Quantity,
                rate: data.UnitPrice,
                amount: data.Subtotal
            });
        }
        result.push({
            rate: "Total",
            amount: props.details.TotalAmount
        });
        return result;
    }

    return (
        <Dialog
            id="modal-display"
            open={props.isopen}
            aria-labelledby="form-dialog-title"
            aria-describedby="modal-modal-description"
            maxWidth="90%"
            maxHeight="90%"
            onClose={props.handleclose}
            disableBackdropClick
        >
            <MDBox>
                <DialogTitle>Bill Details</DialogTitle>
            </MDBox>
            <DialogContent sx={{ minWidth: 500 }}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={4} xl={4}>
                                <MDInput
                                    label="Supplier Name"
                                    value={props.details.SupplierID.SupplierName}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={4} xl={4}>
                                <MDInput
                                    label="Bill No."
                                    value={props.details.BillNumber}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={4} xl={4}>
                                <MDInput
                                    label="Date"
                                    value={moment(props.details.createdAt).format("DD-MM-YYYY")}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid item>
                        <DataTable
                            table={{
                                columns: [
                                    { Header: "Item", accessor: "name", align: "center" },
                                    { Header: "Quantity", accessor: "quantity", align: "center" },
                                    { Header: "Rate", accessor: "rate", align: "center" },
                                    { Header: "Amount", accessor: "amount", align: "center" },
                                ],
                                rows: formBillItemRows(billItems)
                            }}
                            showTotalEntries={false}
                            isSorted={false}
                            noEndBorder
                            entriesPerPage={false}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <MDButton variant="gradient" color="error" onClick={props.handleclose}
                    disabled={loading}>
                    <Icon>close</Icon>&nbsp;
                    Close
                </MDButton>
            </DialogActions>
        </Dialog>

    )
};
