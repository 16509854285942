import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { FormHelperText, Icon } from "@mui/material";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useNotification } from "context/notification.context";
import Autocomplete from "@mui/material/Autocomplete";
import moment from "moment";
import { getEquipements, getSections, getManufacturingPlant, getMaintainanceTypes } from "network/service";
import { getMaintenanceLogs, addMaintenanceLogs, updateMaintenanceLogs } from "network/service";

export default function AddMaintenanceLogPopUp(props) {
    const [newField, setNewField] = useState({ manuplant: "", section: "", mantype: "", schevent: "", eqpname: "", startdate: moment().format("YYYY-MM-DD"), starttime: moment().format("HH:mm"), enddate: moment().format("YYYY-MM-DD"), endtime: moment().format("HH:mm"), maintaincecost: "", description: "", result: "" });
    const [errors, setErrors] = useState({});
    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(false);
    const [maintanceLog, setMaintanceLog] = useState();
    const [section, setSection] = useState([]);
    const [equipment, SetEquipment] = useState([])
    const [selEquipmentName, setSelEquimentName] = useState(null)
    const [selSection, setSelSection] = useState(null);
    const [manuPlant, setManuPlant] = useState([]);
    const [selManuPlant, setSelManuPlant] = useState(null);
    const [startdate, setStartDate] = useState(new Date());
    const [enddate, setEndDate] = useState(new Date());
    const [startTime, setStartTime] = useState(moment());
    const [endTime, setEndTime] = useState(moment());
    const [selManuResult, setSelManuResult] = useState(null);
    const [maintanceType, setMaintanceType] = useState([]);
    const [selMaintanceType, setSelMaintanceType] = useState(null)
    const date = (new Date());
    const starteddate = new Date(newField.startdate);

    const validate = () => {
        let isValid = true;
        let newErrors = {};

        if (!selManuPlant) {
            newErrors = { ...newErrors, manuplant: "Manufacturing plant is required" };
            isValid = false;
        }
        if (!selSection) {
            newErrors = { ...newErrors, section: "Section is required" };
            isValid = false;
        }
        if (!selEquipmentName) {
            newErrors = { ...newErrors, eqpname: "Equipment name is required" };
            isValid = false;
        }
        if (!selManuResult) {
            newErrors = { ...newErrors, result: "Result value is required" };
            isValid = false;
        }
        if (!selMaintanceType) {
            newErrors = { ...newErrors, mantype: " Type is required" };
            isValid = false;
        }
        if (String(newField.maintaincecost).trim() === '') {
            newErrors = { ...newErrors, maintaincecost: "Maintenance cost is required" };
            isValid = false;
        }
        let start_date_temp = moment(`${moment(newField.startdate).format("YYYY-MM-DD")} ${newField.starttime}`).toDate();
        let end_date_temp = moment(`${moment(newField.enddate).format("YYYY-MM-DD")} ${newField.endtime}`).toDate();

        if (newField.startdate === 0) {
            newErrors = { ...newErrors, startdate: "Start date is required" };
            isValid = false;
        }
        else if (start_date_temp > new Date()) {
            newErrors = { ...newErrors, startdate: "Start date cannot be in future" }
        }
        if (newField.enddate === 0) {
            newErrors = { ...newErrors, enddate: "End date is required" };
            isValid = false;
        } else if (end_date_temp <= start_date_temp) {
            newErrors = { ...newErrors, enddate: "End date must be greater than start date" };
            isValid = false;
        } else if (end_date_temp > new Date()) {
            newErrors = { ...newErrors, enddate: "End date cannot be in future" };
            isValid = false;
        }

        if (newField.description.trim() === "") {
            newErrors = { ...newErrors, description: "Description is required" };
            isValid = false;
        }



        console.log("Validation errors:", newErrors);
        console.log("Is valid:", isValid);
        setErrors(newErrors);
        return isValid;
    }
    useEffect(() => {
        getMaintenanceLogs().then((resp) => {
            setMaintanceLog();
        }).catch((err) => {
            console.log("Error fetching MaintainceLogs", err);
        })
    }, [setMaintanceLog]);

    // Options Fetching
    const fetchEquipmentName = (param) => {
        getEquipements(param).then((resp) => {
            console.log(resp.data);
            SetEquipment(resp.data);
        }).catch((err) => {
            console.log(err);
            SetEquipment([]);
        })
    };
    const fetchSection = (params) => {
        getSections(params).then((resp) => {
            console.log(resp.data);
            setSection(resp.data);
        }).catch((err) => {

            setSection([]);
            console.log(err);
        })
    }
    const fetchManuplant = () => {
        getManufacturingPlant().then((resp) => {
            console.log(resp.data);
            setManuPlant(resp.data);
        }).catch(() => {

            setManuPlant(resp.data);
        })
    }
    const fetchMaintanceType = () => {
        getMaintainanceTypes().then((resp) => {
            console.log(resp.data);
            setMaintanceType(resp.data);
        }).catch(() => {

            setMaintanceType(resp.data);
        })
    }


    useEffect(() => {
        fetchManuplant();
        fetchMaintanceType();

    }, []);

    useEffect(() => {
        if (selManuPlant) {
            fetchSection(`ManufacturingPlant=${selManuPlant._id} `);
            // setSelSection(null);
        }
        else {
            setSection([]);
            // setSelSection(null);
        }
    }, [selManuPlant]);

    useEffect(() => {
        if (section.length > 0) {
            if (props.details) {
                for (let scn of section) {
                    if (scn._id === props.details.Equipment.Section._id) {
                        setSelSection({ ...scn });
                    }
                }
            }
        }
        else {
            setSelSection(null);
        }
    }, [section]);

    useEffect(() => {
        if (equipment.length > 0) {
            if (props.details) {
                for (let scn of equipment) {
                    if (scn._id === props.details.Equipment._id) {
                        setSelEquimentName({ ...scn });
                    }
                }
            }
        }
        else {
            setSelEquimentName(null);
        }
    }, [equipment]);

    useEffect(() => {
        if (selSection) {
            fetchEquipmentName(`Section=${selSection._id} `);
            // setSelEquimentName(null);
        }
        else {
            SetEquipment([]);
            // setSelEquimentName(null);
        }
    }, [selSection]);



    const submit = async () => {

        try {
            const isValid = validate();
            if (isValid) {
                setLoading(true);

                const MaintenanceLogsData = {
                    //  ManufacturingPlant: selManuPlant._id,
                    //   SectionName: selSection._id,
                    Equipment: selEquipmentName._id,
                    MaintenanceType: selMaintanceType._id,
                    StartTime: new Date(moment(`${newField.startdate}T${newField.starttime} `).toDate()),
                    EndTime: new Date(moment(`${newField.enddate}T${newField.endtime} `).toDate()),
                    //   MaintenanceSchedule: newField.schevent,
                    MaintenanceDescription: newField.description,
                    MaintenanceCost: parseFloat(newField.maintaincecost),
                    MaintenanceResult: selManuResult
                };

                console.log("Submitting with MaintenanceSchedule:", MaintenanceLogsData);
                if (props.details && props.details._id) {
                    console.log("Updating MaintenanceSchedule with id ", props.details._id)
                    await updateMaintenanceLogs(props.details._id, MaintenanceLogsData);
                    showNotification("success", "Success", "Updated the MaintenanceLogs successfully");
                    props.handlesuccess();
                } else {
                    console.log(MaintenanceLogsData);
                    await addMaintenanceLogs(MaintenanceLogsData);
                    showNotification("success", "Success", "Added the MaintenanceLogs successfully");
                    props.handlesuccess();
                }
                setLoading(false);
                props.handlesuccess();
                props.handleclose();
            }
        } catch (err) {
            console.error("Error adding/updating MaintenanceLogs:", err);
            setLoading(false);
            showNotification("error", "Error", "Failed to add/update MaintenanceLogs.");
        }
    };
    useEffect(() => {
        console.log("props.details:", props.details);
        if (props.details) {
            console.log("Setting newField with props.details:", props.details);
            setSelMaintanceType(props.details.MaintenanceType);
            setSelEquimentName(props.details.Equipment);
            setSelSection(props.details.Equipment.Section);
            setSelManuPlant(props.details.Equipment.Section.ManufacturingPlant);
            setStartDate(moment(props.details.StartTime).toDate());
            setStartTime(moment(props.details.StartTime).toDate());
            setEndDate(moment(props.details.EndTime).toDate());
            setEndTime(moment(props.details.EndTime).toDate());
            setSelManuResult(props.details.MaintenanceResult);
            setNewField({
                manuplant: props.details.ManufacturingPlant,
                section: props.details.Equipment.Section,
                eqpname: props.details.Equipment,
                startdate: moment(props.details.StartTime).format("YYYY-MM-DD"),
                starttime: moment(props.details.StartTime).format("HH:mm"),
                enddate: moment(props.details.EndTime).format("YYYY-MM-DD"),
                endtime: moment(props.details.EndTime).format("HH:mm"),
                result: props.details.MaintenanceResult,
                maintaincecost: props.details.MaintenanceCost,
                description: props.details.MaintenanceDescription,
                maintancetype: props.details.MaintenanceType.TypeName
            });

        }
    }, [props.details]);





    return (
        <Dialog
            id="modal-display"
            open={props.isopen}
            aria-labelledby="form-dialog-title"
            aria-describedby="modal-modal-description"
            maxWidth="90%"
            maxHeight="90%"
            onClose={props.handleclose}
            disableBackdropClick
        >
            <div>
                <DialogTitle>Add / Edit MaintenanceLog</DialogTitle>
            </div>
            <DialogContent sx={{ minWidth: 500 }}>

                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Autocomplete
                            disableClearable
                            label={"Manufacturing Plant"}
                            sx={{ flexGrow: 1 }}
                            options={manuPlant}
                            getOptionLabel={(option) => option.PlantName || ''}
                            renderInput={(params) => <MDInput  {...params} label={"Manufacturing Plant"} fullWidth />}
                            onChange={(event, newValue) => {
                                setSelManuPlant(newValue);
                            }}
                            value={selManuPlant}

                        />
                        {(!newField.manuplant || newField.manuplant === 0) && (
                            <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 1 }}>
                                {errors.manuplant}
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item>
                        <Autocomplete
                            label={"Section"}
                            sx={{ flexGrow: 1 }}
                            options={section}
                            getOptionLabel={(option) => option.SectionName || ''}
                            renderInput={(params) => <MDInput {...params} label={"Section"} fullWidth />}
                            onChange={(event, newValue) => {
                                setSelSection(newValue);
                            }}
                            value={selSection}
                        />
                        {(!newField.section || newField.section === 0) && (
                            <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 1 }}>
                                {errors.section}
                            </FormHelperText>
                        )}

                    </Grid>

                    <Grid item>
                        <Autocomplete
                            label={"Equipment Name"}
                            sx={{ flexGrow: 1 }}
                            error={errors.eqpname}
                            options={equipment}
                            getOptionLabel={(option) => option.EquipmentName || ''}
                            renderInput={(params) => <MDInput  {...params} label={"Equipment Name"} fullWidth />}
                            onChange={(event, newValue) => {
                                setSelEquimentName(newValue);
                            }}
                            value={selEquipmentName}
                        />
                        {(!newField.eqpname || newField.eqpname === 0) && (
                            <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 1 }}>
                                {errors.eqpname}
                            </FormHelperText>
                        )}
                    </Grid>

                    <Grid item>
                        <Autocomplete
                            disableClearable
                            label={"Maintance Type"}
                            error={errors.mantype}
                            sx={{ flexGrow: 1 }}
                            options={maintanceType}
                            getOptionLabel={(option) => option.TypeName || ''}
                            renderInput={(params) => <MDInput  {...params} label={"maintance Type"} fullWidth />}
                            onChange={(event, newValue) => {
                                setSelMaintanceType(newValue);
                            }}
                            value={selMaintanceType}
                        />
                        {(!newField.mantype || newField.mantype === 0) && (
                            <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 1 }}>
                                {errors.mantype}
                            </FormHelperText>
                        )}
                    </Grid>

                    <Grid container mt={1} spacing={2} alignItems="center">
                        <Grid item xs={7} ml={2} xl={7} >
                            <MDInput
                                fullWidth
                                type="date"
                                label={"Start Date "}
                                value={moment(startdate).format("YYYY-MM-DD")}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    setStartDate(value);
                                    setNewField({
                                        ...newField,
                                        startdate: value
                                    });
                                }}



                            />
                            <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 1 }}>
                                {errors.startdate}
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={4.5} >
                            <MDInput
                                fullWidth
                                type="time"
                                label={"Start Time "}
                                value={moment(startTime).format("HH:mm")}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    console.log(value);
                                    if (value) {
                                        setStartTime(moment(value, "HH:mm"));
                                        setNewField({
                                            ...newField,
                                            starttime: value
                                        });
                                        setErrors({
                                            ...errors,
                                            starttime: ''
                                        });
                                    }
                                }}

                            />
                        </Grid>
                    </Grid>


                    <Grid container mt={1} spacing={2} alignItems="center">
                        <Grid item xs={7} ml={2} >
                            <MDInput
                                fullWidth
                                type="date"
                                label={"End Date "}
                                value={moment(enddate).format("YYYY-MM-DD")}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    setEndDate(value);
                                    setNewField({
                                        ...newField,
                                        enddate: value
                                    });
                                }}

                            />
                            <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 1 }}>
                                {errors.enddate}
                            </FormHelperText>
                        </Grid>


                        <Grid item xs={4.5} >

                            <MDInput
                                fullWidth
                                type="time"
                                label={"End Time "}
                                value={moment(endTime).format("HH:mm")}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    if (value) {
                                        setEndTime(moment(value, "HH:mm"));
                                        setNewField({
                                            ...newField,
                                            endtime: value
                                        });
                                        setErrors({
                                            ...errors,
                                            endtime: ''
                                        });
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>


                    <Grid item>
                        <Autocomplete

                            disableClearable
                            label="Manufacturing Result"
                            error={errors.result}
                            helperText={<FormHelperText sx={{ color: 'red', fontWeight: 800 }}>{errors.result}</FormHelperText>}
                            sx={{ flexGrow: 1 }}
                            options={["Success", "Fail"]}
                            getOptionLabel={(option) => option || ''}
                            getOptionSelected={(option, value) => option === value}
                            renderInput={(params) => <MDInput {...params} label="Manufacturing Result" fullWidth />}
                            onChange={(event, newValue) => {

                                setSelManuResult(newValue);
                            }}

                            value={selManuResult}
                        />
                        {(!newField.result || newField.result === 0) && (
                            <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 1 }}>
                                {errors.result}
                            </FormHelperText>
                        )}

                    </Grid>
                    <Grid item>
                        <MDInput
                            label="Maintance Cost"
                            rows={1}
                            value={newField.maintaincecost}
                            fullWidth

                            onChange={(e) => {
                                setNewField({
                                    ...newField,
                                    maintaincecost: e.target.value
                                });
                            }}

                        />
                        {(newField.maintaincecost === "") && (
                            <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 1 }}>
                                {errors.maintaincecost}
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item >
                        <MDInput
                            label="Maintaince Description"
                            multiline
                            rows={3}
                            value={newField.description}

                            helperText={<FormHelperText sx={{ color: 'red', fontWeight: 800, margin: 0 }}>{errors.description}</FormHelperText>}
                            fullWidth
                            onChange={(e) => {
                                setNewField({
                                    ...newField,
                                    description: e.target.value
                                });
                            }}
                        />
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <MDButton variant="gradient" color="error" onClick={props.handleclose}>
                    <Icon>close</Icon>&nbsp;
                    Cancel
                </MDButton>
                <MDButton variant="gradient" color="success" onClick={submit}>
                    <Icon>save</Icon>&nbsp;
                    Save
                </MDButton>
            </DialogActions>
        </Dialog>
    )
}
