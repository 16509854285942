import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Icon from '@mui/material/Icon';

import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import DataTable from 'examples/Tables/DataTable';
import { useLoading } from 'context/loading.context';
import { useMaterialUIController } from 'context';
import { getCustomers } from 'network/service';


import CustomerTable from './Data'
import AddCustomerPopUp from './compoents/AddCustomersPopUp';
export default function Customers() {

    const { showLoader, hideLoader } = useLoading();
    const [customersPopUp, setCustomersPopUp] = useState({ open: false, details: null });
    const [controller, dispatch] = useMaterialUIController();
    const { sidenavColor } = controller;
    const navigate = useNavigate();
    const [customers, setCustomers] = useState([]);
    
    const { customersHeaders, sampleRows, formCustomersRows } = CustomerTable();

   
    const fetchCustomers = () => {
        showLoader();
        getCustomers()
            .then((resp) => {
                setCustomers(resp.data);
                hideLoader();
            })
            .catch((err) => {
                hideLoader();
                console.log(err);
            });
    };

    useEffect(() => {
        fetchCustomers();
    }, []);

    const exportToExcel = (data, filename) => {
        try {
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(data);
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, `${filename}.xlsx`);
        } catch (error) {
            console.error('Error exporting to Excel:', error);
        }
    };
    



    return (
       

        <DashboardLayout>
            <DashboardNavbar />
            {customersPopUp.open && <AddCustomerPopUp isopen={customersPopUp.open} details={customersPopUp.details} handleclose={() => { setCustomersPopUp({ open: false, details: null }) }} handlesuccess={()=>{setCustomersPopUp({ open: false, details: null,}),fetchCustomers()}}/>}
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor={sidenavColor}
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Customer Table
                                </MDTypography>
                            </MDBox>
                            <Stack
                                direction="row-reverse"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={2}
                            >
                                <MDBox pt={3} pr={3}>
                                    <MDButton
                                        variant="outlined"
                                        color="success"
                                        onClick={() => {
                                            setCustomersPopUp({ open: true, details: null });
                                        }}
                                    >
                                        Add Customer
                                    </MDButton>
                                </MDBox>
                                <MDBox pt={3}>
                                    {/* <MDButton
                                        variant="gradient"
                                        color="warning"
                                        onClick={() => {
                                            exportToExcel(formCustomersRows(customers), moment().unix());
                                        }}
                                    >
                                        <Icon>download</Icon>&nbsp; Export
                                    </MDButton> */}
                                </MDBox>
                            </Stack>
                            <MDBox sx={{ maxHeight: "50vh", overflow: 'auto' }}>
                                <DataTable
                                    table={{
                                        columns: customersHeaders,
                                        rows: formCustomersRows(customers,setCustomersPopUp)
                                    }}
                                    noEndBorder
                                    canSearch
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}
