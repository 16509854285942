// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { Bar } from "react-chartjs-2";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Doughnut } from "react-chartjs-2";
import { useMaterialUIController } from "context";


import colors from "assets/theme/base/colors";
import dark_colors from "assets/theme-dark/base/colors";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";



export default function UpcomingLotCard(props) {

    const [controller] = useMaterialUIController();
    const { sidenavColor, darkMode } = controller;
    const [menu, setMenu] = useState(null);

    const openMenu = ({ currentTarget }) => setMenu(currentTarget);
    const closeMenu = () => setMenu(null);


    const renderMenu = (
        <Menu
            id="simple-menu"
            anchorEl={menu}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={Boolean(menu)}
            onClose={closeMenu}
        >
            <MenuItem onClick={() => { props.takematerial(); closeMenu(); }} disabled={props.details.Status.StatusCode === "RTKN"}>Take Raw Materials</MenuItem>
            <MenuItem onClick={() => { props.startLot(); closeMenu(); }} disabled={props.details.Status.StatusCode !== "RTKN"}>Start Lot</MenuItem>
            <MenuItem onClick={closeMenu} disabled>Delete Lot</MenuItem>
        </Menu>
    );


    return (
        <Card sx={{ boxShadow: 6 }}>
            <MDBox p={2} px={2}>
                <MDBox>
                    <MDBox
                        variant="gradient"
                        bgColor={sidenavColor}
                        color={sidenavColor === "light" ? "dark" : "white"}
                        coloredShadow={sidenavColor}
                        borderRadius="xl"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="4rem"
                        height="4rem"
                        mt={-3}
                        sx={{ position: 'absolute' }}
                    >
                        <Icon fontSize="medium" color="inherit">
                            {props.icon}
                        </Icon>
                    </MDBox>
                    <Grid container spacing={2} pl={"5rem"} sx={{}}>
                        {/* <Grid item xl={3} md={4} xs={6}> */}

                        {/* </Grid> */}
                        <Grid item xl={2} md={4} xs={6}>
                            <MDTypography variant="button" fontWeight="light" color="text">
                                {"Lot No."}
                            </MDTypography>
                            <MDTypography variant="h6">{props.details.LotNumber || ""}</MDTypography>
                        </Grid>
                        <Grid item xl={2} md={4} xs={6}>
                            <MDTypography variant="button" fontWeight="light" color="text">
                                {"Recipe"}
                            </MDTypography>
                            <MDTypography variant="h6">{props.details.Recipe.RecipeName || ""}</MDTypography>
                        </Grid>
                        <Grid item xl={2} md={4} xs={6}>
                            <MDTypography variant="button" fontWeight="light" color="text">
                                {"Quantity"}
                            </MDTypography>
                            <MDTypography variant="h6">{props.details.Quantity || ""}{" Liters"}</MDTypography>
                        </Grid>
                        <Grid item xl={2} md={4} xs={6}>
                            <MDTypography variant="button" fontWeight="light" color="text">
                                {"Status"}
                            </MDTypography>
                            <MDTypography variant="h6">{props.details.Status.StatusName || ""}</MDTypography>
                        </Grid>
                        <Grid item xl={2} md={6} xs={6}>
                            <MDTypography variant="button" fontWeight="light" color="text">
                                {"Unit"}
                            </MDTypography>
                            <MDTypography variant="h6">{props.details.ManufacturingPlant.PlantName || ""}</MDTypography>
                        </Grid>
                        <Grid item xl={2} md={2} xs={6}>
                            <Grid container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center">
                                <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
                                    more_vert
                                </Icon>
                            </Grid>
                        </Grid>
                    </Grid>
                </MDBox>
                {renderMenu}
            </MDBox>
        </Card>);
}