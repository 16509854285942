// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from '@mui/material/Stack';
import Icon from "@mui/material/Icon";

import * as XLSX from 'xlsx';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import MDButton from "components/MDButton";
import { getReplenishemnts } from "network/service";
import { useLoading } from "context/loading.context";
import { useMaterialUIController } from "context";
import TableData from "./data/tableColumns";

import moment from "moment";
import MDInput from "components/MDInput";
import { Autocomplete, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import AddRecipePopup from "./components/AddRecipePopup";
import { ArrowDropDown } from "@mui/icons-material";


function Replenishments() {
    const { showLoader, hideLoader } = useLoading();
    const [controller, dispatch] = useMaterialUIController();
    const { inventoryHeaders, recipeHeaders, formRecipeRows, formReportRows } = TableData();
    const [recipePopup, setRecipePopup] = useState({ open: false, details: null });
    const { sidenavColor } = controller;
    const [recipes, setRecipes] = useState([]);
    const navigate = useNavigate();
    const [height, setHeight] = useState(100);
    const [replenishments, setReplenishments] = useState([]);
    const [filter, setFilter] = useState(null);
    const [filteredresult, setFilteredResult] = useState([]);

    useEffect(() => {
        if ((filter === "No Filter") || (!filter)) {
            setFilteredResult([...replenishments]);
        } else {
            let result = [];
            switch (filter) {
                case "Deficit <= 0":
                    for (let rcp of replenishments) {
                        if (rcp.Deficit <= 0) {
                            result.push(rcp);
                        }
                    }
                    break;
                case "Deficit > 0":
                    for (let rcp of replenishments) {
                        if (rcp.Deficit > 0) {
                            result.push(rcp);
                        }
                    }
                    break;
                case "To Order > 0":
                    for (let rcp of replenishments) {
                        let temp_to_order = ((rcp.QuantityOnHand - rcp.Required) < rcp.ToStockQuantity) ? rcp.ToStockQuantity - (rcp.QuantityOnHand - rcp.Required) : 0
                        if (temp_to_order > 0) {
                            result.push(rcp);
                        }
                    }
                    break;
            }
            setFilteredResult(result);
        }
    }, [replenishments, filter, setFilteredResult])

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 1200) { // for xl screens
                setHeight(600);
            } else if (window.innerWidth >= 768) { // for md screens
                setHeight(500);
            } else { // for xs screens
                setHeight(300);
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, [setHeight]);

    const addrecipe = (details) => {
        setRecipes([...recipes, details])
    }

    const deleterecipeItem = (index) => {
        let tempCopy = [...recipes];
        tempCopy.splice(index, 1);
        setRecipes([...tempCopy]);
    }


    useEffect(() => {

    }, []);


    const requestReport = () => {
        showLoader();
        getReplenishemnts({ recipes: recipes }).then((resp) => {
            console.log("Replenishment Report", resp.data);
            setReplenishments(resp.data);
            hideLoader();
        }).catch((err) => {
            hideLoader();
            setReplenishments([]);
            console.log(err);
        })
    }

    const exportToExcel2Sheets = (data1, data2, filename) => {
        const wb = XLSX.utils.book_new();
        const ws1 = XLSX.utils.json_to_sheet(data1);
        const ws2 = XLSX.utils.json_to_sheet(data2);
        XLSX.utils.book_append_sheet(wb, ws1, "Replenishment");
        XLSX.utils.book_append_sheet(wb, ws2, "Recipes");
        XLSX.writeFile(wb, `${filename}.xlsx`);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                {recipePopup.open && <AddRecipePopup isopen={recipePopup.open} details={recipePopup.details} handleclose={() => { setRecipePopup({ open: false, details: null }) }} handlesuccess={(details) => { addrecipe(details); setRecipePopup({ open: false, details: null }) }} />}
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor={sidenavColor}
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Replenishment Report
                                </MDTypography>
                            </MDBox>

                            <MDBox pt={3} px={2} py={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={5} xl={4}>
                                        <Card sx={{ maxHeight: height, minHeight: height, boxShadow: 6, overflow: 'auto' }}>
                                            <MDBox sx={{ minHeight: height }}>
                                                <Stack
                                                    direction="row-reverse"
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <MDBox pt={3} pr={3}>
                                                        <MDButton variant="gradient" color="warning"
                                                            onClick={() => {
                                                                requestReport();
                                                            }}>
                                                            <Icon>refresh</Icon>&nbsp;
                                                            Refresh
                                                        </MDButton>
                                                    </MDBox>
                                                    <MDBox pt={3}>
                                                        <MDButton variant="gradient" color="success"
                                                            onClick={() => {
                                                                setRecipePopup({ open: true, details: null });
                                                            }}>
                                                            <Icon>add</Icon>&nbsp;
                                                            Add
                                                        </MDButton>
                                                    </MDBox>
                                                </Stack>

                                                <MDBox>
                                                    <DataTable
                                                        table={{
                                                            columns: recipeHeaders,
                                                            rows: formRecipeRows(recipes, deleterecipeItem)
                                                        }}
                                                        showTotalEntries={false}
                                                        noEndBorder
                                                        isSorted={false}
                                                        entriesPerPage={false}
                                                    />
                                                </MDBox>
                                            </MDBox>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} md={7} xl={8}>
                                        <Card sx={{ maxHeight: height, minHeight: height, boxShadow: 6, overflow: "auto" }}>

                                            <MDBox sx={{ minHeight: height }}>
                                                <Stack
                                                    direction="row-reverse"
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <MDBox pt={3} pr={3}>
                                                        <MDButton variant="gradient" color="warning"
                                                            onClick={() => {
                                                                exportToExcel2Sheets(formReportRows(filteredresult), formRecipeRows(recipes, null), moment().unix());
                                                            }}>
                                                            <Icon>download</Icon>&nbsp;
                                                            Export
                                                        </MDButton>
                                                    </MDBox>
                                                </Stack>
                                                <MDBox pt={3} px={2} py={3}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xl={3} xs={12} md={4}>
                                                            <FormControl
                                                                sx={{ m: 0, minWidth: 187 }}
                                                                fullWidth
                                                            >

                                                                <InputLabel id="demo-simple-select-label" >Availabilty</InputLabel>
                                                                <Select
                                                                    label="Availabilty"
                                                                    labelId="demo-simple-select-label"
                                                                    style={{ height: 45, paddingRight: 10 }}
                                                                    value={filter}
                                                                    onChange={(e) => {
                                                                        setFilter(e.target.value);
                                                                    }}
                                                                    displayEmpty
                                                                    fullWidth
                                                                    IconComponent={() => <ArrowDropDown />}
                                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                                >
                                                                    <MenuItem value="No Filter">No Filter</MenuItem>
                                                                    <MenuItem value="Deficit <= 0">{"Deficit <= 0"}</MenuItem>
                                                                    <MenuItem value="Deficit > 0">{"Deficit > 0"}</MenuItem>
                                                                    <MenuItem value="To Order > 0">{"To Order > 0"}</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </MDBox>
                                                <MDBox>
                                                    <DataTable
                                                        table={{
                                                            columns: inventoryHeaders,
                                                            rows: formReportRows(filteredresult)
                                                        }}
                                                        showTotalEntries={false}
                                                        noEndBorder
                                                        isSorted={false}
                                                        entriesPerPage={false}
                                                    />
                                                </MDBox>
                                            </MDBox>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </MDBox>

                            {/* <MDBox>
                                <DataTable
                                    table={{
                                        columns: [],
                                        rows: formBillRows(bills, setDetailsPopup)
                                    }}
                                    noEndBorder
                                    canSearch
                                />
                            </MDBox> */}
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Replenishments;
