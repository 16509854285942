// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useEffect, useState, useRef } from "react";
import { Divider, FormControl, Icon, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import MDInput from "components/MDInput";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import { addBills, getSuppliers, getInventoryRawMaterials } from "network/service";
import { addInventoryProduct } from "network/service";
import { useLoading } from "context/loading.context";
import { useNotification } from "context/notification.context";
import { useNavigate } from 'react-router-dom';
import { useMaterialUIController } from "context";
import { ArrowDropDown } from "@mui/icons-material";
import moment from "moment";
import DataTable from "examples/Tables/DataTable";
import { FormHelperText } from "@mui/material";
const bill_items_headers = [
    { Header: "Item", accessor: "item", align: "center" },
    { Header: "Quantity", accessor: "quantity", align: "center" },
    { Header: "Rate", accessor: "rate", align: "center" },
    { Header: "Tax(%)", accessor: "tax", align: "center" },
    { Header: "Amount", accessor: "amount", align: "center" },
    { Header: "Action", accessor: "action", align: "center" }
]



function AddBill() {
    const [controller, dispatch] = useMaterialUIController();
    const { sidenavColor } = controller;
    const navigate = useNavigate();
    const { showNotification } = useNotification();
    const { showLoader, hideLoader } = useLoading();

    const [suppliers, setSuppliers] = useState([]);
    const [rawmaterials, setRawmaterials] = useState([]);
    const [itemTax, setItemTax] = useState("Tax Exclusive");
    const [date, setDate] = useState(new Date());
    const [selSupplier, setSelSupplier] = useState(null);
    const [billno, setBillNo] = useState("");
    const [selItem, setSelItem] = useState(null);
    const [newVal, setNewVal] = useState({ quantity: "", rate: "", tax: "", amount: "", supplier: "", billno: "", billdate: moment().format("YYYY-MM-DD") });
    const [billItems, setBillItems] = useState([]);
    const [billamt, setBillamt] = useState("");
    const [errors, setErrors] = useState({});
    const [subErrors, setSubErrors] = useState({});
    const refdate = new Date();



    const deleteBillItem = (index) => {
        let tempCopy = [...billItems];
        tempCopy.splice(index, 1);
        setBillItems([...tempCopy]);
    }


    const formBillItemRows = (data) => {
        let result = [];
        let total = 0;
        console.log(data);
        for (let dt in data) {
            total += parseFloat(data[dt].amount);
            result.push({
                item: data[dt].Product.ProductName,
                quantity: data[dt].quantity,
                rate: data[dt].rate,
                tax: data[dt].tax,
                amount: data[dt].amount,
                action: < MDButton variant="outlined" color="error"
                    iconOnly
                    onClick={() => { deleteBillItem(dt) }}>
                    <Icon>delete</Icon>
                </MDButton >
            });
        }
        result.push({
            tax: "Total",
            amount: total.toFixed(2)
        });
        return result
    }


    const goBack = () => {
        navigate(-1);
    };

    const fetchRawMaterials = () => {

    }


    useEffect(() => {
        fetchRawMaterials();
    }, []);

    const handleInput = (event, tax_val = itemTax) => {
        let temp = { ...newVal };
        let value = parseFloat(event.target.value);

        switch (event.target.name) {
            case 'quantity':
                if (value >= 0) {
                    temp.quantity = value.toString();
                }
                break;
            case 'rate':
                if (value >= 0) {
                    temp.rate = value.toString();
                }
                break;
            case 'tax':
                if (value >= 0) {
                    temp.tax = value.toString();
                }
                break;
            case 'amount':
                temp.amount = event.target.value;
                break;
        }

        // Recalculate amount based on new values
        if (parseFloat(temp.quantity) > 0 && parseFloat(temp.rate) > 0) {
            let tax = parseFloat(temp.tax) || 0;
            let quantity = parseFloat(temp.quantity);
            let rate = parseFloat(temp.rate);
            let amount = (quantity * rate).toFixed(2);
            if (tax_val === 'Tax Exclusive') {
                amount = (quantity * rate * (1 + tax / 100)).toFixed(2);
            }
            temp.amount = amount.toString();
        }
        else {
            temp.amount = 0;
        }

        setNewVal({ ...temp });
    };


    useEffect(() => {
        getSuppliers().then((resp) => {
            console.log(resp.data);
            setSuppliers(resp.data);
        }).catch((err) => {
            console.log("Error fetching suppliers", err);
        })
    }, [setSuppliers]);

    useEffect(() => {
        getInventoryRawMaterials().then((resp) => {
            console.log(resp.data);
            setRawmaterials(resp.data);
        }).catch((err) => {
            console.log("Error fetching Raw materials", err);
        })
    }, [setRawmaterials]);

    const validate_items = () => {
        let valid = true;
        let error = {};
        if (!selItem) {
            valid = false;
            error['item'] = "Select a valid Item";
        }

        if ((newVal.quantity === '') || (parseFloat(newVal.quantity) <= 0)) {
            valid = false;
            error['quantity'] = "Enter a valid Quantity"
        }
        if ((newVal.rate === '') || (parseFloat(newVal.rate) <= 0)) {
            valid = false;
            error['rate'] = "Enter a valid rate"
        }
        if ((newVal.amount === '') || (parseFloat(newVal.amount) <= 0)) {
            valid = false;
            error['amount'] = "Invalid amount"
        }
        if ((newVal.tax === '') || (parseFloat(newVal.tax) <= 0)) {
            valid = false;
            error['tax'] = "Enter a valid Tax"
        }
        // if (parseFloat(newVal.amount) <= 0) return false;
        setSubErrors(error);
        return valid;
    }

    const addBillItem = () => {
        if (validate_items()) {
            let sel_item = { ...selItem };
            setBillItems([...billItems, {
                ...sel_item,
                quantity: newVal.quantity,
                rate: newVal.rate,
                tax: (newVal.tax.length > 0) ? newVal.tax : "0",
                amount: newVal.amount
            }]);
            setSelItem(null);
            setNewVal({ amount: "", rate: "", tax: "", quantity: "" });
        }
    }

    const isAmountEqual = (total, items) => {
        let total_float = parseFloat(total).toFixed(2);
        let calculated_total = 0;
        for (let dtl of items) {
            calculated_total += parseFloat(dtl.amount);
        }
        if (calculated_total.toFixed(2) == total_float) return true;
        else return false;
    }

    const validateBill = () => {
        let isValid = true;
        let errors = {};

        if (!selSupplier) {
            errors.supplier = "Supplier is required.";
            isValid = false;
        }

        if (parseFloat(billamt) <= 0) {
            errors.billamt = "Bill amount must be greater than zero.";
            isValid = false;
        }

        if (billno.length <= 0) {
            errors.billno = "Bill number is required.";
            isValid = false;
        }
        if (!itemTax) {
            errors.seltax = "Please select a tax type"
        }
        if (newVal.billdate === "") {
            errors.billdate = "Bill date is required"
            isValid = false;
        }
        else if (date > refdate) {
            errors.billdate = "Bill  date cannot be in future"
            isValid = false;
        }
        if (billItems.length <= 0) {
            isValid = false;
            errors.billItems = "Add atleast One Bill Item"
        }

        setErrors(errors);
        return isValid;
    }
    const submit = () => {
        if (validateBill() && isAmountEqual(billamt, billItems)) {
            let details = { BillDetails: [...billItems], Supplier: { ...selSupplier }, BillNumber: billno, BillDate: date, TotalAmount: billamt };
            showLoader();
            addBills({ ...details }).then((resp) => {
                console.log(resp.data);
                hideLoader();
                showNotification("success", "Success", "Successfully added the bill details");
                goBack();
            }).catch((err) => {
                console.log(err);
                showNotification("error", "Failed", "Faile to add the bill details");
                hideLoader();
            })


        }


    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor={sidenavColor}
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Add Bill
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3} px={2} py={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12} xl={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6} xl={3}>
                                                <Autocomplete
                                                    disableClearable
                                                    label={"Supplier"}
                                                    sx={{ flexGrow: 1 }}
                                                    options={suppliers}
                                                    getOptionLabel={(option) => option.SupplierName || ''}
                                                    renderInput={(params) => <MDInput {...params} label={"Supplier"} fullWidth />}
                                                    onChange={(event, newValue) => {
                                                        setSelSupplier(newValue);
                                                    }}
                                                    value={selSupplier}
                                                />
                                                {(!newVal.supplier || newVal.supplier === 0) && (
                                                    <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 1 }}>
                                                        {errors.supplier}
                                                    </FormHelperText>
                                                )}
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={3}>
                                                <Grid container spacing={2}>
                                                    <Grid item xl={6} xs={6} md={6}>
                                                        <MDInput fullWidth label="Bill No." value={billno}
                                                            onChange={(e) => {
                                                                const value = parseFloat(e.target.value, 10);
                                                                const isValid = !isNaN(value);

                                                                setNewVal({ ...newVal, billno: value });
                                                                setErrors({ ...errors, qtyOfUnit: !isValid });
                                                                setBillNo(e.target.value)
                                                            }}></MDInput>

                                                    </Grid>

                                                    <Grid item xl={6} xs={6} md={6}>
                                                        <MDInput fullWidth label="Bill Amount" type="number" value={billamt} onChange={(e) => { setBillamt(e.target.value) }}></MDInput>
                                                    </Grid>
                                                    <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 3 }}>
                                                        {errors.billno}
                                                    </FormHelperText>
                                                </Grid>

                                            </Grid>
                                            <Grid item xs={12} md={6} xl={3}>
                                                <MDInput type="date" fullWidth label="Bill Date" value={moment(date).format("YYYY-MM-DD")} onChange={(event) => {
                                                    setDate(event.target.valueAsDate);
                                                }}></MDInput>
                                                <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 1 }}>
                                                    {errors.billdate}
                                                </FormHelperText>
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={3}>
                                                <FormControl sx={{ m: 0, minWidth: 187 }} fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Tax</InputLabel>
                                                    <Select
                                                        label="Tax"
                                                        labelId="demo-simple-select-label"
                                                        style={{ height: 45, paddingRight: 10 }}
                                                        value={itemTax}
                                                        onChange={(e) => {
                                                            setItemTax(e.target.value);
                                                            handleInput({ target: { name: "tax", value: newVal.tax } }, e.target.value);
                                                            let temp = [...billItems];
                                                            for (let dtin in temp) {
                                                                let rate = parseFloat(temp[dtin].rate);
                                                                let quantity = parseFloat(temp[dtin].quantity);
                                                                if (e.target.value === "Tax Inclusive") {
                                                                    temp[dtin].amount = (quantity * rate).toFixed(2);
                                                                }
                                                                else {
                                                                    if (parseFloat(temp[dtin].tax) > 0) {
                                                                        temp[dtin].amount = ((quantity * rate) * (1 + (parseFloat(temp[dtin].tax) / 100))).toFixed(2);
                                                                    }
                                                                }
                                                                setBillItems([...temp]);
                                                            }
                                                        }}
                                                        displayEmpty
                                                        IconComponent={() => { return (<ArrowDropDown />) }}
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                    >
                                                        <MenuItem value={"Tax Inclusive"}>Tax Inclusive</MenuItem>
                                                        <MenuItem value={"Tax Exclusive"}>Tax Exclusive</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    <Grid item xl={12} md={12} xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12} md={12} xl={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6} xl={2}>
                                                <Autocomplete
                                                    disableClearable
                                                    label={"Item"}
                                                    sx={{ flexGrow: 1 }}
                                                    options={rawmaterials}
                                                    getOptionLabel={(option) => option.Product.ProductName || ''}
                                                    renderInput={(params) => <MDInput {...params} label={"Item"} fullWidth helperText={<FormHelperText sx={{ color: 'red', fontWeight: 800, margin: 0 }}>{subErrors.item ? subErrors.item : ''}</FormHelperText>} />}
                                                    onChange={(event, newValue) => {
                                                        setSelItem(newValue);
                                                    }}
                                                    value={selItem}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={2}>
                                                <Grid container sx={{ alignItems: 'center' }}>
                                                    <MDInput
                                                        sx={{ flexGrow: 1 }}
                                                        name="quantity"
                                                        label="Quantity"
                                                        type="number"
                                                        value={newVal.quantity}
                                                        helperText={<FormHelperText sx={{ color: 'red', fontWeight: 800, margin: 0 }}>{subErrors.quantity ? subErrors.quantity : ''}</FormHelperText>}
                                                        onChange={handleInput}
                                                    />

                                                    <MDTypography variant="overline" sx={{ marginLeft: 1 }}>{selItem ? selItem.Product.UnitOfMeasure.name : ""}</MDTypography>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={2}>
                                                <MDInput
                                                    fullWidth
                                                    name="rate"
                                                    label="Rate"
                                                    type="number"
                                                    value={newVal.rate}
                                                    onChange={handleInput}
                                                    helperText={<FormHelperText sx={{ color: 'red', fontWeight: 800, margin: 0 }}>{subErrors.rate ? subErrors.rate : ''}</FormHelperText>}
                                                />

                                            </Grid>
                                            <Grid item xs={12} md={6} xl={2}>
                                                <MDInput
                                                    fullWidth
                                                    name="tax"
                                                    label="Tax %"
                                                    type="number"
                                                    value={newVal.tax}
                                                    onChange={handleInput}
                                                    helperText={<FormHelperText sx={{ color: 'red', fontWeight: 800, margin: 0 }}>{subErrors.tax ? subErrors.tax : ''}</FormHelperText>}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={2}>
                                                <MDInput
                                                    fullWidth
                                                    name="amount"
                                                    label="Amount"
                                                    type="number"
                                                    value={newVal.amount}
                                                    onChange={handleInput}
                                                    helperText={<FormHelperText sx={{ color: 'red', fontWeight: 800, margin: 0 }}>{subErrors.amount ? subErrors.amount : ''}</FormHelperText>}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={2}>
                                                <MDButton variant="outlined" color="success"
                                                    onClick={() => { addBillItem()}}>
                                                    <Icon>add</Icon>&nbsp;
                                                    Add
                                                </MDButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xl={12} md={12} xs={12}>
                                        <DataTable
                                            table={{
                                                columns: bill_items_headers,
                                                rows: formBillItemRows(billItems)
                                            }}
                                            showTotalEntries={false}
                                            isSorted={false}
                                            noEndBorder
                                            entriesPerPage={false}
                                        />
                                        <MDBox >
                                            <FormHelperText sx={{ color: 'red', alignSelf: 'center', fontWeight: 400, textAlign: "center", marginRight: "px", fontSize: "12px", marginTop: 2 }}>
                                                {errors.billItems ? `${errors.billItems}` : ''}
                                            </FormHelperText>
                                        </MDBox>
                                    </Grid>
                                </Grid>
                                <Stack
                                    direction="row-reverse"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <MDBox pt={3} pr={3}>
                                        <MDButton variant="gradient" color="success"
                                            disabled={!isAmountEqual(billamt, billItems)}
                                            onClick={() => { submit() }}>
                                            <Icon>save</Icon>&nbsp;
                                            Save
                                        </MDButton>
                                    </MDBox>
                                    <MDBox pt={3}>
                                        <MDButton variant="gradient" color="error" onClick={() => { goBack(); }}>
                                            <Icon>close</Icon>&nbsp;
                                            Cancel
                                        </MDButton>
                                    </MDBox>
                                    <MDBox >
                                        <FormHelperText sx={{ color: 'red', alignSelf: 'center', fontWeight: 400, textAlign: "right", marginRight: "px", fontSize: "12px", marginTop: 2 }}>
                                            {((parseFloat(newVal.quantity) <= 0 || parseFloat(newVal.rate) <= 0 || parseFloat(newVal.tax) <= 0) || (!newVal.supplier && newVal.supplier === 0)) ? 'Invalid/Incorrect Input' : (!isAmountEqual(billamt, billItems)) ? 'Bill amount and total should match' : ""}
                                        </FormHelperText>
                                    </MDBox>
                                </Stack>

                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default AddBill;
