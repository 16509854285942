// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from '@mui/material/Stack';
import Icon from "@mui/material/Icon";

import * as XLSX from 'xlsx';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import MDButton from "components/MDButton";
import { useLoading } from "context/loading.context";
import { useMaterialUIController } from "context";

import moment from "moment";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import { Menu } from "@mui/icons-material";
import Skeleton from '@mui/material/Skeleton';
import MenuItem from "@mui/material/MenuItem";
import LiveLotCard from "./components/LiveLotCard";
import UpcomingLotCard from "./components/UpcomingLotCard";
import AddLotPopup from "./components/AddLotPopup";
import StopLotPopup from "./components/StopLotPopup";
import TakeRawMaterials from "./components/TakeRawMaterialsPopup";
import { getLiveLots, getUpcomingLots } from "network/service";
import { startLot, stopLot } from "network/service";
import { useNotification } from "context/notification.context";

function ProductionLots() {
    const { showNotification } = useNotification();
    const { showLoader, hideLoader } = useLoading();
    const [controller, dispatch] = useMaterialUIController();
    const { sidenavColor } = controller;
    const [lotPopup, setLotPopup] = useState({ open: false, details: null });
    const [stoplotpopup, setStoplotpopup] = useState({ open: false, details: null });
    const [materialPopup, setMaterialPopup] = useState({ open: false, details: null });
    const navigate = useNavigate();

    const [livelots, setLiveLots] = useState({ loading: true, data: [] });
    const [upcominglots, setUpcomingLots] = useState({ loading: true, data: [] });


    const [menu, setMenu] = useState(null);

    const [liveTrigger, setLiveTrigger] = useState(false);
    const [upcomingTrigger, setUpcomingTrigger] = useState(false);

    const openMenu = ({ currentTarget }) => setMenu(currentTarget);
    const closeMenu = () => setMenu(null);

    const dropdownMenu = (
        <Menu
            anchorEl={menu}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(menu)}
            onClose={closeMenu}
        >
            <MenuItem onClick={closeMenu}>Action</MenuItem>
            <MenuItem onClick={closeMenu}>Another action</MenuItem>
            <MenuItem onClick={closeMenu}>Something else here</MenuItem>
        </Menu>
    );

    const TakeRawMaterial = (details) => {
        setMaterialPopup({ open: true, details: details });
    }

    useEffect(() => {
        getUpcomingLots().then((resp) => {
            setUpcomingLots({ loading: false, data: resp.data });
            setLiveTrigger(false);
            console.log(resp.data);
        }).catch((err) => {
            setUpcomingLots({ loading: false, data: [] });
            setLiveTrigger(false);
            console.log(err);
        })
    }, [setUpcomingLots, liveTrigger]);

    useEffect(() => {
        getLiveLots().then((resp) => {
            console.log(resp.data);
            setLiveLots({ loading: false, data: resp.data });
            setUpcomingTrigger(false);
        }).catch((err) => {
            console.log(err);
            setLiveLots({ loading: false, data: [] });
            setUpcomingTrigger(false);
        })
    }, [setLiveLots, upcomingTrigger]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                {materialPopup.open && <TakeRawMaterials isopen={materialPopup.open} details={materialPopup.details} handleclose={() => { setMaterialPopup({ open: false, details: null }) }} handlesuccess={(details) => { setLiveTrigger(true); setUpcomingTrigger(true); setMaterialPopup({ open: false, details: null }) }} />}
                {lotPopup.open && <AddLotPopup isopen={lotPopup.open} details={lotPopup.details} handleclose={() => { setLotPopup({ open: false, details: null }) }} handlesuccess={(details) => { setLiveTrigger(true); setUpcomingTrigger(true); setLotPopup({ open: false, details: null }) }} />}
                {stoplotpopup.open && <StopLotPopup isopen={stoplotpopup.open} details={stoplotpopup.details} handleclose={() => { setStoplotpopup({ open: false, details: null }) }} handlesuccess={(details) => { setLiveTrigger(true); setUpcomingTrigger(true); setStoplotpopup({ open: false, details: null }) }} />}
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor={sidenavColor}
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Productions
                                </MDTypography>
                            </MDBox>
                            <Stack
                                direction="row-reverse"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={2}
                            >
                                <MDBox pt={3} pr={3}>
                                    <MDButton variant="gradient" color="success"
                                        onClick={() => { setLotPopup({ open: true, details: null }); }}>
                                        <Icon>add</Icon>&nbsp;
                                        Create
                                    </MDButton>
                                </MDBox>
                            </Stack>

                            <MDBox pt={2} px={2} py={3}>
                                <MDTypography sx={{ mb: 3 }}>Live Lots</MDTypography>
                                <Grid container spacing={3}>
                                    {
                                        (livelots.loading) && <Grid item xl={3} xs={12} md={4}>
                                            <LiveLotCard
                                                loading={true}
                                                color={sidenavColor}
                                                icon="blender"
                                                title="Running Lot"
                                                count={281}
                                                chart={{
                                                    labels: ["M", "T", "W", "T", "F", "S", "S"],
                                                    datasets: { label: "Sales", data: [30, 20, 10, 22, 30, 10, 20] },
                                                }}
                                                percentage={{
                                                    color: "success",
                                                    amount: "+55%",
                                                    label: "than lask week",
                                                }} />
                                        </Grid>
                                    }
                                    {
                                        ((!livelots.loading) && (livelots.data.length == 0)) && <Grid item xl={12} xs={12} md={12} sx={{ textAlign: 'center' }}>
                                            <MDTypography>No Live Lots</MDTypography>
                                        </Grid>
                                    }
                                    {
                                        (livelots.data.length > 0) && <>{
                                            livelots.data.map((data, ind) => <Grid item xl={3} xs={12} md={4}><LiveLotCard
                                                color={sidenavColor}
                                                icon="blender"
                                                loading={false}
                                                details={data}
                                                chart={data.timing}
                                                stopLot={() => {
                                                    setStoplotpopup({ open: true, details: data });
                                                    // stopLot(data._id).then((resp) => {
                                                    //     console.log(resp.data);
                                                    //     showNotification("success", "Success", "Stopped the lot successfully");
                                                    //     setLiveTrigger(true);
                                                    //     setUpcomingTrigger(true);
                                                    // }).catch((err) => {
                                                    //     showNotification("error", "Failed", "Unable to stop the lot");
                                                    //     console.log(err);
                                                    // })
                                                }} /></Grid>)
                                        }</>
                                    }
                                </Grid>
                            </MDBox>
                            <MDBox pt={2} px={2} py={3}>
                                <MDTypography sx={{ mb: 3 }}>Upcoming Lots</MDTypography>
                                <Grid container spacing={5}>
                                    {
                                        (upcominglots.loading) && <Grid item xl={12} xs={12} md={12}>
                                            {/* <Card sx={{ boxShadow: 6 }}> */}
                                            <Skeleton variant="rectangular" height={60} />
                                            {/* </Card> */}
                                        </Grid>
                                    }
                                    {
                                        ((!upcominglots.loading) && (upcominglots.data.length == 0)) && <Grid item xl={12} xs={12} md={12} sx={{ textAlign: 'center' }}>
                                            <MDTypography>No Upcoming Lots</MDTypography>
                                        </Grid>
                                    }
                                    {
                                        (upcominglots.data.length > 0) && <>{
                                            upcominglots.data.map((data, ind) => <Grid item xl={12} xs={12} md={12}><UpcomingLotCard
                                                color={sidenavColor}
                                                details={data}
                                                takematerial={() => { TakeRawMaterial(data); }}
                                                startLot={() => {
                                                    startLot(data._id).then((resp) => {
                                                        console.log(resp.data);
                                                        showNotification("success", "Success", "Started the lot successfully");
                                                        setLiveTrigger(true);
                                                        setUpcomingTrigger(true);
                                                    }).catch((err) => {
                                                        console.log(err);
                                                        if (err.response.status == 400) {
                                                            console.log(err.response.data);
                                                            showNotification("error", "Failed", err.response.data.message ? err.response.data.message : "Unable to start the lot");
                                                        }
                                                        else {
                                                            showNotification("error", "Failed", "Unable to start the lot");
                                                        }
                                                    })
                                                }}
                                                icon="upcoming" /></Grid>)
                                        }</>
                                    }
                                    {/* <Grid item xl={12} xs={12} md={12}>
                                        <UpcomingLotCard
                                            color={sidenavColor}
                                            icon="upcoming" />
                                    </Grid>
                                    <Grid item xl={12} xs={12} md={12}>
                                        <UpcomingLotCard
                                            color={sidenavColor}
                                            icon="upcoming"
                                            title="Running Lot"
                                            count={281}
                                            chart={{
                                                labels: ["M", "T", "W", "T", "F", "S", "S"],
                                                datasets: { label: "Sales", data: [30, 20, 10, 22, 30, 10, 20] },
                                            }}
                                            percentage={{
                                                color: "success",
                                                amount: "+55%",
                                                label: "than lask week",
                                            }} />
                                    </Grid> */}
                                </Grid>
                            </MDBox>

                            {/* <MDBox>
                                <DataTable
                                    table={{
                                        columns: [],
                                        rows: []
                                    }}
                                    noEndBorder
                                    canSearch
                                />
                            </MDBox> */}
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default ProductionLots;
