import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { FormHelperText, Icon } from "@mui/material";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useNotification } from "context/notification.context";
import Autocomplete from "@mui/material/Autocomplete";
import moment from "moment";
import { getEquipements, getSections, getManufacturingPlant, getEventTypes } from "network/service";
import { getMaintenanceLogs, createRuntimeEvents, updateMaintenanceLogs } from "network/service";

export default function AddMaintenanceLogPopUp(props) {
    const [newField, setNewField] = useState({ manuplant: "", section: "", mantype: "", schevent: "", eqpname: "", startdate: moment().format("YYYY-MM-DD"), starttime: moment().format("HH:mm"), enddate: moment().format("YYYY-MM-DD"), endtime: moment().format("HH:mm"), maintaincecost: "", description: "", result: "" })
    const [errors, setErrors] = useState({});
    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(false);
    const [section, setSection] = useState([]);
    const [equipment, SetEquipment] = useState([])
    const [selEquipmentName, setSelEquimentName] = useState([])
    const [selSection, setSelSection] = useState();
    const [manuPlant, setManuPlant] = useState([]);
    const [selManuPlant, setSelManuPlant] = useState();
    const [startdate, setStartDate] = useState(new Date());
    const [enddate, setEndDate] = useState(new Date());
    const [startTime, setStartTime] = useState(moment());
    const [endTime, setEndTime] = useState(moment());
    const [eventTypes, setEventTypes] = useState();
    const [selEventType, setSelEventType] = useState();
    const date = (new Date());
    const starteddate = new Date(newField.startdate);



    const validate = () => {
        let isValid = true;
        let newErrors = {};
        if (!selEquipmentName) {
            newErrors.eqpname = "Equipment name is required";
            isValid = false;
        }

        if (!selManuPlant) {
            newErrors.manuplant = "Manufacturing plant is required";
            isValid = false;
        }

        if (!selSection) {
            newErrors.section = "Section is required";
            isValid = false;
        }
        if (!selEventType) {
            newErrors.eventtype = "Event type is required";
            isValid = false;
        }
        if (newField.description.trim() === "") {
            newErrors.description = "Comments is required"
            isValid = false;
        }
        if (newField.startdate === 0) {
            newErrors = { ...newErrors, startdate: "Start date is required" };
            isValid = false;
        }
        else if (starteddate > date) {
            newErrors = { ...newErrors, startdate: "Start date cannot be feature" }
        }
        if (newField.enddate === 0) {
            newErrors = { ...newErrors, enddate: "End date is required" };
            isValid = false;
        } else if (new Date(newField.enddate) < new Date(newField.startdate)) {
            newErrors = { ...newErrors, enddate: "End date must be greater than start date" };
            isValid = false;
        }
        setErrors(newErrors);
        console.log("Validation errors:", newErrors);
        return isValid;
    }


    // Options Fetching
    const fetchEquipmentName = (param) => {
        getEquipements(param).then((resp) => {
            console.log(resp.data);
            SetEquipment(resp.data);
        }).catch((err) => {
            console.log(err);
            SetEquipment([]);
        })
    };
    const fetchSection = (params) => {
        getSections(params).then((resp) => {
            console.log(resp.data);
            setSection(resp.data);
        }).catch(() => {

            setSection(resp.data);
        })
    }
    const fetchManuplant = () => {
        getManufacturingPlant().then((resp) => {
            console.log(resp.data);
            setManuPlant(resp.data);
        }).catch(() => {

            setManuPlant(resp.data);
        })
    }

    const fetchEventTypes = () => {
        getEventTypes().then((resp) => {
            setEventTypes(resp.data);
        }).catch((err) => {
            console.log(err);
        })
    }


    useEffect(() => {
        fetchManuplant();
        fetchEventTypes();
    }, []);

    useEffect(() => {
        if (selManuPlant) {
            fetchSection(`ManufacturingPlant=${selManuPlant._id}`);
            setSelSection(null);
        }
        else {
            setSection([]);
            setSelSection(null);
        }
    }, [selManuPlant]);

    useEffect(() => {
        if (selSection) {
            fetchEquipmentName(`Section=${selSection._id}`);
            setSelEquimentName(null);
        }
        else {
            SetEquipment([]);
            setSelEquimentName(null);
        }
    }, [selSection]);

    const submit = async () => {

        try {
            const isValid = validate();
            if (isValid) {
                setLoading(true);

                const eventData = {
                    Equipment: selEquipmentName._id,
                    EventType: selEventType._id,
                    StartTime: new Date(moment(`${newField.startdate}T${newField.starttime}`).toDate()),
                    EndTime: new Date(moment(`${newField.enddate}T${newField.endtime}`).toDate()),
                    Comments: newField.description
                };
                console.log(eventData);
                await createRuntimeEvents(eventData);
                showNotification("success", "Success", "Added the Equipment Event successfully");
                setLoading(false);
                props.handlesuccess();
                props.handleclose();
            }
        } catch (err) {
            setLoading(false);
            showNotification("error", "Error", "Failed to add Equipment Event.");
        }
    };



    return (
        <Dialog
            id="modal-display"
            open={props.isopen}
            aria-labelledby="form-dialog-title"
            aria-describedby="modal-modal-description"
            maxWidth="90%"
            maxHeight="90%"
            onClose={props.handleclose}
            disableBackdropClick
        >
            <div>
                <DialogTitle>Add Equipment Event</DialogTitle>
            </div>
            <DialogContent sx={{ minWidth: 500 }}>

                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Autocomplete
                            disableClearable
                            label={"Manufacturing Plant"}
                            error={errors.manuPlant}
                            sx={{ flexGrow: 1 }}
                            options={manuPlant}
                            getOptionLabel={(option) => option.PlantName || ''}
                            renderInput={(params) => <MDInput  {...params} label={"Manufacturing Plant"} fullWidth />}
                            onChange={(event, newValue) => {
                                setSelManuPlant(newValue);
                            }}
                            value={selManuPlant}

                        />
                        {(!newField.manuplant || newField.manuplant === 0) && (
                            <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 1 }}>
                                {errors.manuplant}
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item>
                        <Autocomplete
                            label={"Section"}
                            sx={{ flexGrow: 1 }}
                            error={errors.secation}

                            options={section}
                            getOptionLabel={(option) => option.SectionName || ''}
                            renderInput={(params) => <MDInput {...params} label={"Section"} fullWidth />}
                            onChange={(event, newValue) => {
                                setSelSection(newValue);
                            }}
                            value={selSection}
                        />
                        {(!newField.section || newField.section === 0) && (
                            <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 1 }}>
                                {errors.section}
                            </FormHelperText>
                        )}

                    </Grid>

                    <Grid item>
                        <Autocomplete
                            label={"Equipment Name"}
                            sx={{ flexGrow: 1 }}
                            error={errors.eqpname}
                            options={equipment}
                            getOptionLabel={(option) => option.EquipmentName || ''}
                            renderInput={(params) => <MDInput  {...params} label={"Equipment Name"} fullWidth />}
                            onChange={(event, newValue) => {
                                setSelEquimentName(newValue);
                            }}
                            value={selEquipmentName}
                        />
                        {(!newField.eqpname || newField.eqpname === 0) && (
                            <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 1 }}>
                                {errors.eqpname}
                            </FormHelperText>
                        )}
                    </Grid>

                    <Grid item>
                        <Autocomplete
                            disableClearable
                            label={"Event Type"}
                            error={errors.mantype}

                            sx={{ flexGrow: 1 }}
                            options={eventTypes}
                            getOptionLabel={(option) => option.EventName || ''}
                            renderInput={(params) => <MDInput  {...params} label={"Event Type"} fullWidth />}
                            onChange={(event, newValue) => {
                                setSelEventType(newValue);
                            }}
                            value={selEventType}
                        />
                        {(!newField.eventtype || newField.eventtype === 0) && (
                            <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 1 }}>
                                {errors.eventtype}
                            </FormHelperText>
                        )}
                    </Grid>

                    <Grid container mt={1} spacing={2} alignItems="center">
                        <Grid item xs={7} ml={2} xl={7} >
                            <MDInput
                                fullWidth
                                type="date"
                                label={"Start Date "}
                                value={moment(startdate).format("YYYY-MM-DD")}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    setStartDate(value);
                                    setNewField({
                                        ...newField,
                                        startdate: value
                                    });
                                }}



                            />
                            <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 1 }}>
                                {errors.startdate}
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={4.5} >
                            <MDInput
                                fullWidth
                                type="time"
                                label={"Start Time "}
                                value={moment(startTime).format("HH:mm")}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    console.log(value);
                                    if (value) {
                                        setStartTime(moment(value, "HH:mm"));
                                        setNewField({
                                            ...newField,
                                            starttime: value
                                        });
                                        setErrors({
                                            ...errors,
                                            starttime: ''
                                        });
                                    }
                                }}



                            />

                        </Grid>
                    </Grid>


                    <Grid container mt={1} spacing={2} alignItems="center">
                        <Grid item xs={7} ml={2} >
                            <MDInput
                                fullWidth
                                type="date"
                                label={"End Date "}
                                value={moment(enddate).format("YYYY-MM-DD")}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    setEndDate(value);
                                    setNewField({
                                        ...newField,
                                        enddate: value
                                    });
                                }}



                            />

                            <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 1 }}>
                                {errors.enddate}
                            </FormHelperText>


                        </Grid>


                        <Grid item xs={4.5} >

                            <MDInput
                                fullWidth
                                type="time"
                                label={"End Time "}
                                value={moment(endTime).format("HH:mm")}
                                onChange={(event) => {
                                    const value = event.target.value;
                                    if (value) {
                                        setEndTime(moment(value, "HH:mm"));
                                        setNewField({
                                            ...newField,
                                            endtime: value
                                        });
                                        setErrors({
                                            ...errors,
                                            endtime: ''
                                        });
                                    }
                                }}



                            />

                           
                        </Grid>
                       
                    </Grid>
                    <FormHelperText sx={{ color: 'red', fontWeight: 800 }}>
                                {errors.endTime}
                            </FormHelperText>
                    <Grid item >
                        <MDInput
                            label="Comments"
                            multiline
                            rows={3}
                            value={newField.description}

                            fullWidth
                            onChange={(e) => {
                                setNewField({
                                    ...newField,
                                    description: e.target.value
                                });
                            }}
                        />
                    </Grid>
                    <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 3 }}>
                        {errors.description}
                    </FormHelperText>
                </Grid>
            </DialogContent>
            <DialogActions>
                <MDButton variant="gradient" color="error" onClick={props.handleclose}>
                    <Icon>close</Icon>&nbsp;
                    Cancel
                </MDButton>
                <MDButton variant="gradient" color="success" onClick={submit}>
                    <Icon>save</Icon>&nbsp;
                    Save
                </MDButton>
            </DialogActions>
        </Dialog>
    )
}
