import { Icon } from '@mui/material';
import MDButton from 'components/MDButton';
const moment = require('moment');

export default function MaintenancelogsTable() {

    const formMaintenancelogsRow = (data, setMaintenanceLogsPopUp) => {
        if (!data) {
            console.log("Data is null or undefined");
            return [];
        }
        console.log(data)
        let result = [];
        for (let dt of data) {
            result.push({
                manuplant: dt.Equipment.Section.ManufacturingPlant.PlantName,
                section: dt.Equipment?.Section?.SectionName,
                eqpname: dt.Equipment.EquipmentName,
                starttime: moment(dt.StartTime).format("YYYY-MM-DD HH:mm"),
                endtime: moment(dt.EndTime).format("YYYY-MM-DD HH:mm"),
                MaintainaceSchedules: dt.MaintenanceSchedule ? dt.MaintenanceSchedule._id : "NA",
                result: dt.MaintenanceResult,
                maintaincecost: dt.MaintenanceCost,
                description: dt.MaintenanceDescription,
                action: (
                    <MDButton variant="outlined" color="info" iconOnly onClick={() => {
                        setMaintenanceLogsPopUp({ open: true, details: dt });
                    }}>
                        <Icon>edit</Icon>
                    </MDButton>
                ),
            });
        }
        return result;
        // return [];
    };

    return {
        formMaintenancelogsRow,
        MaintenancelogsHeader: [
            { Header: "Manufacturing Plant", accessor: "manuplant", align: "center" },
            { Header: "Section", accessor: "section", align: "center" },
            { Header: "Equipment Name", accessor: "eqpname", align: "center" },
            // { Header: "Scheduled Events", accessor: "MaintainaceSchedules", align: "center" },
            { Header: "StartDate Time", accessor: "starttime", align: "center" },
            { Header: "EndDate Time", accessor: "endtime", align: "center" },
            { Header: "Maintainace Cost", accessor: "maintaincecost", align: "center" },
            { Header: "Description", accessor: "description", align: "center" },
            { Header: "Result", accessor: "result", align: "center" },
            { Header: "Action", accessor: "action", align: "center" }
        ],
    };
}
