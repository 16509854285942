import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { FormHelperText, Icon } from "@mui/material";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useNotification } from "context/notification.context";
import { addCustomers, updateCustomers } from "network/service";

export default function AddCustomerPopUp(props) {
    const [newField, setNewField] = useState({ name: "", customerscode: "", contactPerson: "", contactNumber: "", email: "", address: "" });
    const [errors, setErrors] = useState({});
    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(false);

    const validate = () => {
        let isValid = true;
        let newErrors = {};

        if (newField.name.trim() === "") {
            newErrors = { ...newErrors, name: "Name is required" };
            isValid = false;
        }

        if (newField.customerscode.trim() === "") {
            newErrors = { ...newErrors, customerscode: "Customers code is required" };
            isValid = false;
        }

        if (newField.contactPerson.trim() === "") {
            newErrors = { ...newErrors, contactPerson: "Contact person is required" };
            isValid = false;
        }

        if (newField.contactNumber.trim() === "") {
            newErrors = { ...newErrors, contactNumber: "Contact number is required" };
            isValid = false;
        }

        if (newField.email.trim() === "") {
            newErrors = { ...newErrors, email: "Email is required" };
            isValid = false;
        }

        if (newField.address.trim() === "") {
            newErrors = { ...newErrors, address: "Address is required" };
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    }

    const submit = async () => {
        try {
            const isValid = validate();

            if (isValid) {
                setLoading(true);
                const customersData = {
                    CustomerCode: newField.customerscode.toUpperCase(),
                    CustomerName: newField.name.toUpperCase(),
                    ContactPerson: newField.contactPerson.toUpperCase(),
                    ContactNumber: newField.contactNumber,
                    Email: newField.email,
                    Address: newField.address
                };

                console.log("Submitting with customersData:", customersData);

                if (props.details && props.details._id) {
                    console.log("Updating customer with id:", props.details._id);
                    await updateCustomers(props.details._id, customersData);
                    showNotification("success", "Success", "Updated the customer successfully");
                    props.handlesuccess();
                } else {
                    console.log("Adding new customer:", customersData);
                    await addCustomers(customersData);
                    showNotification("success", "Success", "Added the customer successfully");
                    props.handlesuccess();
                }

                setLoading(false);
                props.handleclose();
            }
        } catch (err) {
            console.error("Error adding/updating customer:", err);
            setLoading(false);
            showNotification("error", "Error", "Failed to add/update customer.");
        }
    };

    useEffect(() => {
        console.log("props.details:", props.details);
        if (props.details) {
            console.log("Setting newField with props.details:", props.details);
            setNewField({
                name: props.details.CustomerName,
                customerscode: props.details.CustomerCode,
                contactPerson: props.details.ContactPerson,
                contactNumber: props.details.ContactNumber,
                email: props.details.Email,
                address: props.details.Address
            });
        }
    }, [props.details]);


    return (
        <Dialog
            id="modal-display"
            open={props.isopen}
            aria-labelledby="form-dialog-title"
            aria-describedby="modal-modal-description"
            maxWidth="90%"
            maxHeight="90%"
            onClose={props.handleclose}
            disableBackdropClick
        >
            <div>
                <DialogTitle>Add / Edit Customer</DialogTitle>
            </div>
            <DialogContent sx={{ minWidth: 500 }}>
                <Grid container direction="column" spacing={2}>
                    <Grid item >
                        <MDInput
                            label="Customer Name"
                            value={newField.name}
                            fullWidth
                            onChange={(e) => {
                                setNewField({
                                    ...newField,
                                    name: e.target.value
                                });
                            }}
                            helperText={
                                <FormHelperText sx={{ color: '#FF0000', fontWeight: 800, margin: 0 }}>
                                    {errors.name && "Customer Name is required"}
                                </FormHelperText>
                            }
                        />

                    </Grid>
                    <Grid item>
                        <MDInput
                            fullWidth
                            label="Customer code"
                            value={newField.customerscode}
                            helperText={<FormHelperText sx={{ color: 'red', fontWeight: 800, margin: 0 }}>{errors.customerscode}</FormHelperText>}
                            onChange={(e) => {
                                setNewField({
                                    ...newField,
                                    customerscode: e.target.value.toUpperCase()
                                });
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <MDInput
                            label="Contact person"
                            value={newField.contactPerson}
                            helperText={<FormHelperText sx={{ color: 'red', fontWeight: 800, margin: 0 }}>{errors.contactPerson}</FormHelperText>}
                            fullWidth
                            onChange={(e) => {
                                setNewField({
                                    ...newField,
                                    contactPerson: e.target.value
                                });
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <MDInput
                            label="Contact Number"
                            value={newField.contactNumber}
                            helperText={
                                <FormHelperText sx={{ color: 'red', fontWeight: 800, margin: 0 }}>
                                    {errors.contactNumber ? 'Please enter a valid 10-digit contact number.' : ''}
                                </FormHelperText>
                            }
                            fullWidth
                            onChange={(e) => {
                                const value = e.target.value;
                                setNewField({
                                    ...newField,
                                    contactNumber: value
                                });
                                if (!(/^\d{10}$/.test(value))) {
                                    setErrors(errors => ({ ...errors, contactNumber: true }));
                                } else {
                                    setErrors(errors => ({ ...errors, contactNumber: false }));
                                }
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <MDInput
                            label="Email"
                            value={newField.email}
                            helperText={
                                <FormHelperText sx={{ color: 'red', fontWeight: 800, margin: 0 }}>
                                    {errors.email ? 'Please enter a valid email.' : ''}
                                </FormHelperText>
                            }
                            fullWidth
                            onChange={(e) => {
                                const value = e.target.value;
                                setNewField({
                                    ...newField,
                                    email: value
                                });
                                if (!/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/.test(value)) {
                                    setErrors(errors => ({ ...errors, email: true }));
                                } else {
                                    setErrors(errors => ({ ...errors, email: false }));
                                }
                            }}
                        />

                    </Grid>
                    <Grid item>
                        <MDInput
                            label="Address"
                            multiline
                            rows={3}
                            value={newField.address}
                            helperText={<FormHelperText sx={{ color: 'red', fontWeight: 800, margin: 0 }}>{errors.address}</FormHelperText>}
                            fullWidth
                            onChange={(e) => {
                                setNewField({
                                    ...newField,
                                    address: e.target.value
                                });
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <MDButton variant="gradient" color="error" onClick={props.handleclose}>
                    <Icon>close</Icon>&nbsp;
                    Cancel
                </MDButton>
                <MDButton variant="gradient" color="success" onClick={submit}>
                    <Icon>save</Icon>&nbsp;
                    Save
                </MDButton>
            </DialogActions>
        </Dialog>
    )
}
