// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from '@mui/material/Stack';
import Icon from "@mui/material/Icon";

import * as XLSX from 'xlsx';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import TableData from "./data/tableColumns";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import MDButton from "components/MDButton";
import { getLots, getSuppliers } from "network/service";
import { useLoading } from "context/loading.context";
import { useMaterialUIController } from "context";

import moment from "moment";
import MDInput from "components/MDInput";


function LotsList() {
    const { showLoader, hideLoader } = useLoading();
    const { lotHeaders, sampleRows, formLotRows } = TableData();
    const [controller, dispatch] = useMaterialUIController();
    const [from, setFrom] = useState(moment().subtract(30, 'days').toDate());
    const [to, setTo] = useState(new Date());
    const { sidenavColor } = controller;
    const navigate = useNavigate();
    const [lots, setLots] = useState([]);


    useEffect(() => {
        let param = `Status.StatusCode=CMPL`;
        if (from) {
            if (param.length > 0) param += `&`
            param += `EndTime_gte=${moment(from).format("YYYY-MM-DD")}`
        }
        if (to) {
            if (param.length > 0) param += `&`
            param += `EndTime_lte=${moment(to).format("YYYY-MM-DD 23:59:59")}`
        }
        console.log("Framed param", param);
        showLoader();
        getLots(param).then((resp) => {
            setLots(resp.data);
            hideLoader();
        }).catch((err) => {
            hideLoader();
            console.log(err);
        })
    }, [setLots, from, to])



    const exportToExcel = (data, filename) => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, `${filename}.xlsx`);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor={sidenavColor}
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Lots
                                </MDTypography>
                            </MDBox>
                            {/* <Stack
                                direction="row-reverse"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={2}
                            >
                                <MDBox pt={3} pr={3}>
                                    <MDButton variant="gradient" color="success"
                                        onClick={() => { navigate('/purchases/bills/add'); }}>
                                        <Icon>add</Icon>&nbsp;
                                        Purchase
                                    </MDButton>
                                </MDBox>
                            </Stack> */}

                            <MDBox pt={3} px={2} py={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={4} xl={2}>
                                        <MDInput fullWidth type="date" label={"From"} value={moment(from).format("YYYY-MM-DD")} onChange={(event) => {
                                            setFrom(event.target.valueAsDate);
                                        }}></MDInput>
                                    </Grid>
                                    <Grid item xs={12} md={4} xl={2}>
                                        <MDInput fullWidth type="date" label={"To"} value={moment(to).format("YYYY-MM-DD")} onChange={(event) => {
                                            setTo(event.target.valueAsDate);
                                        }}></MDInput>
                                    </Grid>
                                </Grid>
                            </MDBox>

                            <MDBox sx={{ maxHeight: "50vh", overflow: 'auto' }}>
                                <DataTable
                                    table={{
                                        columns: lotHeaders,
                                        rows: formLotRows(lots)
                                    }}
                                    noEndBorder
                                    canSearch
                                    onRowClick={(data, ind) => { console.log(data, ind); navigate('/production/lots/list/details'); }}
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default LotsList;
