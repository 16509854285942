// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useEffect, useState } from "react";
import { Divider, FormControl, Icon, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import MDInput from "components/MDInput";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import { getCustomers, addSaleOrder, getInventoryFinishedGoods } from "network/service";
//import { addInventoryProduct } from "network/service";
import { useLoading } from "context/loading.context";
import { useNotification } from "context/notification.context";
import { useNavigate } from 'react-router-dom';
import { useMaterialUIController } from "context";
import { ArrowDropDown, Description } from "@mui/icons-material";
import moment from "moment";
import DataTable from "examples/Tables/DataTable";
import { FormHelperText } from "@mui/material";




const saleOder_items_headers = [
    { Header: "Item", accessor: "item", align: "center" },
    { Header: "Quantity", accessor: "quantity", align: "center" },
    { Header: "Rate", accessor: "rate", align: "center" },
    { Header: "Tax(%)", accessor: "tax", align: "center" },
    { Header: "Amount", accessor: "amount", align: "center" },
    { Header: "Action", accessor: "action", align: "center" }
]



function AddSaleOrders() {
    const [controller, dispatch] = useMaterialUIController();
    const { sidenavColor } = controller;
    const navigate = useNavigate();
    const { showNotification } = useNotification();
    const { showLoader, hideLoader } = useLoading();

    const [Customers, setCustomers] = useState([]);
    const [finshedGoods, setFinshedGoods] = useState([]);
    const [itemTax, setItemTax] = useState("Tax Exclusive");
    const [selCustomer, setSelCustomer] = useState(null);
    const [from, setFrom] = useState(new Date());
    const [to, setTo] = useState(new Date());
    const [saleOderNo, setSaleOderNo] = useState("")
    const [selItem, setSelItem] = useState(null);
    const [newVal, setNewVal] = useState({ item: "", quantity: "", rate: "", tax: "", amount: "", description: "", customer: "", saleorddate: moment().format("YYYY-MM-DD"), reqdeldate: moment().format("YYYY-MM-DD") });
    const [saleOrderItems, setSaleOrderItems] = useState([]);
    const [saleOrderamt, setSaleOrderamt] = useState("");
    const [description, setDescription] = useState("")
    const [errors, setErrors] = useState({});
    const [total, setTotal] = useState();
    const starteddate = new Date();
    const [itmesadded, setItmesAdded] = useState(false);

    function convertLiters(liters) {
        if (liters >= 1) {
            return `${liters} L`;
        } else {
            const milliliters = liters * 1000;
            return `${milliliters} ml`;
        }
    }

    const getProductName = (data) => {
        return data.Product.ProductName;
    }


    const deleteSaleOrders = (index) => {
        let tempCopy = [...saleOrderItems];
        tempCopy.splice(index, 1);
        setSaleOrderItems([...tempCopy]);
    }
    const calculateTotalAmount = () => {
        let total = 0;
        for (let item of saleOrderItems) {
            total += parseFloat(item.amount);
        }
        return total.toFixed(2);
    };

    useEffect(() => {
        setTotal(calculateTotalAmount());
    }, [saleOrderItems]);

    const formSaleOdersItemRows = (data) => {
        let result = [];
        let total = 0;
        console.log(data);
        for (let dt in data) {
            total += parseFloat(data[dt].amount);
            result.push({
                item: data[dt].Product.ProductName,
                quantity: data[dt].quantity,
                rate: data[dt].rate,
                tax: data[dt].tax,
                description: data[dt].description,
                amount: data[dt].amount,
                totalAmount: data[dt].totalAmount,
                action: < MDButton variant="outlined" color="error"
                    iconOnly
                    onClick={() => { deleteSaleOrders(dt) }}>
                    <Icon>delete</Icon>
                </MDButton >
            });
        }
        result.push({
            tax: "Total",
            amount: total.toFixed(2),
            //totalAmount:total.toFixed(2)
        });
        return result
    }


    const goBack = () => {
        navigate(-1);
    };

    const fetchFinshedGoods = () => {

    }


    useEffect(() => {
        fetchFinshedGoods();
    }, []);

    const handleInput = (event, tax_val = itemTax) => {
        let temp = { ...newVal };
        let value = parseFloat(event.target.value);

        switch (event.target.name) {
            case 'quantity':
                if (value >= 0) {
                    temp.quantity = value.toString();
                }
                break;
            case 'rate':
                if (value >= 0) {
                    temp.rate = value.toString();
                }
                break;
            case 'tax':
                if (value >= 0) {
                    temp.tax = value.toString();
                }
                break;
            case 'amount':
                if (value >= 0) {
                    temp.amount = value.toString();
                }
                break;
        }

        // Recalculate amount based on new values
        if (parseFloat(temp.quantity) > 0 && parseFloat(temp.rate) > 0) {
            let tax = parseFloat(temp.tax) || 0;
            let quantity = parseFloat(temp.quantity);
            let rate = parseFloat(temp.rate);
            let amount = (quantity * rate).toFixed(2);
            if (tax_val === 'Tax Exclusive') {
                amount = (quantity * rate * (1 + tax / 100)).toFixed(2);
            }
            temp.amount = amount.toString();
        }

        setNewVal({ ...temp });
    };



    useEffect(() => {
        getCustomers().then((resp) => {
            console.log(resp.data);
            setCustomers(resp.data);
        }).catch((err) => {
            console.log("Error fetching suppliers", err);
        })
    }, [setCustomers]);

    useEffect(() => {
        getInventoryFinishedGoods().then((resp) => {
            console.log(resp.data);
            setFinshedGoods(resp.data);
        }).catch((err) => {
            console.log("Error fetching Raw materials", err);
        })
    }, [setFinshedGoods]);

    const validate_items = () => {
        let isValid = true;
        let errors = {}
        if (!selItem) {
            errors.item = "Item is required"
            isValid = false;
        }
        if ((newVal.quantity) <= 0) {
            errors.quantity = "Quantity  is required"
            isValid = false;
        }
        if ((newVal.rate) <= 0) {
            errors.rate = "Rate  is required"
            isValid = false;
        }
        if (newVal.tax === "") {
            errors.tax = "Tax  is required"
            isValid = false;
        }
        if (newVal.amount === "") {
            errors.amount = "Amount is required"
            isValid = false;
        }

        setErrors(errors);
        return isValid;
    }
    const addSaleItem = () => {
        setItmesAdded(true)
        if (validate_items()) {
            let sel_item = { ...selItem };
            setSaleOrderItems([...saleOrderItems, {
                ...sel_item,
                quantity: newVal.quantity,
                rate: newVal.rate,
                tax: (newVal.tax.length > 0) ? newVal.tax : "0",
                amount: newVal.amount
            }]);
            setSelItem(null);
            setNewVal({ amount: "", rate: "", tax: "", quantity: "" });
        }

    }


    // const isAmountEqual = (total, items) => {
    //     let total_float = parseFloat(total).toFixed(2);
    //     let calculated_total = 0;
    //     for (let dtl of items) {
    //         calculated_total += parseFloat(dtl.amount);
    //     }
    //     if (calculated_total.toFixed(2) == total_float) return true;
    //     else return false;
    // }

    const validateSaleOder = () => {
        let isValid = true;
        let errors = {}
        if (!selCustomer) {
            errors.customer = "Customer is required"
            isValid = false;
        }
        if (newVal.saleorddate === "") {
            errors.saleorddate = "Sale Order date is required"
            isValid = false;
        }

        if (newVal.reqdeldate === "") {
            errors.saleorddate = "Delivery  date is required"
            isValid = false;
        }
        else if (to < from) {
            errors.reqdeldate = "Delivery date must greater than sale order date."
            isValid = false;
        }
        if (newVal.item === "") {
            errors.item = "Item is required"
            isValid = false

        }

        setErrors(errors);
        return isValid;
    }

    const submit = () => {
        if (validateSaleOder()) {
            let details = { OrderDetails: [...saleOrderItems], Customer: { ...selCustomer }, OrderDate: from, TotalAmount: total, Description: description, RequiredDeliveryDate: to };
            showLoader();
            addSaleOrder({ ...details }).then((resp) => {
                console.log(resp.data);
                console.log("save");
                hideLoader();
                showNotification("success", "Success", "Successfully added the bill details");
                goBack();
            }).catch((err) => {
                console.log(err);
                showNotification("error", "Failed", "Failed to add the bill details");
                hideLoader();
            })
        }

    }
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={12}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor={sidenavColor}
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Add SaleOrders
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3} px={2} py={3}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} md={12} xl={12}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={6} xl={4}>
                                                <Autocomplete
                                                    disableClearable
                                                    label={"Customer"}
                                                    sx={{ flexGrow: 1 }}
                                                    options={Customers}
                                                    getOptionLabel={(option) => option.CustomerName || ''}
                                                    renderInput={(params) => <MDInput {...params} label={"Customers"} fullWidth />}
                                                    onChange={(event, newValue) => {
                                                        setSelCustomer(newValue);
                                                    }}
                                                    value={selCustomer}

                                                />
                                                {(!newVal.customer || newVal.customer === 0) && (
                                                    <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 1 }}>
                                                        {errors.customer}
                                                    </FormHelperText>
                                                )}
                                            </Grid>

                                            <Grid item xs={12} md={6} xl={2}>
                                                <FormControl sx={{ m: 0, minWidth: 187 }} fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Tax</InputLabel>
                                                    <Select
                                                        label="Tax"
                                                        labelId="demo-simple-select-label"
                                                        style={{ height: 45, paddingRight: 10 }}
                                                        value={itemTax}
                                                        onChange={(e) => {
                                                            setItemTax(e.target.value);
                                                            handleInput({ target: { name: "tax", value: newVal.tax } }, e.target.value);
                                                            let temp = [...saleOrderItems];
                                                            for (let dtin in temp) {
                                                                let rate = parseFloat(temp[dtin].rate);
                                                                let quantity = parseFloat(temp[dtin].quantity);
                                                                if (e.target.value === "Tax Inclusive") {
                                                                    temp[dtin].amount = (quantity * rate).toFixed(2);
                                                                }
                                                                else {
                                                                    if (parseFloat(temp[dtin].tax) > 0) {
                                                                        temp[dtin].amount = ((quantity * rate) * (1 + (parseFloat(temp[dtin].tax) / 100))).toFixed(2);
                                                                    }
                                                                }
                                                                setSaleOrderItems([...temp]);
                                                            }
                                                        }}
                                                        displayEmpty
                                                        IconComponent={() => { return (<ArrowDropDown />) }}
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                    >
                                                        <MenuItem value={"Tax Inclusive"}>Tax Inclusive</MenuItem>
                                                        <MenuItem value={"Tax Exclusive"}>Tax Exclusive</MenuItem>
                                                    </Select>
                                                    {(newVal.seltax === 0) && (
                                                        <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 1 }}>
                                                            {errors.seltax}
                                                        </FormHelperText>
                                                    )}
                                                </FormControl>

                                                {/* <MDInput
                                                    fullWidth
                                                    label="Sale Order No."
                                                    value={saleOderNo}
                                                    disabled={true}
                                                    onChange={(e) => { setSaleOderNo(e.target.value) }}
                                                /> */}

                                            </Grid>

                                            <Grid item xs={12} md={6} xl={3}>
                                                <MDInput type="date" fullWidth label="SaleOder Date" value={moment(from).format("YYYY-MM-DD")} onChange={(event) => {
                                                    setFrom(event.target.valueAsDate);
                                                }}></MDInput>
                                                <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 1 }}>
                                                    {errors.saleorddate}
                                                </FormHelperText>
                                            </Grid>

                                            <Grid item xs={12} md={6} xl={3}>
                                                <MDInput type="date" fullWidth label="Require Delivery Date." value={moment(to).format("YYYY-MM-DD")} onChange={(event) => {
                                                    setTo(event.target.valueAsDate);
                                                }}></MDInput>
                                                <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 1 }}>
                                                    {errors.reqdeldate}
                                                </FormHelperText>
                                            </Grid>
                                        </Grid>

                                    </Grid>

                                    {/* <Grid item xs={12} md={6} xl={4}>
                                        <FormControl sx={{ m: 0, minWidth: 187 }} fullWidth>
                                            <InputLabel id="demo-simple-select-label">Tax</InputLabel>
                                            <Select
                                                label="Tax"
                                                labelId="demo-simple-select-label"
                                                style={{ height: 45, paddingRight: 10 }}
                                                value={itemTax}
                                                onChange={(e) => {
                                                    setItemTax(e.target.value);
                                                    handleInput({ target: { name: "tax", value: newVal.tax } }, e.target.value);
                                                    let temp = [...saleOrderItems];
                                                    for (let dtin in temp) {
                                                        let rate = parseFloat(temp[dtin].rate);
                                                        let quantity = parseFloat(temp[dtin].quantity);
                                                        if (e.target.value === "Tax Inclusive") {
                                                            temp[dtin].amount = (quantity * rate).toFixed(2);
                                                        }
                                                        else {
                                                            if (parseFloat(temp[dtin].tax) > 0) {
                                                                temp[dtin].amount = ((quantity * rate) * (1 + (parseFloat(temp[dtin].tax) / 100))).toFixed(2);
                                                            }
                                                        }
                                                        setSaleOrderItems([...temp]);
                                                    }
                                                }}
                                                displayEmpty
                                                IconComponent={() => { return (<ArrowDropDown />) }}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                            >
                                                <MenuItem value={"Tax Inclusive"}>Tax Inclusive</MenuItem>
                                                <MenuItem value={"Tax Exclusive"}>Tax Exclusive</MenuItem>
                                            </Select>
                                        </FormControl>

                                    </Grid>
                                    <Grid item xs={12} md={6} xl={8}>
                                        <MDInput type="string" label="Description" multiline rows={1} value={description}
                                            onChange={(e) => {
                                                setDescription(e.target.value)
                                            }}
                                            fullWidth ></MDInput>
                                    </Grid> */}
                                    <Grid item xl={12} md={12} xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12} md={12} xl={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6} xl={4}>
                                                <Autocomplete
                                                    label={"Item"}
                                                    sx={{ flexGrow: 1 }}

                                                    options={finshedGoods}
                                                    getOptionLabel={getProductName}
                                                    renderInput={(params) => <MDInput {...params} label={"Item"} fullWidth
                                                        isOptionEqualToValue={(option, value) => option._id === value._id}
                                                    />}
                                                    onChange={(event, newValue) => {

                                                        setSelItem(newValue);
                                                    }}
                                                    value={selItem}
                                                />
                                                <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 1 }}>
                                                    {errors.item}
                                                </FormHelperText>

                                            </Grid>

                                            <Grid item xs={12} md={6} xl={2}>
                                                <Grid container sx={{ alignItems: 'center' }}>
                                                    <MDInput
                                                        sx={{ flexGrow: 1 }}
                                                        name="quantity"
                                                        label="Quantity"
                                                        type="number"
                                                        value={newVal.quantity}
                                                        onChange={handleInput}
                                                    />
                                                    <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 1 }}>
                                                        {errors.quantity}
                                                    </FormHelperText>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={2}>
                                                <MDInput
                                                    fullWidth
                                                    name="rate"
                                                    label="Rate"
                                                    type="number"
                                                    value={newVal.rate}
                                                    onChange={handleInput}
                                                />
                                                <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 1 }}>
                                                    {errors.rate}
                                                </FormHelperText>
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={1}>
                                                <MDInput
                                                    fullWidth
                                                    name="tax"
                                                    label="Tax %"
                                                    type="number"
                                                    value={newVal.tax}
                                                    onChange={handleInput}
                                                />
                                                <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 1 }}>
                                                    {errors.tax}
                                                </FormHelperText>
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={2}>
                                                <MDInput
                                                    fullWidth
                                                    name="amount"
                                                    label="Amount"
                                                    type="number"
                                                    value={newVal.amount}
                                                    onChange={handleInput}
                                                />
                                                <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 1 }}>
                                                    {errors.amount}
                                                </FormHelperText>
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={1}>
                                                <Grid container alignItems={"center"} justifyContent={"center"}>
                                                    <MDButton variant="outlined" color="success"
                                                        iconOnly
                                                        sx={{ width: "80%" }}
                                                        onClick={() => { addSaleItem() }}>
                                                        <Icon>add</Icon>&nbsp;

                                                        ADD  </MDButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xl={12} md={12} xs={12}>
                                        <DataTable
                                            table={{
                                                columns: saleOder_items_headers,
                                                rows: formSaleOdersItemRows(saleOrderItems)
                                            }}
                                            showTotalEntries={false}
                                            isSorted={false}
                                            noEndBorder
                                            entriesPerPage={false}
                                        />
                                    </Grid>
                                </Grid>
                                <Stack
                                    direction="row-reverse"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <MDBox pt={3} pr={3}>
                                        <MDButton variant="gradient" color="success"
                                            disabled={(saleOrderItems.length == 0)}
                                            onClick={() => { submit() }}>

                                            <Icon>save</Icon>&nbsp;
                                            Save
                                        </MDButton>
                                    </MDBox>
                                    <MDBox pt={3}>
                                        <MDButton variant="gradient" color="error" onClick={() => { goBack(); }}>
                                            <Icon>close</Icon>&nbsp;
                                            Cancel
                                        </MDButton>
                                    </MDBox>

                                </Stack>

                            </MDBox>

                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default AddSaleOrders;
