/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "layouts/dashboard/components/ReportsBarChart";
import ReportsLineChart from "layouts/dashboard/components/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Card from "@mui/material/Card";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import NotificationOverview from "./components/NotificationsOverview";

import { useMaterialUIController, getColor } from "context";

import { getDashboard } from "network/service";
import { getLotsTrend } from "network/service";
import { getProducedQtyTrend } from "network/service";
import { getPerformanceTrend } from "network/service";
//icons
import lotcount from "assets/images/icons/tool-box_icon.png"
import Pendingsale from "assets/images/icons/bills_icon.png"
import incidnets from "assets/images/icons/machine_icon.png"
import lowstock from "assets/images/icons/lowstock_icon.png"
const moment = require("moment");

const dummy_data = [
  {
    color: "success",
    icon: "notifications",
    title: "Lot Number #BT2134 started",
    dateTime: "22 DEC 7:20 PM",
  },
  {
    color: "error",
    icon: "inventory_2",
    title: "New order #1832412",
    dateTime: "21 DEC 11 PM"
  },
  {
    color: "info",
    icon: "shopping_cart",
    title: "New Purchase from Vendor 1",
    dateTime: "21 DEC 9:34 PM"
  },
  {
    color: "warning",
    icon: "payment",
    title: "Cleaning section Unit 1 device offline",
    dateTime: "20 DEC 2:20 AM"
  },
  {
    color: "primary",
    icon: "vpn_key",
    title: "Lot Number #BT987 completed with 80% efficiency",
    dateTime: "18 DEC 4:54 AM"
  },
  {
    color: "success",
    icon: "notifications",
    title: "Lot Number #BT2134 started",
    dateTime: "22 DEC 7:20 PM",
  },
  {
    color: "error",
    icon: "inventory_2",
    title: "New order #1832412",
    dateTime: "21 DEC 11 PM"
  },
  {
    color: "info",
    icon: "shopping_cart",
    title: "New Purchase from Vendor 1",
    dateTime: "21 DEC 9:34 PM"
  },
  {
    color: "warning",
    icon: "payment",
    title: "Cleaning section Unit 1 device offline",
    dateTime: "20 DEC 2:20 AM"
  },
  {
    color: "primary",
    icon: "vpn_key",
    title: "Lot Number #BT987 completed with 80% efficiency",
    dateTime: "18 DEC 4:54 AM"
  }
];

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [dashdata, setDashData] = useState(null);
  const [lottrend, setLotTrend] = useState({ from: moment().startOf('week').format("YYYY-MM-DD"), to: moment().endOf('week').format("YYYY-MM-DD") });
  const [performTrend, setPerformTrend] = useState({ from: moment().startOf('week').format("YYYY-MM-DD"), to: moment().endOf('week').format("YYYY-MM-DD") });
  const [produceTrend, setProduceTrend] = useState({ from: moment().startOf('week').format("YYYY-MM-DD"), to: moment().endOf('week').format("YYYY-MM-DD") });
  const [lotData, setLotData] = useState({});
  const [performaceData, setPerformanceData] = useState({});
  const [produceData, setProduceData] = useState({});

  useEffect(() => {
    getDashboard().then((resp) => {
      console.log(resp.data);
      setDashData(resp.data);
    }).catch((err) => {
      console.log(err);
    })
  }, [setDashData]);

  useEffect(() => {
    getLotsTrend(`EndTime_gte=${lottrend.from}&EndTime_lte=${lottrend.to} 23:59:59`).then((resp) => {
      console.log(resp.data);

      let labels = [];
      let data = [];
      let type = (Object.keys(resp.data).length > 7) ? 'M' : 'W';
      for (let dt in resp.data) {
        if (type === 'W') {
          labels.push(moment(dt).format('dddd').charAt(0))
        }
        else if (type === 'M') {
          labels.push(moment(dt).format('D'))
        }
        data.push(resp.data[dt].count);
      }
      setLotData({
        labels: labels,
        datasets: { label: "Lots", data: data },
      });
    }).catch((err) => {
      console.log(err);
    })
  }, [setLotData, lottrend]);


  useEffect(() => {
    getProducedQtyTrend(`TransactionDate_gte=${produceTrend.from}&TransactionDate_lte=${produceTrend.to} 23:59:59`).then((resp) => {
      console.log(resp.data);

      let labels = [];
      let data = [];
      let type = (Object.keys(resp.data).length > 7) ? 'M' : 'W';
      for (let dt in resp.data) {
        if (type === 'W') {
          labels.push(moment(dt).format('dddd').charAt(0))
        }
        else if (type === 'M') {
          labels.push(moment(dt).format('D'))
        }
        data.push(resp.data[dt].quantity);
      }
      setProduceData({
        labels: labels,
        datasets: { label: "Produced", data: data },
      });
    }).catch((err) => {
      console.log(err);
    })
  }, [setProduceData, produceTrend]);

  useEffect(() => {
    getPerformanceTrend(`EndTime_gte=${performTrend.from}&EndTime_lte=${performTrend.to} 23:59:59`).then((resp) => {
      console.log(resp.data);

      let labels = [];
      let data = [];
      let type = (Object.keys(resp.data).length > 7) ? 'M' : 'W';
      for (let dt in resp.data) {
        if (type === 'W') {
          labels.push(moment(dt).format('dddd').charAt(0))
        }
        else if (type === 'M') {
          labels.push(moment(dt).format('D'))
        }
        data.push(resp.data[dt].performance);
      }
      setPerformanceData({
        labels: labels,
        datasets: { label: "Lots", data: data },
      });
    }).catch((err) => {
      console.log(err);
    })
  }, [setPerformanceData, performTrend]);


  const LotsTrendDateChange = (range) => {
    console.log(range);
    setLotTrend(range);
  }

  const ProduceTrendDateChange = (range) => {
    console.log(range);
    setProduceTrend(range);
  }

  const PerformaceTrendDateChange = (range) => {
    console.log(range);
    setPerformTrend(range);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
               color="dark"
                icon={<img src={lotcount} width={26} alt="Icon" />}
                title="Lots Count"
                count={dashdata ? dashdata.Lots : 0}
                percentage={{
                  color: "success",
                  amount: dashdata ? dashdata.YesterDayLots : 0,
                  label: "Lots Yesterday",
                }}
              />

            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
               color="dark"
                 icon={<img src={Pendingsale} width={26} alt="Icon" />}
                title="Pending SaleOrders"
                count={dashdata ? dashdata.SaleOrders : 0}
                percentage={{
                  color: "warning",
                  amount: dashdata ? dashdata.PartialSaleOrders : 0,
                  label: "partially completed",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon={<img src={incidnets} width={26} alt="Icon" />}
                title="Incidents"
                count={dashdata ? dashdata.Incidents : 0}
                percentage={{
                  color: "error",
                  amount: dashdata ? dashdata.YesterdayIncidents : 0,
                  label: "yesterday",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon={<img src={lowstock} width={26} alt="Icon" />}
                title="Low Stock"
                count={dashdata ? dashdata.LowStockItems : 0}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="Lots Trend"
                  description=""
                  date="campaign sent 2 days ago"
                  chart={lotData}
                  rangeChange={LotsTrendDateChange}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="Production (Ltrs)"
                  description={""}
                  date="updated 4 min ago"
                  chart={produceData}
                  rangeChange={ProduceTrendDateChange}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="Performance"
                  description=""
                  date="just updated"
                  chart={performaceData}
                  rangeChange={PerformaceTrendDateChange}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8} height={500}>
              {/* <Projects /> */}
              <Card sx={{ height: "100%" }}>
                <MDBox pt={3} px={3}></MDBox></Card>
            </Grid>
            <Grid item xs={12} md={6} lg={4} height={500}>
              <NotificationOverview isDark={darkMode} notifications={dummy_data} />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
