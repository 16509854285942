/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
// import Modal from "@mui/material/Modal";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormGroup from "@mui/material/FormGroup";
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FormHelperText, Icon, Input } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

import { getUOM, getProductCategories, addProduct, uploadFile } from "network/service";
import { useNotification } from "context/notification.context";

export default function AddProductPopup(props) {
    const { showNotification } = useNotification();
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);
    const [newField, setNewField] = useState({ name: "", code: "", type: null, uom: null, description: "" });
    const [uoms, setUOM] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selFile, setSelFile] = useState(null);
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);


      
    useEffect(() => {
        getProductCategories().then((resp) => {
            setCategories(resp.data);
        }).catch((err) => {
            console.log(err);
        });
    }, [setCategories]);

    useEffect(() => {
        getUOM().then((resp) => {
            setUOM(resp.data);
        }).catch((err) => {
            console.log(err);
        });
    }, [setUOM]);

    const handleFileChange = (event) => {
        const sel_file = event.target.files[0];
        setSelFile(sel_file);
    };

    const validate = () => {
        if (newField.name === "") return false;
        if (newField.code === "") return false;
        if (newField.description === "") return false;
        if (!newField.type || newField.type === "") return false;
        if (!newField.uom || newField.uom === "") return false;
        return true;
    };



    const Create = async () => {
        setSubmitted(true);
        if (validate()) {
            try {
                setLoading(true);
                let file_path = null;
                if (selFile) {
                    const formData = new FormData();
                    formData.append('file', selFile);
                    let file_details = await uploadFile(formData);
                    file_path = file_details.data.path;
                }
                addProduct({
                    ProductCategoryID: newField.type,
                    ProductCode: newField.code.toUpperCase(),
                    ProductName: newField.name.toUpperCase(),
                    Description: newField.description,
                    UnitOfMeasure: newField.uom,
                    Image: file_path
                }).then((resp) => {
                    console.log(resp);
                    setLoading(false);
                    showNotification("success", "Successful", "Product added successfully.");
                    props.handlesuccess();
                }).catch((err) => {
                    console.log(err);
                    setLoading(false);
                    showNotification("error", "Failed", "Failed to add Product details.");
                });
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        }
    };

    return (
        <Dialog
            id="modal-display"
            open={props.isopen}
            aria-labelledby="form-dialog-title"
            aria-describedby="modal-modal-description"
            maxWidth="90%"
            maxHeight="90%"
            onClose={props.handleclose}
            disableBackdropClick
        >
            <MDBox>
                <DialogTitle>Add Product</DialogTitle>
            </MDBox>
            <DialogContent sx={{ minWidth: 400 }} >
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <MDInput
                            label="Product Name"
                            value={newField.name}
                            fullWidth
                            helperText={
                                submitted && newField.name === "" && (
                                    <FormHelperText sx={{ color: 'red', fontWeight: 800, margin: 0 }}>
                                        Product name is required
                                    </FormHelperText>
                                )
                            }
                            onChange={(e) => {
                                setNewField({
                                    ...newField,
                                    name: e.target.value.toUpperCase()
                                });
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <MDInput
                            fullWidth
                            label="Product Code"
                            value={newField.code}
                            helperText={
                                submitted && newField.code === "" && (
                                    <FormHelperText sx={{ color: 'red', fontWeight: 800, margin: 0 }}>
                                        Product code is required
                                    </FormHelperText>
                                )
                            }
                            onChange={(e) => {
                                setNewField({
                                    ...newField,
                                    code: e.target.value.toUpperCase()
                                });
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <FormControl
                            sx={{ m: 0, minWidth: 187 }}
                            fullWidth
                            error={submitted && (!newField.type || newField.type === "")}
                        >
                            <InputLabel id="demo-simple-select-label">Type</InputLabel>
                            <Select
                                label="Type"
                                labelId="demo-simple-select-label"
                                style={{ height: 45, paddingRight: 10 }}
                                value={newField.type}
                                onChange={(e) => {
                                    setNewField({
                                        ...newField,
                                        type: e.target.value
                                    });
                                }}
                                displayEmpty
                                fullWidth
                                IconComponent={() => <ArrowDropDown />}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                {categories.map((val, ind) => (
                                    <MenuItem key={val._id} value={val._id}>
                                        {val.ProductCategoryName}
                                    </MenuItem>
                                ))}
                            </Select>
                            {submitted && (!newField.type || newField.type === "") && (
                                <FormHelperText sx={{ color: 'red', fontWeight: 800 }}>
                                    Type is required
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl
                            sx={{ m: 0, minWidth: 187 }}
                            fullWidth
                            error={submitted && (!newField.uom || newField.uom === "")}
                        >
                            <InputLabel id="demo-simple-select-label">UOM</InputLabel>
                            <Select
                                label="UOM"
                                labelId="demo-simple-select-label"
                                style={{ height: 45, paddingRight: 10 }}
                                value={newField.uom}
                                onChange={(e) => {
                                    setNewField({
                                        ...newField,
                                        uom: e.target.value
                                    });
                                }}
                                displayEmpty
                                IconComponent={() => <ArrowDropDown />}
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                {uoms.map((val, ind) => (
                                    <MenuItem key={val._id} value={val._id}>
                                        {val.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {submitted && (!newField.uom || newField.uom === "") && (
                                <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 2 }}>
                                    UOM is required
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <MDInput
                            label="Description"
                            multiline
                            rows={3}
                            fullWidth
                            value={newField.description}
                            onChange={(e) => {
                                setNewField({
                                    ...newField,
                                    description: e.target.value
                                });
                            }}
                            helperText={
                                submitted && newField.description === "" && (
                                    <FormHelperText sx={{ color: 'red', fontWeight: 800, margin: 0 }}>
                                        Description is required
                                    </FormHelperText>
                                )
                            }
                        />
                    </Grid>
                    <Grid item>
                        <MDInput type="file" accept="image/*" disabled={false} fullWidth onChange={handleFileChange} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <MDButton variant="gradient" color="error" onClick={props.handleclose} disabled={loading}>
                    <Icon>close</Icon>&nbsp;
                    Cancel
                </MDButton>
                <MDButton variant="gradient" color="success" disabled={loading} onClick={Create}>
                    <Icon>save</Icon>&nbsp;
                    Save
                </MDButton>
            </DialogActions>
        </Dialog>
    );
}
