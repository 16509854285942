/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

export default function TableData() {


    const formProductRows = (data) => {
        let result = [];
        for (let dt of data) {
            result.push({
                name: dt.Product.ProductName,
                code: dt.Product.ProductCode,
                category: dt.Product.ProductCategoryID.ProductCategoryName,
                uom: dt.isCollection ? "Number" : dt.Product.UnitOfMeasure.name,
                available: dt.QuantityOnHand,
                min: dt.MinQuantity,
                max: dt.MaxQuantity,
                toStock: dt.ToStockQuantity,
                isCollection: dt.isCollection ? "Yes" : "No",
                QtyOfCollection: dt.UnitsInCollection,
                QtyOfUnit: dt.QtyOfUnit
            });
          
        }
        return result
    }

    return {
        formProductRows,
        productHeaders: [
            { Header: "Name", accessor: "name", align: "left" },
            { Header: "Code", accessor: "code", align: "center" },
            // { Header: "Category", accessor: "category", align: "center" },
            { Header: "UOM", accessor: "uom", align: "center" },
            { Header: "Available", accessor: "available", align: "center" },
            { Header: "Min Qty", accessor: "min", align: "center" },
            { Header: "Max Qty", accessor: "max", align: "center" },
            { Header: "To Stock", accessor: "toStock", align: "center" },
            { Header: "isCollection", accessor: "isCollection", align: "center" },
            { Header: "QtyofCollection", accessor: "QtyOfCollection", align: "center" },
            { Header: "Qty of Unit", accessor: "QtyOfUnit", align: "center" }
        ],
        
    };
}
