import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";
import * as XLSX from "xlsx";
import moment from "moment";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { getProductCategories, getInventoryItems, getInventoryRawMaterials } from "network/service";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLoading } from "context/loading.context";
import { useMaterialUIController } from "context";
import { Autocomplete } from "@mui/material";
import TableData from "./data/tableColumns";
import MDInput from 'components/MDInput';
import { Tab, Box } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import FinishedGoodsPage from "./components/FinishedGoods";

function Products() {
    const { showLoader, hideLoader } = useLoading();
    const { productHeaders, formProductRows } = TableData();
    const [controller] = useMaterialUIController();
    const { sidenavColor } = controller;

    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [productList, setProductList] = useState([]);
    const [selProduct, setSelProduct] = useState(null);

    const getInitialValue = () => {
        const savedValue = sessionStorage.getItem('tabValue');
        return savedValue ? savedValue : '1';
    };

    const [value, setValue] = useState(getInitialValue);

    useEffect(() => {
        // Store the state in session storage whenever it changes
        sessionStorage.setItem('tabValue', value);
    }, [value]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleRowClick = (pdt, index) => {
        console.log("Data in click", pdt);
        navigate("/inventory/products/item", { state: { data: products[index] } });
    };

    useEffect(() => {
        getProductCategories()
            .then((resp) => {
                setProductList(resp.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    // useEffect(() => {
    //     let param = "";
    //     if (selProduct) {
    //         if (param.length > 0) param += `&`;
    //         param += `Product.ProductCategoryID=${selProduct._id}`;
    //     }
    //     console.log("Framed param", param);
    //     showLoader();
    //     getInventoryRawMaterials(param)
    //         .then((resp) => {
    //             setProducts(resp.data);
    //             hideLoader();
    //         })
    //         .catch((err) => {
    //             hideLoader();
    //             console.log(err);
    //         });
    // }, [selProduct]);

    useEffect(() => {
        showLoader();
        getInventoryRawMaterials()
            .then((resp) => {
                setProducts(resp.data);
                hideLoader();
            })
            .catch((err) => {
                hideLoader();
                console.error(err);
            });
    }, []);

    // console.log(products);

    const exportToExcel = (data, filename) => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, `${filename}.xlsx`);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={1} pb={3}>
                <MDBox>
                    <TabContext value={value}>
                        <MDBox sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Raw Materials" value="1" />
                                <Tab label="Products" value="2" />
                            </TabList>
                        </MDBox>
                        <TabPanel value="1"><Grid container>
                            <Grid item xs={12} mt={5}>
                                <Card>
                                    <MDBox
                                        mx={2}
                                        mt={-3}
                                        py={3}
                                        px={2}
                                        variant="gradient"
                                        bgColor={sidenavColor}
                                        borderRadius="lg"
                                        coloredShadow="info"
                                    >
                                        <MDTypography variant="h6" color="white">
                                            Products Table
                                        </MDTypography>
                                    </MDBox>
                                    <Stack
                                        direction="row-reverse"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        <MDBox pt={3} pr={3}>
                                            <MDButton
                                                variant="gradient"
                                                color="success"
                                                onClick={() => {
                                                    navigate("/inventory/products/add");
                                                }}
                                            >
                                                <Icon>add</Icon>&nbsp;Add
                                            </MDButton>
                                        </MDBox>
                                        <MDBox pt={3}>
                                            <MDButton
                                                variant="gradient"
                                                color="warning"
                                                onClick={() => {
                                                    exportToExcel(formProductRows(products), moment().unix());
                                                }}
                                            >
                                                <Icon>download</Icon>&nbsp;Export
                                            </MDButton>
                                        </MDBox>
                                    </Stack>
                                    {/* <MDBox pt={3} px={2} py={3}>
                                        <Grid item xs={12} md={4} xl={3}>
                                            <Autocomplete
                                                label={"Products"}
                                                sx={{ flexGrow: 1 }}
                                                options={productList}
                                                getOptionLabel={(option) => option.ProductCategoryName || ''}
                                                renderInput={(params) => <MDInput {...params} label={"categories"} fullWidth />}
                                                onChange={(event, newValue) => {
                                                    setSelProduct(newValue);
                                                }}
                                                value={selProduct}
                                            />
                                        </Grid>
                                    </MDBox> */}
                                    <MDBox sx={{ maxHeight: "50vh", overflow: 'auto' }}>
                                        <DataTable
                                            table={{
                                                columns: productHeaders,
                                                rows: formProductRows(products),
                                            }}
                                            noEndBorder
                                            canSearch
                                            onRowClick={handleRowClick}
                                        />
                                    </MDBox>
                                </Card>
                            </Grid>
                        </Grid></TabPanel>
                        <TabPanel value="2"><FinishedGoodsPage /></TabPanel>
                    </TabContext>
                </MDBox>

            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Products;
