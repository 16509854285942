/* eslint-disable react/prop-types */
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import { Autocomplete } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import MDInput from 'components/MDInput';
import { styled } from '@mui/system';

import { useMaterialUIController } from "context";

import { getFinishedGoodInventory, FILE_API_ENDPOINT } from "network/service";
import { useNotification } from "context/notification.context";
import ProductInfoCard from "../ProductInfoCard";

export default function FinishedGoodsPage(props) {
    const { showNotification } = useNotification();
    const [controller] = useMaterialUIController();
    const [products, setProducts] = useState([]);
    const { sidenavColor } = controller;
    const navigate = useNavigate();

    const ClickableCard = styled(Card)({
        cursor: 'pointer'
    });

    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredProducts = products.filter((product) =>
        product.Product.ProductName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        getFinishedGoodInventory().then((resp) => {
            setProducts(resp.data);
        }).catch((err) => {
            console.log(err);
        })
    }, [setProducts])

    return (
        <Grid container>
            <Grid item xs={12} mt={5}>
                <Card>
                    <MDBox
                        mx={2}
                        mt={-3}
                        py={3}
                        px={2}
                        variant="gradient"
                        bgColor={sidenavColor}
                        borderRadius="lg"
                        coloredShadow="info"
                    >
                        <MDTypography variant="h6" color="white">
                            Products
                        </MDTypography>
                    </MDBox>
                    <Stack
                        direction="row-reverse"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                    >
                        <MDBox pt={3} pr={3}>
                            <MDInput label={"Search"} value={searchTerm} onChange={handleSearch}></MDInput>
                        </MDBox>
                    </Stack>
                    <MDBox px={2} py={3}>
                        <Grid container spacing={3}>
                            {filteredProducts.map((data, ind) => <Grid item xs={12} md={6} xl={3}> <ProductInfoCard image={data.Product.Image ? `${FILE_API_ENDPOINT}${data.Product.Image}` : require("assets/images/gallery.png")} icon="account_balance"
                                title={data.Product.ProductName}
                                description={data.QuantityOnHand}
                                value={data.QuantityRequired}
                                onClick={() => { navigate("/inventory/products/item", { state: { data: data } }); }}
                            /></Grid>)}
                        </Grid>
                    </MDBox>
                </Card>
            </Grid>
        </Grid>
    );
}
