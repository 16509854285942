/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

import { Icon } from "@mui/material";
import MDButton from "components/MDButton";

export default function TableData() {

    const formRecipeRows = (data, deleteitem) => {
        let result = [];
        for (let dt in data) {
            result.push({
                name: data[dt].recipe.RecipeName,
                quantity: data[dt].quantity,
                action: <MDButton variant="outlined" color="info" iconOnly onClick={() => {
                    deleteitem(dt);
                }}>
                    <Icon>delete</Icon>
                </MDButton>
            });
        }
        return result
    }

    const formReportRows = (data) => {
        let result = [];
        for (let dt in data) {
            let temp_to_order = ((data[dt].QuantityOnHand - data[dt].Required) < data[dt].ToStockQuantity) ? data[dt].ToStockQuantity - (data[dt].QuantityOnHand - data[dt].Required) : 0
            result.push({
                item: data[dt].Product.ProductName,
                code: data[dt].Product.ProductCode,
                available: data[dt].QuantityOnHand,
                required: data[dt].Required,
                deficit: data[dt].Deficit,
                tostock: data[dt].ToStockQuantity,
                toorder: temp_to_order
            });
        }
        return result
    }

    return {
        formRecipeRows,
        formReportRows,
        recipeHeaders: [
            { Header: "Name", accessor: "name", align: "center" },
            { Header: "Planned Quantity", accessor: "quantity", align: "center" },
            { Header: "Action", accessor: "action", align: "center" }
        ],
        inventoryHeaders: [
            { Header: "Item", accessor: "item", align: "center" },
            { Header: "Code", accessor: "code", align: "center" },
            { Header: "Available", accessor: "available", align: "center" },
            { Header: "Required", accessor: "required", align: "center" },
            { Header: "Deficit", accessor: "deficit", align: "center" },
            { Header: "To Stock", accessor: "tostock", align: "center" },
            { Header: "To Order", accessor: "toorder", align: "center" }
        ],
        sampleRows: [
            {
                name: "Apple Mix 1",
                code: "AM1",
                product: "Apple Juice",
                ingredients: [1, 2, 3, 4],
                description: "Apple sio mix variant 3"
            },
            {
                name: "Apple Mix 1",
                code: "AM1",
                product: "Apple Juice",
                ingredients: [1, 2, 3, 4],
                description: "Apple sio mix variant 3"
            },
            {
                name: "Apple Mix 1",
                code: "AM1",
                product: "Apple Juice",
                ingredients: [1, 2, 3, 4],
                description: "Apple sio mix variant 3"
            },
            {
                name: "Apple Mix 1",
                code: "AM1",
                product: "Apple Juice",
                ingredients: [1, 2, 3, 4],
                description: "Apple sio mix variant 3"
            }
        ],
    };
}
