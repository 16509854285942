import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Card, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';

import MDTypography from 'components/MDTypography';

import Transaction from "./components/Transaction";
import MDBox from 'components/MDBox';
import { useLoading } from "context/loading.context";
import moment from "moment";
import { useMaterialUIController } from 'context';
import { Line } from 'react-chartjs-2';
import { FILE_API_ENDPOINT, getStockTransactions, getGraphyData } from 'network/service';
import ReportsChart from "./components/ReportLineChart/index"
import { Typography } from '@mui/material';

function ProductDashboard() {


    const [controller] = useMaterialUIController();
    const { sidenavColor } = controller;
    const [filter, setFilter] = useState(null);
    const [selProduct, setSelProduct] = useState(null);
    const location = useLocation();
    const [inventory, setInventory] = useState([]);
    const [stock, setStock] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [page, setPage] = useState(1);
    const inventoryId = "6606b7c7faa2f68a950aaad1";
    const [hasMore, setHasMore] = useState(true)
    const limit = 5;

    const [performTrend, setPerformTrend] = useState({ from: moment().startOf('week').format("YYYY-MM-DD"), to: moment().endOf('week').format("YYYY-MM-DD") });
    const [performanceData, setPerformanceData] = useState({
        labels: [],
        datasets: []
    });

    useEffect(() => {

        console.log("State in location:", location.state);
    }, [location.state]);


    const fetchStockTransactions = useCallback((page) => {
        if (!hasMore) return;

        const queryParam = `Inventory=${location.state.data._id}&_page=${page}&_limit=${limit}`;
        setIsFetching(true);

        getStockTransactions(queryParam)
            .then((resp) => {
                if (resp.data.length === 0) {
                    setHasMore(false);
                } else {
                    setStock((prevStock) => [...prevStock, ...resp.data]);
                }
                setIsFetching(false);
            })
            .catch((err) => {
                console.log(err);
                setIsFetching(false);
            });
    }, [inventoryId, limit, hasMore]);

    console.log(stock);


    useEffect(() => {
        fetchStockTransactions(page);
    }, [fetchStockTransactions, page]);
    const handleScroll = () => {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    //Graphy Api

    useEffect(() => {
        const fetchGraphData = async () => {
            try {
                const graphparam = `Inventory=${location.state.data._id}&TransactionDate_gte=${performTrend.from}&&TransactionDate_lte=${performTrend.to} 23:59:59`;
                const resp = await getGraphyData(graphparam);
                const graphData = resp.data;

                let labels = [];
                let data_out = [];
                let data_in = [];
                let type = (Object.keys(graphData).length > 7) ? 'M' : 'W';

                for (let dt in graphData) {
                    if (type === 'W') {
                        labels.push(moment(dt).format('dddd').charAt(0));
                    } else if (type === 'M') {
                        labels.push(moment(dt).format('D'));
                    }
                    data_out.push(graphData[dt].out);
                    data_in.push(graphData[dt].in);
                }

                setPerformanceData({
                    labels: labels,
                    datasets: [
                        { label: "Out", data: data_out },
                        { label: "In", data: data_in }
                    ]
                });
            } catch (err) {
                console.log(err);
            }
        };


        fetchGraphData();
    }, [location.state.data._id, performTrend]);

    const PerformaceTrendDateChange = (range) => {
        console.log(range);
        setPerformTrend(range);
    };


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={1} pb={3}>
                <Grid sx={{ display: "flex", alignContent: "center", justifyContent: "space-around" }}>
                    <Grid item xs={6} xl={2} md={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                        <img
                            src={(location.state.data.Product.Image) ? `${FILE_API_ENDPOINT}${location.state.data.Product.Image}` : require("assets/images/gallery.png")}
                            alt="Avatar"
                            style={{ maxHeight: 120 }}
                        />

                    </Grid>
                    <Grid item xl={12} xs={12}>
                        <Card>
                            <Grid container item xs={12} md={10} xl={12} sx={{ textAlign: "center" }}>
                                <Grid item xl={3} md={4} xs={6}>
                                    <MDTypography variant="h6" m={2} fontSize="12px" fontWeight="light">
                                        Product
                                    </MDTypography>
                                    <MDTypography variant="h5" color={sidenavColor} fontWeight="medium" fontSize="16px" mt={-2}>
                                        {location.state.data.Product.ProductName}
                                    </MDTypography>
                                </Grid>
                                <Grid item xl={3} md={4} xs={6}>
                                    <MDTypography variant="h6" m={2} fontSize="12px" fontWeight="light">
                                        Codes
                                    </MDTypography>
                                    <MDTypography variant="h5" color={sidenavColor} fontWeight="medium" fontSize="16px" mt={-2}>
                                        {location.state.data.Product.ProductCode}
                                    </MDTypography>
                                </Grid>
                                <Grid item xl={3} md={4} xs={6}>
                                    <MDTypography variant="h6" m={2} fontSize="12px" fontWeight="light">
                                        Category
                                    </MDTypography>
                                    <MDTypography variant="h5" color={sidenavColor} fontWeight="medium" fontSize="16px" mt={-2}>
                                        {location.state.data.Product.ProductCategoryID.ProductCategoryName}
                                    </MDTypography>
                                </Grid>
                                <Grid item xl={3} md={4} xs={6}>
                                    <MDTypography variant="h6" m={2} fontSize="12px" fontWeight="light">is Collection</MDTypography>
                                    <MDTypography variant="h5" color={sidenavColor} fontWeight="medium" fontSize="16px" mt={-2}>  {location.state.data.isCollection ? 'Yes' : 'No'}</MDTypography>
                                </Grid>
                                <Grid item xl={3} md={4} xs={6}>
                                    <MDTypography variant="h6" m={2} fontSize="12px" fontWeight="light">Min Qty</MDTypography>
                                    <MDTypography variant="h5" color={sidenavColor} fontWeight="medium" fontSize="16px" mt={-2}>   {location.state.data.MinQuantity}</MDTypography>
                                </Grid>
                                <Grid item xl={3} md={4} xs={6}>
                                    <MDTypography variant="h6" m={2} fontSize="12px" fontWeight="light">Max Qty</MDTypography>
                                    <MDTypography variant="h5" color={sidenavColor} fontWeight="medium" fontSize="16px" mt={-2}>  {location.state.data.MaxQuantity}</MDTypography>
                                </Grid>
                                <Grid item xl={3} md={4} xs={6}>
                                    <MDTypography variant="h6" m={2} fontSize="12px" fontWeight="light">To Stack</MDTypography>
                                    <MDTypography variant="h5" color={sidenavColor} fontWeight="medium" fontSize="16px" mt={-2}>  {location.state.data.ToStockQuantity}</MDTypography>
                                </Grid>
                                <Grid item xl={3} md={4} xs={6}>
                                    <MDTypography variant="h6" m={2} fontSize="12px" fontWeight="light">UOM</MDTypography>
                                    <MDTypography variant="h5" color={sidenavColor} m={2} mt={-2} fontWeight="medium" fontSize="16px"> {location.state.data.Product.UnitOfMeasure.name}</MDTypography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
                <MDBox mt={4}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} xl={7}>
                            <MDBox mt={0}>
                                <ReportsChart
                                    color={"dark"}
                                    title="Product Graph"
                                    description="updated 4 min ago"
                                    chart={performanceData}
                                    rangeChange={PerformaceTrendDateChange}

                                />
                            </MDBox>

                        </Grid>

                        <Grid item xs={12} md={6} xl={5} mt={-1}>
                            <Card sx={{ height: "25.5rem", overflowY: "auto" }} onScroll={!isFetching ? handleScroll : null}>
                                <MDBox pt={2} pb={1} px={2}>
                                    <MDBox mb={1}>
                                        <MDTypography variant="caption" color="success" fontWeight="bold" textTransform="uppercase">
                                            Transaction History
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox
                                        component="ul"
                                        display="flex"
                                        flexDirection="column"
                                        p={0}
                                        m={0}
                                        sx={{ listStyle: "none" }}
                                    >
                                        {stock.length > 0 ? (
                                            stock.map((val, index) => (
                                                <Grid item xs={12} key={index}>
                                                    <Transaction
                                                        color={val.Category.IsInward ? "success" : "error"}
                                                        description={
                                                            <>
                                                                {"BillNo:"}
                                                                {val.Bill?.BillNumber ? (
                                                                    val.Bill.BillNumber
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </>
                                                        }
                                                        value={`${val.Quantity} ${(location.state.data.Product.ProductCategoryID.ProductCategoryName === "Finished Good") ? "Unit(s)" : location.state.data.Product.UnitOfMeasure.name}`}
                                                        name={moment(val.TransactionDate).format("DD-MM-YYYY [at] hh:mm A")}
                                                        icon={val.Category.IsInward ? "expand_less" : "expand_more"}
                                                    />
                                                </Grid>
                                            ))
                                        ) : (
                                            <Grid item xs={12}>
                                                <MDTypography variant="h6" color="error" textAlign="center">
                                                    No transaction available
                                                </MDTypography>
                                            </Grid>
                                        )}
                                        {isFetching && (
                                            <MDTypography variant="h6" color="text" display="block" textAlign="center">
                                                Loading...
                                            </MDTypography>
                                        )}
                                    </MDBox>
                                </MDBox>
                            </Card>

                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
            <Footer />
        </DashboardLayout>

    );
}

export default ProductDashboard;
