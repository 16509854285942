import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Icon from '@mui/material/Icon';

import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import DataTable from 'examples/Tables/DataTable';
import { useLoading } from 'context/loading.context';
import { useMaterialUIController } from 'context';
import { getEquipements, getSections, getManufacturingPlants } from 'network/service';
import EquipmentTableColoumns from './data'

import AddEquipmentPopUp from './compoents/AddEquipmentPopUp';
import { Autocomplete } from '@mui/material';
import MDInput from 'components/MDInput';

export default function Equipments() {

  const { showLoader, hideLoader } = useLoading();
  const [equipmentPopUp, setEquipmentPopUp] = useState({ open: false, details: null });
  const [controller, dispatch] = useMaterialUIController();
  const { sidenavColor } = controller;
  const navigate = useNavigate();
  const [equipment, setEquipment] = useState([]);
  const [code, setCode] = useState();
  const [plant, setPlant] = useState(null);
  const [section, setSection] = useState([]);
  const [selSection, setSelSection] = useState(null);
  const [manuPlants, setManuPlants] = useState([]);

  const { EquipmentHeader, sampleRows, formEquipmentRows } = EquipmentTableColoumns();


  const fetchEquipment = () => {
    let param = "";
    if (selSection && plant) {
      param += `Section=${selSection._id}`;
    } else if (plant) {
      param += `Section.ManufacturingPlant=${plant._id}`;
    }
    showLoader();
    getEquipements(param)
      .then((resp) => {
        setEquipment(resp.data);
        hideLoader();
      })
      .catch((err) => {
        hideLoader();
        console.log(err);
      });
  };

  useEffect(() => {
    fetchEquipment();
  }, [setEquipment, plant, selSection]);

  useEffect(() => {
    setSelSection(null);
    if (plant) {
      getSections(`ManufacturingPlant=${plant._id}`).then((resp) => {
        setSection(resp.data);
      }).catch((err) => {
        console.log(err);
      })
    }
    else {
      setSection([]);
    }
  }, [setSection, plant]);

  useEffect(() => {
    getManufacturingPlants().then((resp) => {
      setManuPlants(resp.data);
    }).catch((err) => {
      console.log(err);
    })
  }, [setManuPlants]);


  // useEffect(() => {
  //   let param = "";

  //   if (selSection) {
  //     if (param.length > 0) param += `&`
  //     param += `Sections=${selSection._id}`
  //   }
  //   console.log("Framed param", param);
  //   showLoader();
  //   getEquipements(param).then((resp) => {
  //     setEquipment(resp.data);
  //     hideLoader();
  //   }).catch((err) => {
  //     hideLoader();
  //     console.log(err);
  //   })
  // }, [setEquipment, selSection])

  const exportToExcel = (data, filename) => {
    try {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, `${filename}.xlsx`);
    } catch (error) {
      console.error('Error exporting to Excel:', error);
    }
  };




  return (


    <DashboardLayout>
      <DashboardNavbar />
      {equipmentPopUp.open && <AddEquipmentPopUp isopen={equipmentPopUp.open} details={equipmentPopUp.details} handleclose={() => { setEquipmentPopUp({ open: false, details: null }) }} handlesuccess={() => { setEquipmentPopUp({ open: false, details: null, }), fetchEquipment() }} />}
      <MDBox pt={4} px={2} py={3}>
        <MDBox pt={6} pb={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor={sidenavColor}
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Equipments Table
                  </MDTypography>
                </MDBox>
                <Grid container spacing={2} alignItems="center" p={3}>
                  <Grid item xs={12} md={4} xl={4}>
                    <Autocomplete
                      label={"Manufacturing Plant"}
                      sx={{ flexGrow: 1 }}
                      options={manuPlants}
                      getOptionLabel={(option) => option.PlantName || ''}
                      renderInput={(params) => <MDInput {...params} label={"Manufacturing Plant"} fullWidth />}
                      onChange={(event, newValue) => {
                        setPlant(newValue);
                      }}
                      value={plant}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} xl={4}>
                    <Autocomplete
                      key="equipment-autocomplete"
                      label={"Section"}
                      sx={{ flexGrow: 1 }}
                      options={section}
                      getOptionLabel={(option) => option.SectionName || ''}
                      renderInput={(params) => <MDInput {...params} label={"Section"} fullWidth />}
                      onChange={(event, newValue) => {
                        setSelSection(newValue);
                      }}
                      value={selSection}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} xl={4}>
                    <Stack direction="row" spacing={2} justifyContent="flex-end">
                      
                        
                     
                      
                        {/* <MDButton
                          variant="gradient"
                          color="warning"
                          onClick={() => {
                            exportToExcel(formEquipmentRows(equipment), moment().unix());
                          }}
                        >
                          <Icon>download</Icon>&nbsp; Export
                        </MDButton> */}
                        <MDButton
                          variant="outlined"
                          color="success"
                          onClick={() => {
                            setEquipmentPopUp({ open: true, details: null });
                          }}
                        >
                          Add Equipments
                        </MDButton>
                    </Stack>
                  </Grid>
                </Grid>
                <MDBox sx={{ maxHeight: "50vh", overflow: 'auto' }}>
                  <DataTable
                    table={{
                      columns: EquipmentHeader,
                      rows: formEquipmentRows(equipment, setEquipmentPopUp)
                    }}
                    noEndBorder
                    canSearch
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
