import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Icon from '@mui/material/Icon';

import MDButton from 'components/MDButton';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import DataTable from 'examples/Tables/DataTable';
import { useLoading } from 'context/loading.context';
import { useMaterialUIController } from 'context';
import { getMaintenanceLogs } from 'network/service';


import AddMaintenanceLogPopUp from './compoents/AddMaintenanceLogPopUp';
import { Autocomplete } from '@mui/material';
import MDInput from 'components/MDInput';
import MaintenancelogsTable from './data'
import { getSections, getEquipements, getManufacturingPlant } from 'network/service';
export default function Logs() {

    const { showLoader, hideLoader } = useLoading();
    const [MaintenanceLogPopUp, setMaintenanceLogPopUp] = useState({ open: false, details: null });
    const [controller, dispatch] = useMaterialUIController();
    const { sidenavColor } = controller;
    const navigate = useNavigate();
    const [equipment, setEquipment] = useState([]);
    const [MaintanceLog, setMaintanceLog] = useState([]);
    const [manufplant, setManufplant] = useState([]);
    const [selManuPlant, setSelManuPlant] = useState(null);
    const [section, setSection] = useState([]);
    const [selEquipment, setSelEquipment] = useState(null);
    const [from, setFrom] = useState(moment().subtract(30, 'days').toDate());
    const [to, setTo] = useState(new Date());
    const [selSection, setSelSection] = useState(null);
    const { MaintenancelogsHeader, sampleRows, formMaintenancelogsRow } = MaintenancelogsTable();

    const fetchMaintanceLog = () => {
        let param = "";
        if (from) {
            param += `StartTime_gte=${moment(from).format("YYYY-MM-DD")}`
        }
        if (to) {
            if (param.length > 0) param += `&`
            param += `StartTime_lte=${moment(to).format("YYYY-MM-DD")} 23:59:59`
        }
        if (selEquipment) {
            if (param.length > 0) param += `&`
            param += `Equipment=${selEquipment._id}`
        }
        else if (selSection) {
            if (param.length > 0) param += `&`
            param += `Equipment.Section=${selSection._id}`
        }
        else if (selManuPlant) {
            if (param.length > 0) param += `&`
            param += `ManufacturingPlant=${selManuPlant._id}`
        }
        console.log("Framed param", param);
        showLoader();
        getMaintenanceLogs(param).then((resp) => {
            setMaintanceLog(resp.data);
            hideLoader();
        }).catch((err) => {
            hideLoader();
            console.log(err);
        })
    };

    useEffect(() => {
        fetchMaintanceLog();
    }, [setMaintanceLog, from, to, selManuPlant, selSection, selEquipment]);


    useEffect(() => {
        getManufacturingPlant().then((resp) => {
            setManufplant(resp.data);
        }).catch((err) => {
            console.log(err);
        })
    }, [setManufplant]);

    useEffect(() => {
        if (selManuPlant) {
            getSections(`ManufacturingPlant=${selManuPlant._id}`).then((resp) => {
                console.log(resp.data);
                setSection(resp.data);
            }).catch((err) => {
                console.log(err);
                setSection([]);
            })
            setSelSection(null);
        }
        else {
            setSection([]);
            setSelSection(null);
        }
    }, [setSelSection, selManuPlant]);


    useEffect(() => {
        if (selSection) {
            getEquipements(`Section=${selSection._id}`).then((resp) => {
                setEquipment(resp.data);
            }).catch((err) => {
                console.log(err);
                setEquipment([]);
            });
            setSelEquipment(null);
        }
        else {
            setEquipment([]);
            setSelEquipment(null);
        }
    }, [setEquipment, setSelEquipment, selSection]);

    // useEffect(() => {
    //     let param = "";
    //     // if (from) {
    //     //     param += `OrderDate_gte=${moment(from).format("YYYY-MM-DD")}`
    //     // }
    //     // if (to) {
    //     //     if (param.length > 0) param += `&`
    //     //     param += `OrderDate_lte=${moment(to).format("YYYY-MM-DD")}`
    //     // }
    //     if (selManuPlant) {
    //         if (param.length > 0) param += `&`
    //         param += `Section=${selManuPlant._id}`
    //     }
    //     if (selSection) {
    //         if (param.length > 0) param += `&`
    //         param += `Section=${selSection._id}`
    //     }
    //     if (selEquipment) {
    //         if (param.length > 0) param += `&`
    //         param += `Equipment=${selEquipment._id}`
    //     }
    //     console.log("Framed param", param);
    //     showLoader();
    //     getMaintenanceLogs(param).then((resp) => {
    //         setMaintanceLog(resp.data);
    //         hideLoader();
    //     }).catch((err) => {
    //         hideLoader();
    //         console.log(err);
    //     })
    // }, [setMaintanceLog, from, to, selEquipment, selSection])


    const exportToExcel = (data, filename) => {
        try {
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(data);
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, `${filename}.xlsx`);
        } catch (error) {
            console.error('Error exporting to Excel:', error);
        }
    };

    return (

        <DashboardLayout>
            <DashboardNavbar />
            {MaintenanceLogPopUp.open && <AddMaintenanceLogPopUp isopen={MaintenanceLogPopUp.open} details={MaintenanceLogPopUp.details} handleclose={() => { setMaintenanceLogPopUp({ open: false, details: null }) }} handlesuccess={() => { setMaintenanceLogPopUp({ open: false, details: null, }), fetchMaintanceLog() }} />}
            <MDBox pt={4} px={2} py={3}>
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor={sidenavColor}
                                    borderRadius="lg"
                                    coloredShadow="info"
                                >
                                    <MDTypography variant="h6" color="white">
                                        Maintenancelogs Table
                                    </MDTypography>
                                </MDBox>
                                <Stack
                                    direction="row-reverse"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <MDBox pt={3} pr={3}>
                                        <MDButton
                                            variant="outlined"
                                            color="success"
                                            onClick={() => {
                                                setMaintenanceLogPopUp({ open: true, details: null });
                                            }}
                                        >
                                            Create
                                        </MDButton>
                                    </MDBox>
                                    <MDBox pt={3}>
                                        {/* <MDButton
                                            variant="gradient"
                                            color="warning"
                                            onClick={() => {
                                                exportToExcel(formMaintenancelogsRow(MaintanceLog), moment().unix());
                                            }}
                                        >
                                            <Icon>download</Icon>&nbsp; Export
                                        </MDButton> */}
                                    </MDBox>
                                </Stack>
                                <MDBox pt={3} px={2} py={3}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={4} xl={2} >
                                            <Autocomplete
                                                key="section-autocomplete"
                                                label={"Manufacturing Plant"}
                                                sx={{ flexGrow: 1 }}
                                                options={manufplant}
                                                getOptionLabel={(option) => option.PlantName || ''}
                                                renderInput={(params) => <MDInput {...params} label={"Manufacturing Plant"} fullWidth />}
                                                onChange={(event, newValue) => {
                                                    setSelManuPlant(newValue);
                                                }}
                                                value={selManuPlant}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4} xl={3} >
                                            <Autocomplete
                                                key="equipment-autocomplete"
                                                label={"Section"}
                                                sx={{ flexGrow: 1 }}
                                                options={section}
                                                getOptionLabel={(option) => option.SectionName || ''}
                                                renderInput={(params) => <MDInput {...params} label={"Section"} fullWidth />}
                                                onChange={(event, newValue) => {
                                                    setSelSection(newValue);
                                                }}
                                                value={selSection}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4} xl={3}>
                                            <Autocomplete
                                                key="equipment-autocomplete"
                                                label={"Equipments"}
                                                sx={{ flexGrow: 1 }}
                                                options={equipment}
                                                getOptionLabel={(option) => option.EquipmentName || ''}
                                                renderInput={(params) => <MDInput {...params} label={"Equipments"} fullWidth />}
                                                onChange={(event, newValue) => {
                                                    setSelEquipment(newValue);
                                                }}
                                                value={selEquipment}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4} xl={2} >
                                            <MDInput fullWidth type="date" label={"From"} value={moment(from).format("YYYY-MM-DD")} onChange={(event) => {
                                                setFrom(event.target.valueAsDate);
                                            }}></MDInput>
                                        </Grid>
                                        <Grid item xs={12} md={4} xl={2} >
                                            <MDInput fullWidth type="date" label={"To"} value={moment(to).format("YYYY-MM-DD")} onChange={(event) => {
                                                setTo(event.target.valueAsDate);
                                            }}></MDInput>
                                        </Grid>
                                    </Grid>
                                </MDBox>

                                <MDBox sx={{ maxHeight: "50vh", overflow: 'auto' }}>
                                    <DataTable
                                        table={{
                                            columns: MaintenancelogsHeader,
                                            rows: formMaintenancelogsRow(MaintanceLog, setMaintenanceLogPopUp)
                                        }}
                                        noEndBorder
                                        canSearch
                                    />
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
            <Footer />
        </DashboardLayout>
    )

}