import React, { createContext, useContext, useState } from 'react';
import Loader from "components/Loader";

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);

    const showLoader = () => setLoading(true);
    const hideLoader = () => setLoading(false);

    return (
        <LoadingContext.Provider value={{ loading, showLoader, hideLoader }}>
            {loading && <Loader />}
            <div style={{ opacity: (loading) ? 0.5 : 1 }}>
                {children}
            </div>
        </LoadingContext.Provider>
    );
};

export const useLoading = () => useContext(LoadingContext);