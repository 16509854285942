// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from "@mui/material/Autocomplete";
import AddProductPopup from "../AddProductPopup";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useEffect, useState, useRef } from "react";
import { Divider, Icon, Stack } from "@mui/material";
import MDInput from "components/MDInput";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import { getAllProducts, FILE_API_ENDPOINT } from "network/service";
import { addInventoryProduct } from "network/service";
import { useLoading } from "context/loading.context";
import { useNotification } from "context/notification.context";
import { useNavigate } from 'react-router-dom';
import { useMaterialUIController } from "context";
import { FormHelperText } from "@mui/material";


function AddProduct() {
    const [controller, dispatch] = useMaterialUIController();
    const { sidenavColor } = controller;
    const navigate = useNavigate();
    const { showNotification } = useNotification();
    const { showLoader, hideLoader } = useLoading();
    const productRef = useRef(null);
    const [productPopup, setProductPopup] = useState({ open: false, details: null });
    const [products, setProducts] = useState([]);
    const [selProduct, setSelProduct] = useState(null);


    const [isCollection, setIsCollection] = useState(false);

    const [newField, setNewField] = useState({ minQty: "", maxQty: "", toStock: "", unitsInColl: "", qtyOfUnit: "" });
    const [errors, setErrors] = useState({});
    const goBack = () => {
        navigate(-1);
    };

    const fetchProducts = () => {
        getAllProducts(`__order=ProductName_1`).then((resp) => {
            console.log(resp.data);
            setProducts(resp.data);
        }).catch((err) => {
            console.log(err);
        })
    }


    useEffect(() => {
        fetchProducts();
    }, [setProducts])

    const submit = () => {
        const newErrors = {};

        if (!selProduct) {
            productRef.current.focus();
            return;
        }

        if (parseFloat(newField.minQty) <= 0 || newField.minQty === "") {
            newErrors.minQty = true;
        }

        if (parseFloat(newField.maxQty) <= 0 || newField.maxQty === "") {
            newErrors.maxQty = true;
        }

        if (parseFloat(newField.toStock) <= 0 || newField.toStock === "") {
            newErrors.toStock = true;
        }

        if (isCollection) {
            if (parseFloat(newField.unitsInColl) <= 0 || newField.unitsInColl === "") {
                newErrors.unitsInColl = true;
            }

            if (parseFloat(newField.qtyOfUnit) <= 0 || newField.qtyOfUnit === "") {
                newErrors.qtyOfUnit = true;
            }
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        // If all validations pass, submit the form
        showLoader();
        addInventoryProduct({
            Product: selProduct._id,
            QuantityOnHand: 0,
            MinQuantity: parseFloat(newField.minQty),
            MaxQuantity: parseFloat(newField.maxQty),
            ToStockQuantity: parseFloat(newField.toStock),
            isCollection: isCollection,
            UnitsInCollection: parseFloat(newField.unitsInColl),
            QtyOfUnit: parseFloat(newField.qtyOfUnit),
        }).then((resp) => {
            hideLoader();
            showNotification("success", "Success", "Added the Inventory Item");
            goBack();
        }).catch((err) => {
            hideLoader();
            showNotification("error", "Failed", "Unable to add the Inventory item");
        });
    };


    return (
        <DashboardLayout>
            <DashboardNavbar />
            {productPopup.open && <AddProductPopup isopen={productPopup.open} details={productPopup.details} handleclose={() => { setProductPopup({ open: false, details: null }) }} handlesuccess={() => { fetchProducts(); setProductPopup({ open: false, details: null }) }} />}
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor={sidenavColor}
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Add Inventory Product
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3} px={2} py={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} lg={8} xl={9}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6} xl={6}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} xl={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} md={6} xl={6}>
                                                                <Grid container sx={{ alignItems: 'center' }}>
                                                                    <Autocomplete
                                                                        disableClearable
                                                                        label={"Product"}
                                                                        sx={{ flexGrow: 1 }}
                                                                        options={products}
                                                                        getOptionLabel={(option) => option.ProductName || ''}
                                                                        renderInput={(params) => <MDInput inputRef={productRef} {...params} label={"Product"} fullWidth />}
                                                                        onChange={(event, newValue) => {
                                                                            setSelProduct(newValue);
                                                                        }}
                                                                        value={selProduct}
                                                                    />
                                                                    <MDButton variant="outlined" color="success" iconOnly sx={{ marginLeft: 1 }}
                                                                        onClick={() => {
                                                                            setProductPopup({ open: true, details: null });
                                                                        }}>
                                                                        <Icon>add</Icon>
                                                                    </MDButton>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12} md={6} xl={6}>
                                                                <MDInput disabled fullWidth label="Code" value={selProduct ? selProduct.ProductCode : ""}></MDInput>
                                                            </Grid>
                                                            <Grid item xs={12} md={6} xl={6}>
                                                                <MDInput fullWidth label="UOM" disabled value={selProduct ? selProduct.UnitOfMeasure.name : ""}></MDInput>
                                                            </Grid>
                                                            <Grid item xs={12} md={6} xl={6}>
                                                                <MDInput disabled fullWidth label="Category" value={selProduct ? selProduct.ProductCategoryID.ProductCategoryName : ""}></MDInput>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} xl={12}>
                                                        <MDInput disabled label="Description" multiline rows={1} fullWidth value={selProduct ? selProduct.Description : ""}></MDInput>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} md={6} xl={6}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} xl={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} md={6} xl={6}>
                                                                <MDInput
                                                                    label="Min Qty Threshold"
                                                                    type="number"
                                                                    value={newField.minQty}
                                                                    error={errors.minQty}



                                                                    fullWidth
                                                                    onChange={(e) => {
                                                                        const value = parseFloat(e.target.value);
                                                                        if (!isNaN(value) && value > 0 && e.target.value !== "") {
                                                                            setNewField({ ...newField, minQty: value });
                                                                            setErrors({ ...errors, minQty: false });
                                                                        } else {
                                                                            setNewField({ ...newField, minQty: value });
                                                                            setErrors({ ...errors, minQty: true });
                                                                        }
                                                                    }}




                                                                />
                                                            </Grid>

                                                            <Grid item xs={12} md={6} xl={6}>
                                                                <MDInput fullWidth label="Max Stock Capacity" type="number" value={newField.maxQty} error={errors.maxQty}


                                                                    onChange={(e) => {
                                                                        const value = parseFloat(e.target.value);
                                                                        if (!isNaN(value) && value > 0 && e.target.value !== "") {
                                                                            setNewField({ ...newField, maxQty: value });
                                                                            setErrors({ ...errors, maxQty: false });
                                                                        } else {
                                                                            setNewField({ ...newField, maxQty: value });
                                                                            setErrors({ ...errors, maxQty: true });
                                                                        }
                                                                    }}

                                                                ></MDInput>
                                                            </Grid>

                                                            <Grid item xs={12} md={6} xl={6}>
                                                                <MDInput
                                                                    fullWidth
                                                                    label="Qty To Stock"
                                                                    type="number"
                                                                    value={newField.toStock}
                                                                    error={errors.toStock}

                                                                    onChange={(e) => {
                                                                        const value = parseFloat(e.target.value, 10);
                                                                        const isValid = !isNaN(value) && value > 0 && e.target.value !== "";

                                                                        setNewField({ ...newField, toStock: value });
                                                                        setErrors({ ...errors, toStock: !isValid });
                                                                    }}
                                                                />

                                                            </Grid>
                                                            <Grid item xs={12} md={6} xl={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                                                                <FormGroup>
                                                                    <FormControlLabel control={<Checkbox defaultChecked={isCollection} onChange={(e) => { setIsCollection(e.target.checked) }} />} label="Is Collection" />
                                                                </FormGroup>
                                                            </Grid>
                                                            <Grid item xs={12} md={6} xl={6}>
                                                                <MDInput
                                                                    fullWidth
                                                                    label="Units In Collection"
                                                                    type="number"
                                                                    disabled={!isCollection}
                                                                    error={errors.unitsInColl}
                                                                    value={newField.unitsInColl}

                                                                    onChange={(e) => {
                                                                        const value = parseFloat(e.target.value, 10);
                                                                        const isValid = !isNaN(value) && value > 0;

                                                                        setNewField({ ...newField, unitsInColl: value });
                                                                        setErrors({ ...errors, unitsInColl: !isValid });
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} md={6} xl={6}>
                                                                <MDInput
                                                                    fullWidth
                                                                    label="Qty of a Unit"
                                                                    type="number"
                                                                    disabled={!isCollection}
                                                                    error={errors.qtyOfUnit}
                                                                    value={newField.qtyOfUnit}
                                                                   

                                                                    onChange={(e) => {
                                                                        const value = parseFloat(e.target.value, 10);
                                                                        const isValid = !isNaN(value) && value > 0 && e.target.value !== "";

                                                                        setNewField({ ...newField, qtyOfUnit: value });
                                                                        setErrors({ ...errors, qtyOfUnit: !isValid });
                                                                    }}
                                                                />

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>


                                                </Grid>

                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    <Grid item xs={12} xl={3} lg={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={(selProduct && selProduct.Image) ? `${FILE_API_ENDPOINT}${selProduct.Image}` : require("assets/images/gallery.png")} alt="Avatar" width={200} />
                                    </Grid>
                                </Grid>

                                <Stack
                                    direction="row-reverse"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={2}

                                >

                                    <MDBox pt={3} pr={3}>
                                        <MDButton variant="gradient" color="success"
                                            onClick={() => { submit() }}>
                                            <Icon>save</Icon>&nbsp;
                                            Save
                                        </MDButton>
                                    </MDBox>
                                    <MDBox pt={3}>
                                        <MDButton variant="gradient" color="error" onClick={() => { goBack(); }}>
                                            <Icon>close</Icon>&nbsp;
                                            Cancel
                                        </MDButton>
                                    </MDBox>
                                    <MDBox>
                                        <FormHelperText sx={{ color: 'red', alignSelf: 'center', fontWeight: 600, textAlign: "right", marginRight: "px", fontSize: "12px", marginTop: 4 }}>
                                            {errors.minQty || errors.maxQty || errors.toStock || errors.unitsInColl || errors.qtyOfUnit ? 'Incorrect/Invalid Input' : ''}
                                        </FormHelperText>
                                    </MDBox>
                                </Stack>

                            </MDBox>

                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default AddProduct;
