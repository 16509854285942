/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

import { Icon } from "@mui/material";
import MDButton from "components/MDButton";
import moment from "moment";

export default function TableData() {

    const formBillRows = (data, setDetailsPopup) => {
        let result = [];
        for (let dt of data) {
            result.push({
                number: dt.BillNumber,
                date: moment(dt.BillDate).format("DD-MM-YYYY"),
                total: dt.TotalAmount,
                supplier: dt.SupplierID.SupplierName,
                supp_code: dt.SupplierID.SupplierCode,
                action: <MDButton variant="outlined" color="warning" iconOnly onClick={() => {
                    setDetailsPopup({ open: true, details: dt });
                }}>
                    <Icon>visibility</Icon>
                </MDButton>
            });
        }
        return result
    }

    return {
        formBillRows,
        billHeaders: [
            { Header: "Date", accessor: "date", align: "center" },
            { Header: "Bill No.", accessor: "number", align: "center" },
            { Header: "Supplier", accessor: "supplier", align: "center" },
            { Header: "Supplier Code", accessor: "supp_code", align: "center" },
            { Header: "Total", accessor: "total", align: "center" },
            { Header: "Action", accessor: "action", align: "center" }
        ],
        sampleRows: [
            {
                number: "251",
                date: "25-03-2024",
                items: 5,
                total: 3000,
                supplier: "Nirals Enterprises",
                action: "View"
            }
        ],
    };
}
