// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from '@mui/material/Stack';
import Icon from "@mui/material/Icon";

import * as XLSX from 'xlsx';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import MDButton from "components/MDButton";
import { useLoading } from "context/loading.context";
import { useMaterialUIController, getColor } from "context";

import moment from "moment";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import { Menu } from "@mui/icons-material";
import Skeleton from '@mui/material/Skeleton';
import MenuItem from "@mui/material/MenuItem";
import { getLiveLots, getUpcomingLots } from "network/service";
import { startLot, stopLot } from "network/service";
import { useNotification } from "context/notification.context";
import UnitFlowChart from "./components/UnitFlowChart";

import colors from "assets/theme/base/colors";
import dark_colors from "assets/theme-dark/base/colors";

import GaugeChart from 'react-gauge-chart';
import GaugeComponent from 'react-gauge-component'
import LotMainDetailsCard from "./components/LotMainDetails";
import SectionDetailsCard from "./components/SectionCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

const dummy_raw_materials = [{
    material: "Sugar",
    required: 1000,
    taken: 998,
    uom: "Kg"
}, {
    material: "Sweetener",
    required: 100,
    taken: 102,
    uom: "Kg"
},
{
    material: "Pre Forms",
    required: 2500,
    taken: 2700,
    uom: "No."
},
{
    material: "Caps",
    required: 2500,
    taken: 2600,
    uom: "No."
}];

let dummy_sections = [
    {
        name: "Bottle Blower Section",
        runtime: "120",
        downtime: "20",
        availability: "98",
        performace: "89",
        quality: "99",
        oee: "75"
    },
    {
        name: "Cleaning and Filling",
        runtime: "120",
        downtime: "20",
        availability: "98",
        performace: "89",
        quality: "99",
        oee: "75"
    },
    {
        name: "Cooling Section",
        runtime: "120",
        downtime: "20",
        availability: "98",
        performace: "89",
        quality: "99",
        oee: "75"
    },
    {
        name: "Labelling Section",
        runtime: "120",
        downtime: "20",
        availability: "98",
        performace: "89",
        quality: "99",
        oee: "75"
    },
    {
        name: "Packing Section",
        runtime: "120",
        downtime: "20",
        availability: "98",
        performace: "89",
        quality: "99",
        oee: "75"
    }
];



export default function ProductionLotDetails() {
    const { showNotification } = useNotification();
    const { showLoader, hideLoader } = useLoading();
    const [controller, dispatch] = useMaterialUIController();
    const { sidenavColor, darkMode } = controller;
    const [lotPopup, setLotPopup] = useState({ open: false, details: null });
    const [materialPopup, setMaterialPopup] = useState({ open: false, details: null });
    const navigate = useNavigate();

    const [livelots, setLiveLots] = useState({ loading: true, data: [] });
    const [upcominglots, setUpcomingLots] = useState({ loading: true, data: [] });


    const [menu, setMenu] = useState(null);

    const [liveTrigger, setLiveTrigger] = useState(false);
    const [upcomingTrigger, setUpcomingTrigger] = useState(false);

    const openMenu = ({ currentTarget }) => setMenu(currentTarget);
    const closeMenu = () => setMenu(null);

    const dropdownMenu = (
        <Menu
            anchorEl={menu}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(menu)}
            onClose={closeMenu}
        >
            <MenuItem onClick={closeMenu}>Action</MenuItem>
            <MenuItem onClick={closeMenu}>Another action</MenuItem>
            <MenuItem onClick={closeMenu}>Something else here</MenuItem>
        </Menu>
    );

    const TakeRawMaterial = (details) => {
        setMaterialPopup({ open: true, details: details });
    }

    useEffect(() => {
        getUpcomingLots().then((resp) => {
            setUpcomingLots({ loading: false, data: resp.data });
            console.log(resp.data);
        }).catch((err) => {
            setUpcomingLots({ loading: false, data: [] });
            console.log(err);
        })
    }, [setUpcomingLots, liveTrigger]);

    useEffect(() => {
        // getColor(dispatch).then((data) => {
        //     console.log("Colors data context", data);
        // })
        let temp = getColor(darkMode, "info");
        console.log("Getting the colors:", temp);
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor={sidenavColor}
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Lot Details
                                </MDTypography>
                            </MDBox>

                            <MDBox pt={2} px={2} py={3}>
                                <Grid container spacing={2} mt={4}>
                                    <Grid item xl={6} md={12} xs={12}>
                                        <LotMainDetailsCard icon={"more"} />
                                    </Grid>
                                    <Grid item xl={3} md={6} xs={12} sx={{ textAlign: 'center' }}>
                                        <GaugeChart id="gauge-chart5"
                                            animate={false}
                                            nrOfLevels={420}
                                            arcsLength={[0.6, 0.3, 0.1]}
                                            needleColor={getColor(darkMode, "grey", 500)}
                                            needleBaseColor={getColor(darkMode, "grey", 500)}
                                            textColor={getColor(darkMode, "text", "focus")}
                                            textComponent={() => { return <MDTypography>Test</MDTypography> }}
                                            colors={[getColor(darkMode, "error", "focus"), getColor(darkMode, "info", "focus"), getColor(darkMode, "success", "focus")]}
                                            arcPadding={0.02}
                                            customNeedleComponent={<>ABC</>}
                                        />
                                        <MDTypography>Productivity</MDTypography>
                                    </Grid>
                                    <Grid item xl={3} md={6} xs={12} sx={{ textAlign: 'center' }}>
                                        <GaugeChart id="gauge-chart5"
                                            animate={false}
                                            nrOfLevels={420}
                                            arcsLength={[0.6, 0.3, 0.1]}
                                            needleColor={getColor(darkMode, "grey", 500)}
                                            needleBaseColor={getColor(darkMode, "grey", 500)}
                                            textColor={getColor(darkMode, "text", "focus")}
                                            textComponent={() => { return <MDTypography>Test</MDTypography> }}
                                            colors={[getColor(darkMode, "error", "focus"), getColor(darkMode, "info", "focus"), getColor(darkMode, "success", "focus")]}
                                            arcPadding={0.02}
                                            customNeedleComponent={<>ABC</>}
                                        />
                                        <MDTypography>Efficiency</MDTypography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} mt={4}>
                                    <Grid item xl={6} md={12} xs={12}>
                                        <Card sx={{ boxShadow: 6, minHeight: "100%" }}>
                                            <Grid>
                                                <MDBox display="flex" justifyContent="space-between" pt={1} px={2} mb={2}>
                                                    <MDBox
                                                        variant="gradient"
                                                        bgColor={sidenavColor}
                                                        color={sidenavColor === "light" ? "dark" : "white"}
                                                        coloredShadow={sidenavColor}
                                                        borderRadius="xl"
                                                        display="flex"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        px={3}
                                                        // width="4rem"
                                                        // height="4rem"
                                                        mt={-3}
                                                    >
                                                        <MDTypography sx={{ color: "white" }} color={"white"}>{"Raw Materials"}</MDTypography>
                                                    </MDBox>
                                                </MDBox>
                                                <DataTable
                                                    table={{
                                                        columns: [
                                                            { Header: "Material", accessor: "material", align: "left", width: "40%" },
                                                            { Header: "Required", accessor: "required", align: "center", width: "20%" },
                                                            { Header: "Taken", accessor: "taken", align: "center", width: "20%" },
                                                            { Header: "UOM", accessor: "uom", align: "center", width: "20%" }
                                                        ],
                                                        rows: dummy_raw_materials
                                                    }}
                                                    showTotalEntries={false}
                                                    isSorted={false}
                                                    entriesPerPage={false}
                                                />
                                            </Grid>
                                        </Card>
                                    </Grid>
                                    <Grid item xl={6} md={12} xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xl={6} md={6} xs={6}>
                                                <DefaultInfoCard
                                                    color={sidenavColor}
                                                    icon="account_balance"
                                                    title="wastage"
                                                    // description="Belong Interactive"
                                                    value="2 %"
                                                />
                                            </Grid>
                                            <Grid item xl={6} md={6} xs={6}>
                                                <DefaultInfoCard
                                                    color={sidenavColor}
                                                    icon="account_balance"
                                                    title="bottles"
                                                    // description=""
                                                    value="2569"
                                                />
                                            </Grid>
                                            <Grid item xl={6} md={6} xs={6}>
                                                <DefaultInfoCard
                                                    color={sidenavColor}
                                                    icon="account_balance"
                                                    title="electricity"
                                                    // description="Belong Interactive"
                                                    value="800 KWh"
                                                />
                                            </Grid>
                                            <Grid item xl={6} md={6} xs={6}>
                                                <DefaultInfoCard
                                                    color={sidenavColor}
                                                    icon="account_balance"
                                                    title="water"
                                                    // description="Belong Interactive"
                                                    value="1500 Ltrs"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid container mt={5} alignItems={"center"} justifyContent={"center"}>
                                    <Grid item xs={12} xl={12} md={12}>
                                        {dummy_sections.map((data, ind) => <SectionDetailsCard
                                            name={data.name}
                                            runtime={data.runtime}
                                            downtime={data.downtime}
                                            availability={data.availability}
                                            performace={data.performace}
                                            quality={data.quality}
                                            oee={data.oee} />)}
                                    </Grid>
                                    {/* <Grid item xs={12} xl={12} md={12} sx={{ border: 1, borderColor: getColor(darkMode, sidenavColor), borderRadius: 3, minHeight: 400 }}>
                                        <UnitFlowChart color={(darkMode) ? dark_colors[sidenavColor].main : colors[sidenavColor].main} />
                                    </Grid> */}
                                </Grid>
                            </MDBox>

                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}
