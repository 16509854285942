import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { FormHelperText, Icon } from "@mui/material";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useNotification } from "context/notification.context";
import { addEquipments, updateEquipments, getEquipements } from "network/service";
import Autocomplete from "@mui/material/Autocomplete";
import { getEquipementTypes, getSections, getManufacturingPlant } from "network/service";
import moment from "moment";
export default function AddEquipmentPopUp(props) {
    const [newField, setNewField] = useState({ eqpcode: "", eqpname: "", manuplant: "", installationdate: moment().format("YYYY-MM-DD"), description: "", typename: "", section: "", typecode: "", selType: "" });
    const [errors, setErrors] = useState({});
    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState([]);
    const [section, setSection] = useState([]);
    const [manuPlant, setManuPlant] = useState([]);
    const [selType, setSelType] = useState(null)
    const [selSection, setSelSecction] = useState();
    const [selManuPlant, setSelManuPlant] = useState(null);
    const [instalDate, setInstalDate] = useState(new Date());


    const validate = () => {
        let isValid = true;
        let newErrors = {};


        if (newField.eqpcode === "") {
            newErrors.eqpcode = "Equipment code is required";
            isValid = false;
        }

        if (newField.eqpname === "") {
            newErrors.eqpname = "Equipment name is required";
            isValid = false;
        }

        if (!selManuPlant) {
            newErrors.manuplant = "Manufacturing plant is required";
            isValid = false;
        }



        if (!selSection) {
            newErrors.section = "Section is required";
            isValid = false;
        }

        if (!selType) {
            newErrors.typename = "Type is required";
            isValid = false;
        }
        if (newField.description === "") {
            newErrors.description = "Description is required";
            isValid = false;
        }

        if (newField.installationdate === "") {
            newErrors.installationdate = "Installation date is required";
            isValid = false;
        } else if (new Date(newField.installationdate) > new Date()) {
            newErrors.installationdate = "Installation date cannot be in the future";
            isValid = false;
        }


        setErrors(newErrors);
        return isValid;

    };
    console.log(instalDate);
    const fetchType = () => {
        getEquipementTypes().then((resp) => {
            console.log(resp.data);
            setType(resp.data);
        }).catch((err) => {
            console.log(err);
        })
    }

    const fetchSection = (param) => {
        getSections(param).then((resp) => {
            console.log(resp.data);
            setSection(resp.data);
        }).catch(() => {
            console.log(resp.data);
            setSection(resp.data);
        })
    }
    const fetchManuplant = () => {
        getManufacturingPlant().then(async (resp) => {
            console.log(resp.data);
            setManuPlant(resp.data);
        }).catch((err) => {
            console.log(resp.data);
            setManuPlant(resp.data);
        })
    }

    useEffect(() => {
        fetchType();
        fetchManuplant();
    }, []);

    useEffect(() => {
        if (section.length > 0) {
            if (props.details) {
                for (let scn of section) {
                    if (scn._id === props.details.Section._id) {
                        setSelSecction({ ...scn });
                    }
                }
            }
        }
    }, [section]);

    useEffect(() => {
        if (selManuPlant) {
            fetchSection(`ManufacturingPlant=${selManuPlant._id}`);
            setSelSecction(null);
        }
        else {
            setSelSecction(null);
            setSection([]);
        }
    }, [selManuPlant]);

    const submit = async () => {

        try {
            const isValid = validate();
            console.log(isValid);
            if (isValid) {
                setLoading(true);
                const equipmentData = {
                    TypeName: newField.typename,
                    TypeCode: newField.typecode,
                    EquipmentCode: newField.eqpcode,
                    EquipmentName: newField.eqpname,
                    Section: selSection._id,
                    SectionName: newField.sectionname,
                    ManufacturingPlant: selManuPlant._id,
                    InstallationDate: new Date(moment(`${newField.installationdate}`).toDate()),
                    Description: newField.description,
                    EquipmentType: selType._id
                };
                console.log("Submitting with customersData:", equipmentData);
                if (props.details && props.details._id) {
                    console.log("Updating Equipment with id ", props.details._id)
                    await updateEquipments(props.details._id, equipmentData);
                    showNotification("success", "Success", "Updated the Equipment successfully");
                    props.handlesuccess();
                } else {
                    console.log(equipmentData);
                    await addEquipments(equipmentData);
                    showNotification("success", "Success", "Added the Equipment successfully");
                    props.handlesuccess();
                }
                setLoading(false);
                props.handlesuccess();
                props.handleclose();
            }
        } catch (err) {
            console.error("Error adding/updating Equipment:", err);
            setLoading(false);
            showNotification("error", "Error", "Failed to add/update Equipment.");
        }
    };

    useEffect(() => {
        console.log("props.details", props.details)
        if (props.details) {
            setSelType(props.details.EquipmentType);
            let temp_section = { ...props.details.Section };
            temp_section.ManufacturingPlant.Location = temp_section.ManufacturingPlant.Location._id;
            console.log("The temp section", temp_section);
            setSelSecction({ ...temp_section });
            setSelManuPlant(props.details.Section.ManufacturingPlant);
            setInstalDate(moment(props.details.InstallationDate).toDate());
            setNewField({
                typename: props.details.EquipmentType.TypeName,
                typecode: props.details.EquipmentType.TypeCode,
                eqpcode: props.details.EquipmentCode,
                eqpname: props.details.EquipmentName,
                sectionname: props.details.Section.SectionName,
                sectioncode: props.details.Section.SectionCode,
                description: props.details.Description,
                manuplant: props.details.Section.ManufacturingPlant,
                section: props.details.Section.Section
            });
        }
    }, [props.details]);
    console.log(instalDate);
    return (
        <Dialog
            id="modal-display"
            open={props.isopen}
            aria-labelledby="form-dialog-title"
            aria-describedby="modal-modal-description"
            maxWidth="90%"
            maxHeight="90%"
            onClose={props.handleclose}
            disableBackdropClick
        >
            <div>
                <DialogTitle>Add / Edit Equipments</DialogTitle>
            </div>
            <DialogContent sx={{ minWidth: 500 }}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <MDInput
                            fullWidth
                            label="Equipment Name"
                            value={newField.eqpname}

                            helperText={<FormHelperText sx={{ color: 'red', fontWeight: 800, margin: 0 }}>{errors.eqpname}</FormHelperText>}
                            onChange={(e) => {
                                setNewField({
                                    ...newField,
                                    eqpname: e.target.value
                                });
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <MDInput
                            label="Equipment Code"
                            value={newField.eqpcode}

                            helperText={<FormHelperText sx={{ color: 'red', fontWeight: 800, margin: 0 }}>{errors.eqpcode}</FormHelperText>}
                            fullWidth
                            onChange={(e) => {
                                setNewField({
                                    ...newField,
                                    eqpcode: e.target.value.toUpperCase()
                                });
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Autocomplete
                            disableClearable
                            label={"Type"}
                            sx={{ flexGrow: 1 }}
                            options={type}
                            getOptionLabel={(option) => option.TypeName || 'Type'}
                            renderInput={(params) => <MDInput {...params} label={"Type"} fullWidth />}
                            onChange={(event, newValue) => {
                                setSelType(newValue);
                            }}

                            value={selType}

                        />
                        {(!newField.type || newField.type === 0) && (
                            <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 2 }}>
                                {errors.typename}
                            </FormHelperText>
                        )}
                    </Grid>


                    <Grid item>
                        <Autocomplete
                            disableClearable
                            label={"Manufacturing Plant"}
                            sx={{ flexGrow: 1 }}
                            options={manuPlant}
                            getOptionLabel={(option) => option.PlantName || ''}
                            renderInput={(params) => <MDInput  {...params} label={"Manufacturing Plant"} fullWidth />}
                            onChange={(event, newValue) => {
                                setSelManuPlant(newValue);
                            }}
                            value={selManuPlant}

                        />
                        {(!newField.manuplant || newField.manuplant === 0) && (
                            <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 2 }}>
                                {errors.manuplant}
                            </FormHelperText>
                        )}
                    </Grid>
                    <Grid item>
                        <Autocomplete
                            label={"Section"}
                            sx={{ flexGrow: 1 }}
                            options={section}
                            getOptionLabel={(option) => option.SectionName || ''}
                            renderInput={(params) => <MDInput  {...params} label={"Section"} fullWidth />}
                            onChange={(event, newValue) => {
                                setSelSecction(newValue);
                            }}
                            value={selSection}
                        />
                        {(!newField.section || newField.section === 0) && (
                            <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 2 }}>
                                {errors.section}
                            </FormHelperText>
                        )}
                    </Grid>

                    <Grid item>
                        <MDInput
                            fullWidth
                            type="date"
                            label={"Installation Date"}
                            value={moment(instalDate).format("YYYY-MM-DD")}
                            onChange={(event) => {
                                const value = event.target.value;
                                setInstalDate(value);
                                setNewField({
                                    ...newField,
                                    installationdate: value
                                });
                            }}

                        />

                        <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 2 }}>
                            {errors.installationdate}
                        </FormHelperText>

                    </Grid>


                    <Grid item>
                        <MDInput
                            label="Description"
                            multiline
                            rows={3}
                            value={newField.description}

                            helperText={<FormHelperText sx={{ color: 'red', fontWeight: 800, margin: 0 }}>{errors.description}</FormHelperText>}
                            fullWidth
                            onChange={(e) => {
                                setNewField({
                                    ...newField,
                                    description: e.target.value
                                });
                            }}
                        />
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <MDButton variant="gradient" color="error" onClick={props.handleclose}>
                    <Icon>close</Icon>&nbsp;
                    Cancel
                </MDButton>
                <MDButton variant="gradient" color="success" onClick={submit}>
                    <Icon>save</Icon>&nbsp;
                    Save
                </MDButton>
            </DialogActions>
        </Dialog >
    )
}
