import axios from "axios";
import moment from 'moment';

export const FILE_API_ENDPOINT = "https://dev.iggietech.in/inzpire/";
// export const FILE_API_ENDPOINT = "http://18.61.183.168:3022/";
export const API_ENDPOINT = `${FILE_API_ENDPOINT}`;

var cancelToken;

export const getAxiosInstance = () => {
    var axiosInstance = axios.create({
        timeout: 1000 * 60
    });
    axiosInstance.defaults.baseURL = API_ENDPOINT;
    return axiosInstance;
};

var axiosInstance = getAxiosInstance();
axiosInstance.interceptors.request.use(
    function (config) {
        console.log(config.url);
        if (config.url === "users/login") {
            return config;
        } else {
            let authToken = window.localStorage.getItem("jwt");
            console.log("Checking", authToken);
            console.log(authToken);
            config.headers["Authorization"] = `Bearer ${authToken}`;
        }
        return config;
    },
    function (error) {
        console.log(error);
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    console.log(error.response.status);
    if (error.response.status === 401) {
        window.localStorage.clear();
        window.location.reload();
    }
    return Promise.reject(error);
});

export const fetchLogin = (username, password) => {
    return axiosInstance.post(
        "users/login",
        {
            username: username,
            password: password
        },
        {}
    );
};

export const getUOM = () => {
    return axiosInstance.get(`uom`);
}

export const getProductCategories = (param) => {
    console.log("Calling API with param:", param);
    return axiosInstance.get(`ProductCategories${param ? `?${param}` : ``}`);
}

export const addProduct = (details) => {
    return axiosInstance.post(`Products`, details);
}

export const getAllProducts = (param) => {
    return axiosInstance.get(`Products${param ? `?${param}` : ``}`);
}


export const addInventoryProduct = (details) => {
    return axiosInstance.post(`Inventory`, details);
}

export const getInventoryItems = (param) => {
    return axiosInstance.get(`Inventory${param ? `?${param}` : ``}`);
}

export const uploadFile = (data) => {
    return axiosInstance.post(`/upload`, data, {});
}
export const getStockTransactions = (param) => {
    return axiosInstance.get(`StockTransactions${param ? `?${param}` : ``}`);
}
export const getGraphyData = (param) => {
    return axiosInstance.get(`StockTransactions/stocking${param ? `?${param}` : ``}`);
}
export const getRecipes = () => {
    return axiosInstance.get(`Recipes`);
}

export const getInventoryRawMaterials = () => {
    return axiosInstance.get(`Inventory/rawmaterials`);
}

export const getInventoryFinishedGoods = () => {
    return axiosInstance.get(`Inventory/finishedgoods`);
}

export const createRecipe = (details) => {
    return axiosInstance.post('Recipes', details);
}

export const updateRecipe = (id, details) => {
    return axiosInstance.put(`Recipes/${id}`, details);
}

export const getRecipeIngredients = (recipe) => {
    return axiosInstance.get(`RecipeIngredients?Recipe=${recipe}`);
}

export const getSuppliers = () => {
    return axiosInstance.get(`Suppliers`);
}

export const addSupplier = (details) => {
    return axiosInstance.post(`Suppliers`, details);
}


export const updatesupplier = (id, details) => {
    return axiosInstance.put(`Suppliers/${id}`, details);
}


export const getCustomers = () => {
    return axiosInstance.get(`Customers`);
}

export const addCustomers = (details) => {
    return axiosInstance.post(`Customers`, details);
}

export const updateCustomers = (id, details) => {
    return axiosInstance.put(`Customers/${id}`, details);
}

export const getBills = (param) => {
    return axiosInstance.get(`Bills${param ? `?${param}` : ``}`);
}

export const addBills = (details) => {
    return axiosInstance.post(`Bills`, details);
}

export const getBilldetails = (param) => {
    return axiosInstance.get(`BillDetails${param ? `?${param}` : ``}`);
}

export const addSaleOrder = (details) => {
    return axiosInstance.post(`SaleOrders`, details);
}

export const getSaleOrder = (param) => {
    return axiosInstance.get(`SaleOrders${param ? `?${param}` : ``}`);
}
export const getSaleOrderDetails = (param) => {
    return axiosInstance.get(`SaleOrderDetails${param ? `?${param}` : ``}`);
}

export const getEquipements = (param) => {
    return axiosInstance.get(`Equipments${param ? `?${param}` : ``}`);
}
export const addEquipments = (details) => {
    return axiosInstance.post(`Equipments`, details)
}
export const updateEquipments = (id, details) => {
    return axiosInstance.put(`Equipments/${id}`, details)
}
export const getEquipementTypes = (param) => {
    return axiosInstance.get(`EquipmentTypes${param ? `?${param}` : ``}`);
}
export const getSections = (param) => {
    return axiosInstance.get(`Sections${param ? `?${param}` : ``}`);
}
export const getManufacturingPlant = (param) => {
    return axiosInstance.get(`ManufacturingPlant${param ? `?${param}` : ``}`);
}

// export const getMaintenanceLogs=(param)=>{
//     return axiosInstance.get(`MaintenanceLogs${param ? `?${param}` : ``}`);
// }
export const getMaintenanceLogs = (param) => {
    return axiosInstance.get(`MaintenanceLogs${param ? `?${param}` : ``}`);
}
export const addMaintenanceLogs = (details) => {
    return axiosInstance.post(`MaintenanceLogs`, details)
}
export const updateMaintenanceLogs = (id, details) => {
    return axiosInstance.put(`MaintenanceLogs/${id}`, details)
}
export const getMaintenanceSchedules = (param) => {
    return axiosInstance.get(`MaintenanceSchedules${param ? `?${param}` : ``}`);
}
export const addMaintenanceSchedules = (details) => {
    return axiosInstance.post(`MaintenanceSchedules`, details)
}
export const updateMaintenanceSchedules = (id, details) => {
    return axiosInstance.put(`MaintenanceSchedules/${id}`, details)
}
export const deleteMaintenanceSchedules = (id) => {
    return axiosInstance.delete(`MaintenanceSchedules/${id}`)
}
export const getMaintainanceTypes = (param) => {
    return axiosInstance.get(`MaintainanceTypes${param ? `?${param}` : ``}`);
}

export const getReplenishemnts = (details) => {
    return axiosInstance.post(`Inventory/replenishment`, details);
}

export const getRecipeUnit = (param) => {
    return axiosInstance.get(`Recipes/manufacturingplant${param ? `?${param}` : ``}`);
}

export const addLot = (details) => {
    return axiosInstance.post(`LotDetails`, details);
}

export const getManufacturingPlants = (param) => {
    return axiosInstance.get(`ManufacturingPlant${param ? `?${param}` : ``}`);
}

export const getUpcomingLots = () => {
    return axiosInstance.get(`LotDetails/upcoming`);
}

export const getLiveLots = () => {
    return axiosInstance.get(`LotDetails/live`);
}

export const getLotRawMaterials = (param) => {
    return axiosInstance.get(`LotDetails/rawmaterials${param ? `?${param}` : ``}`)
}

export const dispatchRawMaterials = (details) => {
    return axiosInstance.post(`LotDetails/rawmaterials/dispatch`, details);
}

export const startLot = (lot_id) => {
    return axiosInstance.put(`LotDetails/start/${lot_id}`, {});
}

export const stopLot = (lot_id, details) => {
    return axiosInstance.put(`LotDetails/stop/${lot_id}`, details ? details : {});
}

export const getLots = (param) => {
    return axiosInstance.get(`LotDetails${param ? `?${param}` : ``}`);
}

export const getDashboard = () => {
    return axiosInstance.get(`Products/dashboard`);
}

export const getLotsTrend = (param) => {
    return axiosInstance.get(`LotDetails/trend${param ? `?${param}` : ``}`);
}

export const getProducedQtyTrend = (param) => {
    return axiosInstance.get(`LotDetails/stocktrend${param ? `?${param}` : ``}`);
}

export const getPerformanceTrend = (param) => {
    return axiosInstance.get(`LotDetails/performacetrend${param ? `?${param}` : ``}`);
}

export const updateSaleOrder = (details) => {
    return axiosInstance.post(`SaleOrders/update`, details);
}

export const getOrderStatuses = (param) => {
    return axiosInstance.get(`OrderStatus${param ? `?${param}` : ``}`);
}

export const getRuntimeEvents = (param) => {
    return axiosInstance.get(`RuntimeEvents${param ? `?${param}` : ``}`);
}

export const createRuntimeEvents = (details) => {
    return axiosInstance.post(`RuntimeEvents`, details);
}

export const getEventTypes = (param) => {
    return axiosInstance.get(`EventTypes${param ? `?${param}` : ``}`);
}

export const getFinishedGoodInventory = (param) => {
    return axiosInstance.get(`Inventory/finishedgoods/required${param ? `?${param}` : ``}`);
}