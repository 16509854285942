

import React, { useState } from 'react'

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from '@mui/material/Stack';
import Icon from "@mui/material/Icon";


// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import moment from "moment";
import * as XLSX from 'xlsx';



import { useEffect, } from "react";
import { useNavigate } from 'react-router-dom';

import TableData from './Data/tableData';
import DataTable from 'examples/Tables/DataTable';
import AddSupplierPopUp from './compoents/Addsupplier';
import MDButton from 'components/MDButton';

import { useLoading } from "context/loading.context";
import { useMaterialUIController } from "context";
import { getSuppliers } from 'network/service';



export default function Suppliers() {
    const { showLoader, hideLoader } = useLoading();
    const [supplierPopUp, setSupplierPopUp] = useState({ open: false, details: null });
    const [controller, dispatch] = useMaterialUIController();
    const { sidenavColor } = controller;
    const navigate = useNavigate();
    const [suppliers, setSupplier] = useState([])
     const { productHeaders,  formSuppliersRows } = TableData();

    const fetchSupplier = () => {
        showLoader();
        getSuppliers().then((resp) => {
            setSupplier(resp.data);
            hideLoader();
        }).catch((err) => {
            hideLoader();
            console.log(err);
        })
    }

    useEffect(() => {

        fetchSupplier();
    }, [setSupplier])

    const exportToExcel = (data, filename) => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, `${filename}.xlsx`);
    };
   
   
    return (
        <DashboardLayout>
            <DashboardNavbar />
            {supplierPopUp.open && <AddSupplierPopUp isopen={supplierPopUp.open} details={supplierPopUp.details} handleclose={() => { setSupplierPopUp({ open: false, details: null }) }} handlesuccess={()=>{setSupplierPopUp({ open: false, details: null,}),fetchSupplier()}}/>}
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor={sidenavColor}
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Suppliers Table
                                </MDTypography>
                            </MDBox>
                            <Stack
                                direction="row-reverse"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={2}
                            >
                                <MDBox pt={3} pr={3}>
                                    <MDButton
                                        variant="outlined"
                                        color="success"
                                        onClick={() => {
                                            setSupplierPopUp({ open: true, details: null });
                                        }}
                                    >
                                        Add Supplier
                                    </MDButton>
                                </MDBox>
                               <MDBox pt={3}>
                                    {/* <MDButton variant="gradient" color="warning" onClick={() => {
                                        exportToExcel(formSuppliersRows(suppliers), moment().unix());
                                    }}>
                                        <Icon>download</Icon>&nbsp;
                                        Export
                                    </MDButton> */}
                                </MDBox>
                            </Stack>
                            <MDBox sx={{ maxHeight: "50vh", overflow: 'auto' }}>
                                <DataTable
                                    table={{
                                        columns: productHeaders,
                                        rows: formSuppliersRows(suppliers, setSupplierPopUp)
                                    }}
                                    noEndBorder
                                    canSearch
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    )
}
