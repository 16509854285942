/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

import { Icon } from "@mui/material";
import MDButton from "components/MDButton";
import moment from "moment";

export default function TableData() {

    const formLotRows = (data) => {
        let result = [];
        for (let dt of data) {
            result.push({
                date: moment(dt.StartTime).format("DD-MM-YYYY HH:mm"),
                lot: dt.LotNumber,
                qty: dt.Quantity,
                recipe: dt.Recipe.RecipeName,
                unit: dt.ManufacturingPlant.PlantName
            });
        }
        return result
    }

    return {
        formLotRows,
        lotHeaders: [
            { Header: "Date", accessor: "date", align: "center" },
            { Header: "Lot No.", accessor: "lot", align: "center" },
            { Header: "Recipe", accessor: "recipe", align: "center" },
            { Header: "Planned Quantity", accessor: "qty", align: "center" },
            { Header: "Unit", accessor: "unit", align: "center" },
            // { Header: "Action", accessor: "action", align: "center" }
        ],
        sampleRows: [
            {
                number: "251",
                date: "25-03-2024",
                items: 5,
                total: 3000,
                supplier: "Nirals Enterprises",
                action: "View"
            }
        ],
    };
}
