
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
const moment = require("moment");


export default function MaintainaceSchedulesTable() {

    function getBadgeType(data) {
        let colour = "error";
        let content = "Down";
        if (data.EventType.EventName !== "DOWN") {
            colour = "info";
            content = data.EventType.EventName;
        }
        return (<MDBox ml={-1}>
            <MDBadge badgeContent={content} color={colour} variant="gradient" size="sm" />
        </MDBox>);
    }


    const formEventsRow = (data, setShedulePopUp) => {
        let result = [];
        for (let dt of data) {
            result.push({
                equipment: dt.Equipment.EquipmentName,
                manufacurtingpalnt: dt.Equipment.Section.ManufacturingPlant.PlantName,
                section: dt.Equipment.Section.SectionName,
                type: getBadgeType(dt),
                start: moment(dt.StartTime).format("YYYY-MM-DD HH:mm"),
                end: moment(dt.EndTime).format("YYYY-MM-DD HH:mm"),
                comment: dt.Comments
            });
        }
        return result;
    };

    return {
        formEventsRow,
        EventsHeader: [
            { Header: "Equipment ", accessor: "equipment", align: "center" },
            { Header: "Manufacturing Plant", accessor: "manufacurtingpalnt", align: "center" },
            { Header: "Section", accessor: "section", align: "center" },
            { Header: "Event Type", accessor: "type", align: "center" },
            { Header: "Start Time", accessor: "start", align: "center" },
            { Header: "End Time", accessor: "end", align: "center" },
            { Header: "Comments", accessor: "comment", align: "center" }
        ]
    };
}
