// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { Bar } from "react-chartjs-2";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Doughnut } from "react-chartjs-2";
import { useMaterialUIController } from "context";
import Skeleton from '@mui/material/Skeleton';


import colors from "assets/theme/base/colors";
import dark_colors from "assets/theme-dark/base/colors";

import configs from "./config";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DataTable from "examples/Tables/DataTable";
import { stopLot } from "network/service";

const dummy_data = [{
    section: "Cleaning & Filling",
    in: 100,
    out: 100,
    time: 20
}, {
    section: "Cooling",
    in: 100,
    out: 98,
    time: 20
}, {
    section: "Labelling",
    in: 98,
    out: 98,
    time: 20
}, {
    section: "Packing",
    in: 97,
    out: 90,
    time: 20
}];


export default function LiveLotCard(props) {

    const [controller] = useMaterialUIController();
    const { sidenavColor, darkMode } = controller;
    const [menu, setMenu] = useState(null);

    const openMenu = ({ currentTarget }) => setMenu(currentTarget);
    const closeMenu = () => setMenu(null);

    const { data, options } = configs(props.chart.labels || [], props.chart.datasets || {}, (darkMode) ? dark_colors[sidenavColor].main : colors[sidenavColor].main);

    const getTimeDiff = () => {
        let givenDate = new Date(props.details.StartTime);
        let now = new Date();
        let differenceInMilliseconds = now - givenDate;
        let differenceInHours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
        let differenceInMinutes = Math.floor((differenceInMilliseconds / (1000 * 60)) % 60);
        let formattedDifference = `${differenceInHours} hours ${differenceInMinutes} minutes`;
        return formattedDifference;
    }


    const formlotrows = (datum) => {
        let result = [];
        for (let data of datum) {
            result.push({
                section: data.section,
                in: data.in,
                out: data.out
            });
        }
        return result;
    }

    const renderMenu = (
        <Menu
            id="simple-menu"
            anchorEl={menu}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={Boolean(menu)}
            onClose={closeMenu}
        >
            <MenuItem onClick={() => { props.stopLot(); closeMenu(); }}>Stop Lot</MenuItem>
            <MenuItem onClick={closeMenu} disabled>More Details</MenuItem>
        </Menu>
    );


    return (
        <Card sx={{ boxShadow: 6 }}>
            <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
                <MDBox
                    variant="gradient"
                    bgColor={sidenavColor}
                    color={sidenavColor === "light" ? "dark" : "white"}
                    coloredShadow={sidenavColor}
                    borderRadius="xl"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="4rem"
                    height="4rem"
                    mt={-3}
                >
                    {props.loading ? <Skeleton variant="circular" width={40} height={40} /> : <Icon fontSize="medium" color="inherit">
                        {props.icon}
                    </Icon>}
                </MDBox>
                <MDBox py={1}>
                    <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
                        more_vert
                    </Icon>
                    {/* <MDTypography variant="button" fontWeight="light" color="text">
                        {props.title}
                    </MDTypography>
                    <MDTypography variant="h4">{props.count}</MDTypography> */}
                </MDBox>
                {(!props.loading) && renderMenu}
            </MDBox>
            <Divider />
            <MDBox pt={1} py={2} px={3}>
                <Grid container spacing={2}>
                    <Grid item xl={6} md={12} xs={6} sx={{ textAlign: 'left', textOverflow: 'ellipsis' }}>
                        {props.loading ? <Skeleton variant="text" /> :
                            <MDTypography variant="button" fontWeight="light" color="text">
                                {"Recipe"}
                            </MDTypography>}
                        {props.loading ? <Skeleton variant="text" /> :
                            <MDTypography variant="h6">{props.details.Recipe.RecipeName}</MDTypography>}
                    </Grid>
                    <Grid item xl={6} md={12} xs={6} sx={{ textAlign: 'right', textOverflow: 'ellipsis' }}>
                        {props.loading ? <Skeleton variant="text" /> :
                            <MDTypography variant="button" fontWeight="light" color="text">
                                {"Quantity"}
                            </MDTypography>}
                        {props.loading ? <Skeleton variant="text" /> :
                            <MDTypography variant="h6">{`${props.details.Quantity} Ltrs`}</MDTypography>}
                    </Grid>
                </Grid>
            </MDBox>
            <MDBox pt={1} px={3}>
                <Grid container spacing={2}>
                    <DataTable
                        table={{
                            columns: [
                                { Header: "Section", accessor: "section", align: "center", width: "40%" },
                                { Header: "In", accessor: "in", align: "center", width: "20%" },
                                { Header: "Out", accessor: "out", align: "center", width: "20%" }
                            ],
                            rows: dummy_data
                        }}
                        showTotalEntries={false}
                        isSorted={false}
                        noEndBorder
                        entriesPerPage={false}
                    />
                </Grid>
            </MDBox>
            {/* <MDBox pt={1} py={2} px={3}>
                <Grid container spacing={2}>
                    <Grid item xl={6} md={12} xs={6} sx={{}}>
                        <MDBox py={2} >
                            {props.loading ? <Skeleton variant="rounded" height={100} /> :
                                <Bar data={data} options={options} />}
                        </MDBox>
                    </Grid>
                    <Grid item xl={6} md={12} xs={6} >
                        <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
                            {props.loading ? <Skeleton variant="circular" width={100} height={100} sx={{ my: 2 }} /> :
                                <Doughnut data={{
                                    labels: ['Completed', 'To Complete'],
                                    datasets: [
                                        {
                                            data: [2, 3],
                                            backgroundColor: [
                                                (darkMode) ? dark_colors[sidenavColor].main : colors[sidenavColor].main,
                                                'rgba(169,169,169, 0.2)',
                                            ],
                                            borderColor: "transparent"
                                        },
                                    ],
                                }}
                                    options={{
                                        animation: false,
                                        cutout: "90%",
                                        responsive: true,
                                        maintainAspectRatio: true,
                                        plugins: {
                                            legend: {
                                                display: false
                                            }
                                        }
                                    }} />}
                        </Grid>
                    </Grid>
                </Grid>
            </MDBox> */}
            <Divider />
            <MDBox pb={2} px={2}>
                {props.loading ? <Skeleton variant="text" /> :
                    <MDTypography component="p" variant="button" color="text" display="flex">
                        &nbsp;{"Total Run time: "}
                        <MDTypography
                            component="span"
                            variant="button"
                            fontWeight="bold"
                            color={sidenavColor}
                        >
                            {getTimeDiff()}
                        </MDTypography>
                    </MDTypography>}
            </MDBox>
        </Card>);
}