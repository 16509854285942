import { Icon } from '@mui/material';
import MDButton from 'components/MDButton';
import { deleteMaintenanceSchedules } from 'network/service';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useNotification } from "context/notification.context";

export default function MaintainaceSchedulesTable() {
    const { showNotification } = useNotification();


    const deleteSchedule = async (_id) => {
        try {
            await deleteMaintenanceSchedules(_id);
            showNotification("success", "Success", "Deleted the Schedule successfully");
        } catch (err) {
            console.error("Failed to delete schedule:", err);
            showNotification("error", "Error", "Failed to delete the Schedule");
        }
    };

    const formMaintenanceSchedulesRow = (data, refreshData) => {
        let result = [];
        for (let dt of data) {
            result.push({
                equipment: dt.Equipment.EquipmentName,
                section: dt.Equipment.Section.SectionName,
                manufacturingPlant: dt.Equipment.Section.ManufacturingPlant.PlantName,
                planstart: moment(dt.PlannedStartDate).format("YYYY-MM-DD"),
                planend: moment(dt.PlannedEndDate).format("YYYY-MM-DD"),
                action: (
                    <MDButton variant="outlined" color="error" iconOnly onClick={async () => {
                        await deleteSchedule(dt._id);
                        refreshData();
                    }}>
                        <Icon>delete</Icon>
                    </MDButton>
                ),
            });
        }
        return result;

    };

    return {
        formMaintenanceSchedulesRow,
        MaintenanceSchedulesHeader: [
            { Header: "Equipment", accessor: "equipment", align: "center" },
            { Header: "Manufacturing Plant", accessor: "manufacturingPlant", align: "center" },
            { Header: "Section", accessor: "section", align: "center" },
            { Header: "PlanntedStart Date", accessor: "planstart", align: "center" },
            { Header: "PlanntedEnd Date", accessor: "planend", align: "center" },
            { Header: "Action", accessor: "action", align: "center" }
        ],

        sampleRows: [
            {
                supplierName: "Nandam traders",
                code: "NE-004",
                contactPerson: "Nandam Raju",
                contactNumber: "987654322",
                email: "nandam.enterprises@example.com",
                address: "sample address 3"
            },
        ],

    };
}
