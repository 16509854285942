import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { Autocomplete, FormHelperText, Icon } from "@mui/material";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useNotification } from "context/notification.context";
import { getMaintenanceSchedules, addMaintenanceSchedules, updateMaintenanceSchedules } from "network/service";
import moment from "moment";
import { getEquipements, getSections, getManufacturingPlant } from "network/service";
import { useLoading } from 'context/loading.context';

export default function AddShedulesPopUp(props) {
    const [newField, setNewField] = useState({ equipment: "", section: "", manuplant: "", planstart: moment().format("YYYY-MM-DD"), planend: moment().format("YYYY-MM-DD") });
    const [errors, setErrors] = useState({});
    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(false);
    const [manuPlant, setManuPlant] = useState([]);
    const [selManuPlant, setSelManuPlant] = useState(null);
    const [section, setSection] = useState([]);
    const [equipment, SetEquipment] = useState([]);
    const [selEquipmentName, setSelEquimentName] = useState(null);
    const [selSection, setSelSection] = useState(null);
    const [planStart, setPlanStart] = useState(new Date());
    const [planEnd, setPlanEnd] = useState(new Date());
    const { showLoader, hideLoader } = useLoading();
    const [submitted, setSubmitted] = useState(false);

    const validate = () => {
        let isValid = true;
        let newErrors = {};

        const today = new Date();
        const startedDate = new Date(newField.planstart);

        if (!selEquipmentName) {
            newErrors.equipment = "Equipment name is required";
            isValid = false;
        }

        if (!selManuPlant) {
            newErrors.manuplant = "Manufacturing plant is required";
            isValid = false;
        }

        if (!selSection) {
            newErrors.section = "Section is required";
            isValid = false;
        }

        const endedDate = new Date(newField.planend);
        if (newField.planstart === 0) {
            newErrors.planstart = "Plan start date is required";
            isValid = false;
        } else if (startedDate <= new Date()) {
            newErrors.planstart = "Plan start date must be in Future";
            isValid = false;
        }

        if (newField.planend === 0) {
            newErrors.planend = "Plan end date is required";
            isValid = false;
        } else if (endedDate < startedDate) {
            newErrors.planend = "End date must be greater than start date";
            isValid = false;
        }

        setErrors(newErrors);
        console.log("Validation errors:", newErrors);
        return isValid;
    };


    const fetchEqupmientName = (param) => {
        getEquipements(param).then((resp) => {
            SetEquipment(resp.data);
        }).catch((err) => {
            SetEquipment([]);
        });
    };

    const fetchSection = (param) => {
        getSections(param).then((resp) => {
            setSection(resp.data);
        }).catch((err) => {
            setSection([]);
        });
    };

    const fetchManuplant = (param) => {
        getManufacturingPlant(param).then((resp) => {
            setManuPlant(resp.data);
        }).catch((err) => {
            setManuPlant([]);
        });
    };

    useEffect(() => {
        fetchManuplant();
    }, []);

    useEffect(() => {
        if (selManuPlant) {
            fetchSection(`ManufacturingPlant=${selManuPlant._id}`);
            setSelSection(null);
        } else {
            setSection([]);
            setSelSection(null);
        }
    }, [selManuPlant]);

    useEffect(() => {
        if (selSection) {
            fetchEqupmientName(`Section=${selSection._id}`);
            setSelEquimentName(null);
        } else {
            SetEquipment([]);
            setSelEquimentName(null);
        }
    }, [selSection]);

    const submit = async () => {
        try {
            const isValid = validate();
            console.log(isValid);
            if (isValid) {
                setLoading(true);
                const ScheduleData = {
                    Equipment: selEquipmentName._id,
                    PlannedStartDate: newField.planstart,
                    PlannedEndDate: newField.planend,
                };



                if (props.details && props.details._id) {
                    await updateMaintenanceSchedules(props.details._id, updateSheduleData);
                    showNotification("success", "Success", "Updated the Schedule successfully");
                    props.handlesuccess();
                } else {
                    await addMaintenanceSchedules(ScheduleData);
                    showNotification("success", "Success", "Added the MaintenanceLog successfully");
                    props.handlesuccess();
                }

                setLoading(false);
                props.handleclose();
            }
        } catch (err) {
            console.error("Error adding/updating MaintenanceLog:", err);
            setLoading(false);
            showNotification("error", "Error", "Failed to add/update MaintenanceLog. Please check the console for more details.");
        }
    };

    useEffect(() => {
        if (props.details) {
            setSelManuPlant(props.details.ManufacturingPlant);
            setSelEquimentName(props.details.Equipment);
            setSelSection(props.details.SectionName);
            setNewField({
                equipment: props.details.Equipment.EquipmentName,
                planstart: moment(props.details.PlannedStartDate).format("YYYY-MM-DD"),
                planend: moment(props.details.PlannedEndDate).format("YYYY-MM-DD"),
            });
        }
    }, [props.details]);



    return (
        <Dialog
            id="modal-display"
            open={props.isopen}
            aria-labelledby="form-dialog-title"
            aria-describedby="modal-modal-description"
            maxWidth="90%"
            maxHeight="90%"
            onClose={props.handleclose}
            disableBackdropClick
        >
            <div>
                <DialogTitle>Add / Edit MaintenanceLog</DialogTitle>
            </div>
            <DialogContent sx={{ minWidth: 500 }}>
                <Grid container direction="column" spacing={2}>
                    {!props.details && (
                        <Grid item>
                            <Autocomplete
                                disableClearable
                                label={"Manufacturing Plant"}
                                sx={{ flexGrow: 1 }}
                                options={manuPlant}
                                getOptionLabel={(option) => option.PlantName || ""}
                                renderInput={(params) => <MDInput {...params} label={"Manufacturing Plant"} fullWidth />}
                                onChange={(event, newValue) => {
                                    setSelManuPlant(newValue);
                                    setNewField({
                                        ...newField,
                                        manuplant: newValue
                                    });
                                }}
                                value={selManuPlant}
                            />
                            {(!newField.manuplant || newField.manuplant === "") && (
                                <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 1.8, }}>
                                    {errors.manuplant}
                                </FormHelperText>
                            )}
                        </Grid>
                    )}

                    {!props.details && (
                        <Grid item>
                            <Autocomplete
                                label={"Section"}
                                sx={{ flexGrow: 1 }}
                                error={errors.section}
                                options={section}
                                getOptionLabel={(option) => option.SectionName || ''}
                                renderInput={(params) => <MDInput {...params} label={"Section"} fullWidth />}
                                onChange={(event, newValue) => {
                                    setSelSection(newValue);
                                    setNewField({
                                        ...newField,
                                        section: newValue
                                    });
                                }}
                                value={selSection}
                            />
                            {(!newField.section || newField.section === "") && (
                                <FormHelperText sx={{ color: 'red', fontWeight: 800, marginLeft: 1.8 }}>
                                    {errors.section}
                                </FormHelperText>
                            )}
                        </Grid>
                    )}

                    <Grid item>
                        <Autocomplete
                            label={"Equipment Name"}
                            sx={{ flexGrow: 1 }}
                            options={equipment}
                            getOptionLabel={(option) => option.EquipmentName || ''}
                            renderInput={(params) => <MDInput {...params} label={"Equipment Name"} fullWidth />}
                            onChange={(event, newValue) => {
                                setSelEquimentName(newValue);
                                setNewField({
                                    ...newField,
                                    equipment: newValue
                                });
                            }}
                            value={selEquipmentName}
                            disabled={!!props.details}
                        />
                        {(!newField.equipment || newField.equipment === "") && (
                            <FormHelperText sx={{ color: 'red', marginLeft: 1.8, fontWeight: 800 }}>
                                {errors.equipment}
                            </FormHelperText>
                        )}
                    </Grid>

                    <Grid item>
                        <MDInput
                            fullWidth
                            type="date"
                            label={"Planned Start Date"}
                            value={moment(planStart).format("YYYY-MM-DD")}
                            onChange={(event) => {
                                const value = event.target.value;
                                setPlanStart(value);
                                setNewField({
                                    ...newField,
                                    planstart: value
                                });
                            }}
                            helperText={
                                <FormHelperText sx={{ color: 'red', fontWeight: 800, margin: 0 }}>
                                    {errors.planstart}
                                </FormHelperText>
                            }
                        />
                    </Grid>

                    <Grid item>
                        <MDInput
                            fullWidth
                            type="date"
                            label={"Planned End Date"}
                            value={moment(planEnd).format("YYYY-MM-DD")}
                            onChange={(event) => {
                                const value = event.target.value;
                                setPlanEnd(value);
                                setNewField({
                                    ...newField,
                                    planend: value
                                });
                            }}
                            helperText={
                                <FormHelperText sx={{ color: 'red', fontWeight: 800, margin: 0 }}>
                                    {errors.planend}
                                </FormHelperText>
                            }
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <MDButton variant="gradient" color="error" onClick={props.handleclose}>
                    <Icon>close</Icon>&nbsp;
                    Cancel
                </MDButton>
                <MDButton variant="gradient" color="success" onClick={submit}>
                    <Icon>save</Icon>&nbsp;
                    Save
                </MDButton>
            </DialogActions>
        </Dialog>
    )
}
