import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import FormGroup from "@mui/material/FormGroup";
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import { Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import { FormHelperText } from "@mui/material";
import { getRecipes, getUOM } from "network/service";

export default function AddRecipePopup(props) {
    const [recipes, setRecipes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [newField, setNewField] = useState({ recipe: null, quantity: null });
    const [unit, setUnit] = useState("");
    const [uoms, setUoms] = useState([]);
    const [recipeError, setRecipeError] = useState(false);
    const [quantityError, setQuantityError] = useState(false);

    useEffect(() => {
        getUOM().then((resp) => {
            setUoms(resp.data);
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    useEffect(() => {
        getRecipes().then((resp) => {
            setRecipes(resp.data);
        }).catch((err) => {
            console.log(err);
        })
    }, []);

    useEffect(() => {
        if (newField.recipe) {
            for (let um of uoms) {
                if (um._id === newField.recipe.Product.UnitOfMeasure) {
                    setUnit(um.name);
                }
            }
        }
    }, [newField, uoms]);

    const validate = () => {
        let isValid = true;
        if (!newField.recipe) {
            setRecipeError(true);
            isValid = false;
        } else {
            setRecipeError(false);
        }
        if (!newField.quantity || parseFloat(newField.quantity) <= 0) {
            setQuantityError(true);
            isValid = false;
        } else {
            setQuantityError(false);
        }
        return isValid;
    }

    const submit = async () => {
        if (validate()) {
            props.handlesuccess({ ...newField });
        }
    }

    return (
        <Dialog
            id="modal-display"
            open={props.isopen}
            aria-labelledby="form-dialog-title"
            aria-describedby="modal-modal-description"
            maxWidth="90%"
            maxHeight="90%"
            onClose={props.handleclose}
            disableBackdropClick
        >
            <MDBox>
                <DialogTitle>Add Recipe</DialogTitle>
            </MDBox>
            <DialogContent sx={{ minWidth: 400 }}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Autocomplete
                            disableClearable
                            label={"Recipe"}
                            sx={{ flexGrow: 1 }}
                            options={recipes}
                            getOptionLabel={(option) => option.RecipeName || ''}
                            renderInput={(params) => <MDInput {...params} label={"Recipe"} fullWidth />}
                            onChange={(event, newValue) => {
                                setNewField({ ...newField, recipe: newValue });
                            }}
                            value={newField.recipe}
                        />
                        {recipeError && <FormHelperText error sx={{ color: 'red', fontWeight: 800, marginLeft:1 }}>Please select a recipe.</FormHelperText>}
                    </Grid>
                    <Grid item>
                        <MDInput
                            fullWidth
                            label="Recipe Code"
                            disabled
                            value={newField.recipe ? newField.recipe.RecipeCode : ""}
                            onChange={(e) => { }}
                        />
                    </Grid>
                    <Grid item>
                        <MDInput
                            fullWidth
                            label="UOM"
                            disabled
                            value={unit}
                            onChange={(e) => { }}
                        />
                    </Grid>
                    <Grid item>
                        <MDInput
                            label="Quantity"
                            fullWidth
                            type="number"
                            value={newField.quantity}
                            onChange={(e) => {
                                setNewField({
                                    ...newField, quantity: e.target.value
                                });
                            }}
                        />
                        {quantityError && <FormHelperText error sx={{ color: 'red', fontWeight: 800, margin:1    }}>Please enter a valid quantity.</FormHelperText>}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <MDButton variant="gradient" color="error" onClick={props.handleclose} disabled={loading}>
                    <Icon>close</Icon>&nbsp; Cancel
                </MDButton>
                <MDButton variant="gradient" color="success" disabled={loading} onClick={submit}>
                    <Icon>save</Icon>&nbsp; Save
                </MDButton>
            </DialogActions>
        </Dialog>
    )
};
