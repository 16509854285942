/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { styled } from '@mui/system';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function ProductInfoCard({ color, image, title, description, value, onClick }) {

  const ClickableCard = styled(Card)({
    cursor: 'pointer'
  });

  return (
    <ClickableCard onClick={onClick}>
      <MDBox pt={2} mx={3} display="flex" justifyContent="center">
        <MDBox
          display="grid"
          justifyContent="center"
          alignItems="center"
          color="white"
          width="9rem"
          height="9rem"
        >
          <img
            src={image}
            alt="Avatar"
            style={{ maxWidth: 150, maxHeight: 150, alignSelf: 'center' }}
          />
        </MDBox>
      </MDBox>
      <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
        <MDTypography variant="h5" fontWeight="medium" sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: '100%'
        }}>
          {title}
        </MDTypography>
        <MDTypography variant="button" color="text" fontWeight="regular" sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: '100%'
        }}>
          Available :
          <MDTypography
            component="span"
            variant="button"
            fontWeight="bold"
            color={color}
          >
            {description}
          </MDTypography>
        </MDTypography>
        <Divider />
        <MDTypography variant="button" fontWeight="medium">
          Required :
          <MDTypography
            component="span"
            variant="h6"
            fontWeight="bold"
            color="success"
          >
            {value}
          </MDTypography>
        </MDTypography>
      </MDBox>
    </ClickableCard>
  );
}

// Setting default values for the props of DefaultInfoCard
ProductInfoCard.defaultProps = {
  color: "info",
  value: "",
  description: "",
};

// Typechecking props for the DefaultInfoCard
ProductInfoCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ProductInfoCard;
