import { Icon } from '@mui/material';
import MDButton from 'components/MDButton';
const moment = require('moment');
export default function EquipmentTableColumns() {

    const formEquipmentRows = ( data, setEquipmentPopUp) => {
        let result = [];
        for (let dt of data) {
            result.push({
                typename: dt.EquipmentType.TypeName,
                eqpcode:  dt.EquipmentCode,
                eqpname:  dt.EquipmentName,
                sectionname: dt.Section.SectionName,
                sectioncode: dt.Section.SectionCode,
                description: dt.Description,
                installationdate: moment(dt.InstallationDate).format("YYYY-MM-DD "),
                manuplant: dt.Section.ManufacturingPlant.PlantName,
                action: ( <MDButton variant="outlined" color="info" iconOnly onClick={() => {
                    setEquipmentPopUp({ open: true, details: dt });
                }}>
                    <Icon>edit</Icon>
                </MDButton>
                )
            });
        }
        return result;
    };

    return {
        formEquipmentRows,
        EquipmentHeader: [
            { Header: "Equipment Name", accessor: "eqpname", align: "center" },
            { Header: "Equipment Code", accessor: "eqpcode", align: "center" },
            { Header: "Manufacturing Plant", accessor: "manuplant", align: "center" },
            { Header: "Section ", accessor: "sectionname", align: "center" },
            { Header: "Installation Date", accessor: "installationdate", align: "center" },
            { Header: "Description", accessor: "description", align: "center" },
            { Header: "Types", accessor: "typename", align: "center" },
            // { Header: "Action", accessor: "action", align: "center" }
        ],
    };
};
