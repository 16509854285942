import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { FormHelperText, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useNotification } from "context/notification.context";
import { addSupplier, updatesupplier } from "network/service";

export default function AddSupplierPopUp(props) {

    const [newField, setNewField] = useState({ name: "", suppliercode: "", contactPerson: "", contactNumber: "", email: "", address: "" });
    const [errors, setErrors] = useState({});
    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(false);

    const validate = () => {
        let isValid = true;
        let newErrors = {};

        if (newField.name.trim() === "") {
            newErrors.name = "Name is required";
            isValid = false;
        }

        if (newField.suppliercode.trim() === "") {
            newErrors.suppliercode = "Supplier code is required";
            isValid = false;
        }

        if (newField.contactPerson.trim() === "") {
            newErrors.contactPerson = "Contact person is required";
            isValid = false;
        }

        if (newField.contactNumber.trim() === "") {
            newErrors.contactNumber = "Contact number is required";
            isValid = false;
        } else if (!/^\d{10}$/.test(newField.contactNumber)) {
            newErrors.contactNumber = "Please enter a valid 10-digit contact number";
            isValid = false;
        }

        if (newField.email.trim() === "") {
            newErrors.email = "Email is required";
            isValid = false;
        } else if (!/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/.test(newField.email)) {
            newErrors.email = "Please enter a valid email";
            isValid = false;
        }

        if (newField.address.trim() === "") {
            newErrors.address = "Address is required";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    }

    const submit = async () => {
        try {
            const isValid = validate();

            if (isValid) {
                setLoading(true);
                const supplierData = {
                    SupplierCode: newField.suppliercode.toUpperCase(),
                    SupplierName: newField.name,
                    ContactPerson: newField.contactPerson.toUpperCase(),
                    ContactNumber: newField.contactNumber,
                    Email: newField.email,
                    Address: newField.address
                };

                console.log("Submitting with supplierData:", supplierData);

                if (props.details && props.details._id) {
                    console.log("Updating supplier with id:", props.details._id);
                    await updatesupplier(props.details._id, supplierData);
                    showNotification("success", "Success", "Updated the supplier successfully");
                    props.handlesuccess();
                } else {
                    console.log("Adding new supplier:", supplierData);
                    await addSupplier(supplierData);
                    showNotification("success", "Success", "Added the supplier successfully");
                    props.handlesuccess();
                }

                setLoading(false);
                props.handleclose();
            }
        } catch (err) {
            console.error("Error adding/updating supplier:", err);
            setLoading(false);
            showNotification("error", "Error", "Failed to add/update supplier.");
        }
    };

    useEffect(() => {
        if (props.details) {
            setNewField({
                name: props.details.SupplierName,
                suppliercode: props.details.SupplierCode,
                contactPerson: props.details.ContactPerson,
                contactNumber: props.details.ContactNumber,
                email: props.details.Email,
                address: props.details.Address
            });
        }
    }, [props.details]);

    return (
        <Dialog
            id="modal-display"
            open={props.isopen}
            aria-labelledby="form-dialog-title"
            aria-describedby="modal-modal-description"
            maxWidth="90%"
            maxHeight="90%"
            onClose={props.handleclose}
            disableBackdropClick
        >
            <MDBox>
                <DialogTitle>Add / Edit Supplier</DialogTitle>
            </MDBox>
            <DialogContent sx={{ minWidth: 500 }}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <MDInput
                            label="Supplier Name"
                            value={newField.name}

                            helperText={errors.name && <FormHelperText sx={{ color: 'red', fontWeight: 800, margin: 0 }}>{errors.name}</FormHelperText>}
                            fullWidth
                            onChange={(e) => {
                                setNewField({
                                    ...newField,
                                    name: e.target.value
                                });
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <MDInput
                            fullWidth
                            label="Supplier Code"
                            value={newField.suppliercode}

                            helperText={errors.suppliercode && <FormHelperText sx={{ color: 'red', fontWeight: 800, margin: 0 }}>{errors.suppliercode}</FormHelperText>}
                            onChange={(e) => {
                                setNewField({
                                    ...newField,
                                    suppliercode: e.target.value.toUpperCase()
                                });
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <MDInput
                            label="Contact Person"
                            value={newField.contactPerson}

                            helperText={errors.contactPerson && <FormHelperText sx={{ color: 'red', fontWeight: 800, margin: 0 }}>{errors.contactPerson}</FormHelperText>}
                            fullWidth
                            onChange={(e) => {
                                setNewField({
                                    ...newField,
                                    contactPerson: e.target.value
                                });
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <MDInput
                            label="Contact Number"
                            value={newField.contactNumber}

                            helperText={errors.contactNumber && <FormHelperText sx={{ color: 'red', fontWeight: 800, margin: 0 }}>{errors.contactNumber}</FormHelperText>}
                            fullWidth
                            onChange={(e) => {
                                setNewField({
                                    ...newField,
                                    contactNumber: e.target.value
                                });
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <MDInput
                            label="Email"
                            value={newField.email}

                            helperText={errors.email && <FormHelperText sx={{ color: 'red', fontWeight: 800, margin: 0 }}>{errors.email}</FormHelperText>}
                            fullWidth
                            onChange={(e) => {
                                setNewField({
                                    ...newField,
                                    email: e.target.value
                                });
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <MDInput
                            label="Address"
                            multiline
                            rows={2}
                            value={newField.address}

                            helperText={errors.address && <FormHelperText sx={{ color: 'red', fontWeight: 800, margin: 0 }}>{errors.address}</FormHelperText>}
                            fullWidth
                            onChange={(e) => {
                                setNewField({
                                    ...newField,
                                    address: e.target.value
                                });
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <MDButton variant="gradient" color="error" onClick={props.handleclose}>
                    <Icon>close</Icon>&nbsp;
                    Cancel
                </MDButton>
              
            </DialogActions>
        </Dialog>
    )
}
