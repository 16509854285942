import React, { useEffect, useState } from 'react';
import { Card, Grid, Stack, Icon } from '@mui/material';
import moment from "moment";
import { Autocomplete } from "@mui/material";
import { useLoading } from "context/loading.context";
import { useMaterialUIController } from "context";
import { useNavigate } from 'react-router-dom';
import Footer from 'examples/Footer';
import DataTable from 'examples/Tables/DataTable';
import { getCustomers, getSaleOrder, getOrderStatuses } from 'network/service';
import UpdateSaleOrderPopUp from './compoents/UpdateSaleOrderPop';

import ViewSaleOrderPopUp from './compoents/ViewSaleOrderPopUp';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MDInput from 'components/MDInput';
import TableData from "./Data/tableColoumns"

import * as XLSX from 'xlsx';
export default function SaleOrders() {
  const { showLoader, hideLoader } = useLoading();
  const [saleOrderPopUp, setSaleOrderPopUp] = useState({ open: false, details: null });
  const [updatesaleOrderPopUp, setUpdateSaleOrderPopUp] = useState({ open: false, details: null });
  const [customers, setCustomers] = useState([]);
  const [controller] = useMaterialUIController();
  const [ordersale, setOderSale] = useState([]);
  const [from, setFrom] = useState(moment().subtract(30, 'days').toDate());
  const [to, setTo] = useState(moment().add(30, 'days').toDate());
  const [orderstatuses, setOrderStatuses] = useState([]);
  const [selOrderStatus, setSelOrderStatus] = useState(null);
  const [selCustomer, setSelCustomer] = useState(null);
  const { sidenavColor } = controller;
  const navigate = useNavigate();
  const { saleordersHeaders, sampleRows, formsaleorders } = TableData();

  useEffect(() => {
    showLoader();
    getCustomers()
      .then((resp) => {
        setCustomers(resp.data);
      })
      .catch((err) => {
        console.log(err);
      })

  }, [setOderSale]);

  useEffect(() => {
    getOrderStatuses().then((resp) => {
      setOrderStatuses(resp.data);
    }).catch((err) => {
      console.log(err);
    })
  }, [setOrderStatuses]);

  useEffect(() => {
    getCustomers().then((resp) => {
      setCustomers(resp.data);
    }).catch((err) => {
      console.log(err);
    })
  }, [setCustomers]);


  const fetchSaleOrders = () => {
    let param = "";
    if (from) {
      param += `OrderDate_gte=${moment(from).format("YYYY-MM-DD")} 00:00:00`
    }
    if (to) {
      if (param.length > 0) param += `&`
      param += `OrderDate_lte=${moment(to).format("YYYY-MM-DD")} 23:59:59`
    }
    if (selCustomer) {
      if (param.length > 0) param += `&`
      param += `Customer=${selCustomer._id}`
    }
    if (selOrderStatus) {
      if (param.length > 0) param += `&`
      param += `OrderStatus=${selOrderStatus._id}`
    }
    console.log("Framed param", param);
    showLoader();
    getSaleOrder(param).then((resp) => {
      setOderSale(resp.data);
      hideLoader();
    }).catch((err) => {
      hideLoader();
      console.log(err);
    })
  }


  useEffect(() => {
    fetchSaleOrders();
  }, [setOderSale, from, to, selCustomer, selOrderStatus])

  const exportToExcel = (data, filename) => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${filename}.xlsx`);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        {/* {saleOrderPopUp.open && <ViewSaleOrderPopUp isopen={saleOrderPopUp.open} details={saleOrderPopUp.details} handleclose={() => { setSaleOrderPopUp({ open: false, details: null }) }} />} */}
        {updatesaleOrderPopUp.open && <UpdateSaleOrderPopUp isopen={updatesaleOrderPopUp.open} details={updatesaleOrderPopUp.details} handleclose={() => { setUpdateSaleOrderPopUp({ open: false, details: null }) }} handlesuccess={() => { setUpdateSaleOrderPopUp({ open: false, details: null }); fetchSaleOrders(); }} />}
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor={sidenavColor}
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  SaleOrders
                </MDTypography>
              </MDBox>
              <Stack
                direction="row-reverse"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <MDBox pt={3} pr={3}>
                  <MDButton variant="gradient" color="success"
                    onClick={() => {
                      navigate('/saleorders/add');
                    }}>
                    <Icon>add</Icon>&nbsp;
                    Create
                  </MDButton>
                </MDBox>
                <MDBox pt={3}>
                  {/* <MDButton variant="gradient" color="warning" onClick={() => {
                    exportToExcel(formsaleorders(ordersale), moment().unix());
                  }}>
                    <Icon>download</Icon>&nbsp;
                    Export
                  </MDButton> */}
                </MDBox>
              </Stack>
              <MDBox pt={3} px={2} py={3}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={3} xl={3}>
                    <MDInput fullWidth type="date" label={"From"} value={moment(from).format("YYYY-MM-DD")} onChange={(event) => {
                      setFrom(event.target.valueAsDate);
                    }}></MDInput>
                  </Grid>
                  <Grid item xs={12} md={3} xl={3}>
                    <MDInput fullWidth type="date" label={"To"} value={moment(to).format("YYYY-MM-DD")} onChange={(event) => {
                      setTo(event.target.valueAsDate);
                    }}></MDInput>
                  </Grid>
                  <Grid item xs={12} md={3} xl={3}>
                    <Autocomplete
                      label={"Order Status"}
                      sx={{ flexGrow: 1 }}
                      options={orderstatuses}
                      getOptionLabel={(option) => option.OrderStatusName || ''}
                      renderInput={(params) => <MDInput {...params} label={"Order Status"} fullWidth />}
                      onChange={(event, newValue) => {
                        setSelOrderStatus(newValue);
                      }}
                      value={selOrderStatus}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} xl={3}>
                    <Autocomplete
                      label={"Customers"}
                      sx={{ flexGrow: 1 }}
                      options={customers}
                      getOptionLabel={(option) => option.CustomerName || ''}
                      renderInput={(params) => <MDInput {...params} label={"Customers"} fullWidth />}
                      onChange={(event, newValue) => {
                        setSelCustomer(newValue);
                      }}
                      value={selCustomer}
                    />
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox sx={{ maxHeight: "50vh", overflow: 'auto' }}>
                <DataTable
                  table={{
                    columns: saleordersHeaders,
                    rows: formsaleorders(ordersale, setUpdateSaleOrderPopUp)
                  }}
                  noEndBorder
                  canSearch
                  onRowClick={(data, ind) => { console.log(data, ind); setUpdateSaleOrderPopUp({ open: true, details: ordersale[ind] }) }}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}
