// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { Bar } from "react-chartjs-2";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import { Doughnut } from "react-chartjs-2";



export default function SectionDetailsCard(props) {

    const [controller] = useMaterialUIController();
    const { sidenavColor, darkMode } = controller;
    const [menu, setMenu] = useState(null);

    const openMenu = ({ currentTarget }) => setMenu(currentTarget);
    const closeMenu = () => setMenu(null);

    const getTimeDiff = () => {
        let givenDate = new Date();
        let now = new Date();
        let differenceInMilliseconds = now - givenDate;
        let differenceInHours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
        let differenceInMinutes = Math.floor((differenceInMilliseconds / (1000 * 60)) % 60);
        let formattedDifference = `${differenceInHours} hours ${differenceInMinutes} minutes`;
        return formattedDifference;
    }

    return (
        <Card sx={{ boxShadow: 6, marginTop: 4 }} mt={4}>
            <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
                <MDBox
                    variant="gradient"
                    bgColor={sidenavColor}
                    color={sidenavColor === "light" ? "dark" : "white"}
                    coloredShadow={sidenavColor}
                    borderRadius="xl"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    px={3}
                    // width="4rem"
                    // height="4rem"
                    mt={-3}
                >
                    {/* <Icon fontSize="medium" color="inherit">
                        {props.icon}
                    </Icon> */}
                    <MDTypography sx={{ color: "white" }} color={"white"}>{props.name}</MDTypography>
                </MDBox>
            </MDBox>
            <MDBox pt={1} py={2} px={3}>
                <Grid container spacing={2}>
                    <Grid item xl={2} md={4} xs={6} sx={{ textAlign: 'center', textOverflow: 'ellipsis' }}>
                        <Grid container alignItems={"center"} flexDirection={"column"}>
                            <MDTypography variant="button" fontWeight="light" color="text">
                                {"Run Time"}
                            </MDTypography>
                            <MDTypography variant="h3" color={sidenavColor} sx={{ display: "flex", textAlign: "center", alignItems: "flex-end" }}>{props.runtime}<MDTypography ml={1} variant="h6">{"mins"}</MDTypography></MDTypography>
                        </Grid>
                    </Grid>
                    <Grid item xl={2} md={4} xs={6} sx={{ textAlign: 'left', textOverflow: 'ellipsis' }}>
                        <Grid container alignItems={"center"} flexDirection={"column"}>
                            <MDTypography variant="button" fontWeight="light" color="text">
                                {"Down Time"}
                            </MDTypography>
                            <MDTypography variant="h3" color={sidenavColor} sx={{ display: "flex", textAlign: "center", alignItems: "flex-end" }}>{props.downtime}<MDTypography ml={1} variant="h6">{"mins"}</MDTypography></MDTypography>
                        </Grid>
                    </Grid>
                    <Grid item xl={2} md={4} xs={6} sx={{ textAlign: 'left', textOverflow: 'ellipsis' }}>
                        <Grid container alignItems={"center"} flexDirection={"column"}>
                            <MDTypography variant="button" fontWeight="light" color="text">
                                {"Availablity"}
                            </MDTypography>
                            <MDTypography variant="h3" color={sidenavColor} sx={{ display: "flex", textAlign: "center", alignItems: "flex-end" }}>{props.availability}<MDTypography ml={1} variant="h6">{" %"}</MDTypography></MDTypography>
                        </Grid>
                    </Grid>
                    <Grid item xl={2} md={4} xs={6} sx={{ textAlign: 'left', textOverflow: 'ellipsis' }}>
                        <Grid container alignItems={"center"} flexDirection={"column"}>
                            <MDTypography variant="button" fontWeight="light" color="text">
                                {"Performance"}
                            </MDTypography>
                            <MDTypography variant="h3" color={sidenavColor} sx={{ display: "flex", textAlign: "center", alignItems: "flex-end" }}>{props.performace}<MDTypography ml={1} variant="h6">{" %"}</MDTypography></MDTypography>
                        </Grid>
                    </Grid>
                    <Grid item xl={2} md={4} xs={6} sx={{ textAlign: 'left', textOverflow: 'ellipsis' }}>
                        <Grid container alignItems={"center"} flexDirection={"column"}>
                            <MDTypography variant="button" fontWeight="light" color="text">
                                {"Quality"}
                            </MDTypography>
                            <MDTypography variant="h3" color={sidenavColor} sx={{ display: "flex", textAlign: "center", alignItems: "flex-end" }}>{props.quality}<MDTypography ml={1} variant="h6">{" %"}</MDTypography></MDTypography>
                        </Grid>
                    </Grid>
                    <Grid item xl={2} md={4} xs={6} sx={{ textAlign: 'left', textOverflow: 'ellipsis' }}>
                        <Grid container alignItems={"center"} flexDirection={"column"}>
                            <MDTypography variant="button" fontWeight="light" color="text">
                                {"OEE"}
                            </MDTypography>
                            <MDTypography variant="h3" color={sidenavColor} sx={{ display: "flex", textAlign: "center", alignItems: "flex-end" }}>{props.oee}<MDTypography ml={1} variant="h6">{" %"}</MDTypography></MDTypography>
                        </Grid>
                    </Grid>
                </Grid>
            </MDBox>
        </Card >);
}