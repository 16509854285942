import React, { createContext, useContext, useState } from 'react';
import MDSnackbar from "components/MDSnackbar";

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {

    const [showSB, setShowSB] = useState({ open: false, color: 'error', title: "", content: "", date: null });
    // const [showSB, setShowSB] = useState({ open: true, color: 'success', title: "Test Message", content: "Test Message to check the notification", date: null });

    const closeSuccessSB = () => setShowSB({ ...showSB, open: false });
    const showNotification = (color, title, content, date = null) => {
        setShowSB({ open: true, color: color, title: title, content: content, date: date });
    }

    return (
        <NotificationContext.Provider value={{ showNotification }}>
            {children}
            <MDSnackbar
                color={showSB.color}
                icon={(showSB.color === "success") ? 'check' : (showSB.color === "error") ? 'warning' : (showSB.color === "info") ? 'notifications' : 'star'}
                title={showSB.title}
                content={showSB.content}
                open={showSB.open}
                onClose={closeSuccessSB}
                close={closeSuccessSB}
            />
        </NotificationContext.Provider>
    );
};

export const useNotification = () => useContext(NotificationContext);