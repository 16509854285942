/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

import { Icon } from "@mui/material";
import MDButton from "components/MDButton";

export default function TableData() {

    const formRecipeRows = (data, setRecipePopup) => {
        let result = [];
        for (let dt of data) {
            result.push({
                name: dt.RecipeName,
                code: dt.RecipeCode,
                product: dt.Product.ProductName,
                createdby: dt.CreatedBy.username,
                description: dt.Description,
                actions: <MDButton variant="outlined" color="info" iconOnly onClick={() => {
                    setRecipePopup({ open: true, details: dt });
                }}>
                    <Icon>edit</Icon>
                </MDButton>
            });
        }
        return result
    }

    return {
        formRecipeRows,
        productHeaders: [
            { Header: "Name", accessor: "name", align: "left" },
            { Header: "Code", accessor: "code", align: "center" },
            { Header: "Product Name", accessor: "product", align: "center" },
            { Header: "Created By", accessor: "createdby", align: "center" },
            { Header: "Description", accessor: "description", width: "30%", align: "center" },
            { Header: "Actions", accessor: "actions", align: "center" }
        ],
        sampleRows: [
            {
                name: "Apple Mix 1",
                code: "AM1",
                product: "Apple Juice",
                ingredients: [1, 2, 3, 4],
                description: "Apple sio mix variant 3"
            },
            {
                name: "Apple Mix 1",
                code: "AM1",
                product: "Apple Juice",
                ingredients: [1, 2, 3, 4],
                description: "Apple sio mix variant 3"
            },
            {
                name: "Apple Mix 1",
                code: "AM1",
                product: "Apple Juice",
                ingredients: [1, 2, 3, 4],
                description: "Apple sio mix variant 3"
            },
            {
                name: "Apple Mix 1",
                code: "AM1",
                product: "Apple Juice",
                ingredients: [1, 2, 3, 4],
                description: "Apple sio mix variant 3"
            }
        ],
    };
}
