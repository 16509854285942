// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from '@mui/material/Stack';
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import TableData from "./data/tableColumns";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import MDButton from "components/MDButton";
import { getRecipes } from "network/service";
import { useLoading } from "context/loading.context";
import { useMaterialUIController } from "context";
import AddEditRecipePopup from "./components/AddEditRecipePopup";


function Recipes() {
    const { showLoader, hideLoader } = useLoading();
    const { productHeaders, sampleRows, formRecipeRows } = TableData();
    const [controller, dispatch] = useMaterialUIController();
    const [recipePopup, setRecipePopup] = useState({ open: false, details: null });
    const { sidenavColor } = controller;
    const navigate = useNavigate();
    const [recipes, setRecipes] = useState([]);

    const fetchRecipes = () => {
        showLoader();
        getRecipes().then((resp) => {
            setRecipes(resp.data);
            hideLoader();
        }).catch((err) => {
            hideLoader();
            console.log(err);
        })
    }

    useEffect(() => {
        fetchRecipes();
    }, [setRecipes])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                {recipePopup.open && <AddEditRecipePopup isopen={recipePopup.open} details={recipePopup.details} handleclose={() => { setRecipePopup({ open: false, details: null }) }} handlesuccess={() => { setRecipePopup({ open: false, details: null }), fetchRecipes()}} />}
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor={sidenavColor}
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Recipes Table
                                </MDTypography>
                            </MDBox>
                            <Stack
                                direction="row-reverse"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={2}
                            >
                                <MDBox pt={3} pr={3}>
                                    <MDButton variant="gradient" color="success"
                                        onClick={() => {
                                            setRecipePopup({ open: true, details: null });
                                        }}>
                                        <Icon>add</Icon>&nbsp;
                                        Create
                                    </MDButton>
                                </MDBox>
                            </Stack>

                            <MDBox sx={{ maxHeight: "50vh", overflow: 'auto' }}>
                                <DataTable
                                    table={{
                                        columns: productHeaders,
                                        rows: formRecipeRows(recipes, setRecipePopup)
                                    }}
                                    noEndBorder
                                    canSearch
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Recipes;
