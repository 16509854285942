/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useState } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Bar } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";

// // ReportsBarChart configurations
// import configs from "examples/Charts/BarCharts/ReportsBarChart/configs";

import configs from "../ReportsLineChart/configs";
const moment = require("moment");

function ReportsBarChart({ color, title, description, date, chart, rangeChange }) {
  const { data, options } = configs(chart.labels || [], chart.datasets || {});
  const [filter, setFilter] = useState(0);

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox padding="1rem">
        <MDBox pb={1} px={1}>
          <Grid container sx={{ alignItems: 'center' }} spacing={2}>
            <Grid item sx={{ flexGrow: 1 }}>
              <MDTypography variant="h6" textTransform="capitalize">
                {title}
              </MDTypography>
              <MDTypography component="div" variant="button" color="text" fontWeight="light">
                {description}
              </MDTypography>
              {/* <Divider /> */}
              {/* <MDBox display="flex" alignItems="center">
            <MDTypography variant="button" color="text" lineHeight={1} sx={{ mt: 0.15, mr: 0.5 }}>
              <Icon>schedule</Icon>
            </MDTypography>
            <MDTypography variant="button" color="text" fontWeight="light">
              {date}
            </MDTypography>
          </MDBox> */}
            </Grid>
            <Grid item>
              <FormControl
                sx={{ m: 0 }}
                fullWidth
              >
                <InputLabel id="demo-simple-select-label">Time Range</InputLabel>
                <Select
                  label="Availabilty"
                  labelId="demo-simple-select-label"
                  style={{ height: 45, paddingRight: 10 }}
                  value={filter}
                  onChange={(e) => {
                    setFilter(e.target.value);
                    let from, to;
                    switch (e.target.value) {
                      case 0:
                        from = moment().startOf('week').format("YYYY-MM-DD");
                        to = moment().endOf('week').format("YYYY-MM-DD");
                        break;
                      case 1:
                        from = moment().subtract(1, 'week').startOf('week').format("YYYY-MM-DD");
                        to = moment().subtract(1, 'week').endOf('week').format("YYYY-MM-DD");
                        break;
                      case 2:
                        from = moment().startOf('month').format("YYYY-MM-DD");
                        to = moment().endOf('month').format("YYYY-MM-DD");
                        break;
                      case 3:
                        from = moment().subtract(1, 'month').startOf('month').format("YYYY-MM-DD");
                        to = moment().subtract(1, 'month').endOf('month').format("YYYY-MM-DD");
                        break;
                      default:
                        from = moment().startOf('week').format("YYYY-MM-DD");
                        to = moment().endOf('week').format("YYYY-MM-DD");
                        break;
                    }
                    rangeChange({ from, to });
                  }}
                  displayEmpty
                  fullWidth
                  IconComponent={() => <ArrowDropDown />}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value={0}>{"This Week"}</MenuItem>
                  <MenuItem value={1}>{"Last Week"}</MenuItem>
                  <MenuItem value={2}>{"This Month"}</MenuItem>
                  <MenuItem value={3}>{"Last Month"}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </MDBox>
        {useMemo(
          () => (
            <MDBox
              variant="gradient"
              bgColor={color}
              borderRadius="lg"
              coloredShadow={color}
              py={2}
              pr={0.5}
              height="12.5rem"
            >
              <Bar data={data} options={options} />
            </MDBox>
          ),
          [chart, color]
        )}

      </MDBox>
    </Card>
  );
}


// Setting default values for the props of ReportsBarChart
ReportsBarChart.defaultProps = {
  color: "dark",
  description: "",
};

// Typechecking props for the ReportsBarChart
ReportsBarChart.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  date: PropTypes.string.isRequired,
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
};

export default ReportsBarChart;
