/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */

import { Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import moment from "moment";

export default function TableData() {


    function getBadgeStatus(data) {
        let colour = "info";
        let content = "Pending";
        if (data.OrderStatus.OrderStatusCode === "CMPL") {
            colour = "success";
            content = "Closed";
        }
        else if (data.OrderStatus.OrderStatusCode === "PRTL") {
            colour = "warning";
            content = "Partial";
        }
        return (<MDBox ml={-1}>
            <MDBadge badgeContent={content} color={colour} variant="gradient" size="sm" />
        </MDBox>);
    }

    const formsaleorders = (data, setSaleOrderPopUp) => {
        let result = [];
        for (let dt of data) {
            result.push({
                number: dt.SaleOrderNumber,
                date: moment(dt.OrderDate).format("DD-MM-YYYY"),
                delivery: moment(dt.RequiredDeliveryDate).format("DD-MM-YYYY"),
                total: dt.TotalAmount,
                customer: dt.Customer.CustomerName,
                cust_code: dt.Customer.CustomerCode,
                // status: dt.OrderStatus.OrderStatusName,
                status: getBadgeStatus(dt),
                actual: dt.ActualAmount ? dt.ActualAmount : 0,
                action: <MDButton variant="outlined" color="info" iconOnly onClick={() => {
                    setSaleOrderPopUp({ open: true, details: dt });
                }}>
                    <Icon>edit</Icon>
                </MDButton>
            });
        }
        return result
    }

    return {
        formsaleorders,
        saleordersHeaders: [
            { Header: "SaleOrder Number.", accessor: "number", align: "center" },
            { Header: "Date", accessor: "date", align: "center" },
            { Header: "Required Delivery Date", accessor: "delivery", align: "center" },
            { Header: "Customer", accessor: "customer", align: "center" },
            { Header: "Total Amount", accessor: "total", align: "center" },
            { Header: "Actual Amount", accessor: "actual", align: "center" },
            { Header: "Status", accessor: "status", align: "center" },
            // { Header: "Action", accessor: "action", align: "center" }
        ],
        sampleRows: [
            {
                number: "251",
                date: "25-03-2024",
                items: 5,
                total: 3000,
                customer: "Nirals Enterprises",
                action: "View"
            }
        ],
    };
}
