/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
// import Modal from "@mui/material/Modal";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Divider from "@mui/material/Divider";
import { Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";

import { getSaleOrderDetails } from "network/service";
import { useNotification } from "context/notification.context";
import moment from "moment";
import { updateSaleOrder } from "network/service";


export default function UpdateSaleOrderPopUp(props) {
    const { showNotification } = useNotification();
    const [loading, setLoading] = useState(false);
    const [newField, setNewField] = useState({ name: "", code: "", product: null, quantity: "0", description: "" });
    const [allIngredients, setAllIngredients] = useState([]);
    const [selIngredient, setSelIngredient] = useState(null);
    const [allFinishedProducts, setAllFinishedProducts] = useState([]);
    const [tempIngredient, setTempIngredient] = useState({ ingredient: null, quantity: 0 });
    const [recipeIngredients, setRecipeIngredients] = useState([]);

    const [SaleOrdersItem, setSaleOderItem] = useState([]);
    const [updatedTotal, setUpdatedTotal] = useState(0);

    useEffect(() => {
        if (props.details) {
            getSaleOrderDetails(`SaleOrder=${props.details._id}`).then((resp) => {
                console.log("SaleOrderDetails", resp.data);
                setSaleOderItem(resp.data);
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [setSaleOderItem]);

    const updateSaleOrderDetails = (closed) => {
        setLoading(true);
        let temp_total = 0;
        for (let sct of SaleOrdersItem) {
            temp_total += parseFloat(sct.QuantityDelivered) * sct.UnitPrice;
        }
        updateSaleOrder({
            closed,
            ...props.details,
            ActualAmount: parseFloat(temp_total.toFixed(2)),
            SaleOrderDetails: [...SaleOrdersItem]
        }).then((resp) => {
            console.log(resp.data);
            setLoading(false);
            props.handlesuccess();
        }).catch((err) => {
            setLoading(false);
            console.log(err);
        })
    }


    function convertLiters(liters) {
        if (liters >= 1) {
            return `${liters} L`;
        } else {
            const milliliters = liters * 1000;
            return `${milliliters} ml`;
        }
    }

    const getProductName = (data) => {
        return `${data.Product.ProductName} ${data.UnitsInCollection}x${convertLiters(data.QtyOfUnit)}`;
    }


    const formsaleorders = (datum) => {
        let result = [];
        let temp_total = 0;
        for (let dt in datum) {
            result.push({
                name: getProductName(datum[dt].InventoryID),
                quantityodered: datum[dt].QuantityOrdered,
                // quantitydelivered: data.QuantityDelivered,
                quantitydelivered: (props.details.OrderStatus.OrderStatusCode === "CMPL") ? datum[dt].QuantityDelivered : <MDInput type="number" value={datum[dt].QuantityDelivered}
                onChange={(e) => {
                    let value = e.target.value;
                
                    // Allow only positive values
                    if (!isNaN(value) && Number(value) >= 0) {
                        let temp = [...datum];
                        temp[dt].QuantityDelivered = value;
                        setSaleOderItem(temp);
                    }
                }}
                ></MDInput>,
                rate: datum[dt].UnitPrice,
                amount: datum[dt].Subtotal,
                available: datum[dt].InventoryID.QuantityOnHand,
                dlyrate: (parseFloat(datum[dt].QuantityDelivered) * datum[dt].UnitPrice).toFixed(2)
            });
            temp_total += parseFloat(datum[dt].QuantityDelivered) * datum[dt].UnitPrice;
        }
        result.push({
            quantityodered: "Total",
            amount: props.details.TotalAmount,
            dlyrate: temp_total.toFixed(2),
            available: "Total"
        });
        // setUpdatedTotal(parseFloat(temp_total.toFixed(2)));
        return result;
    }

    return (
        <Dialog
            id="modal-display"
            open={props.isopen}
            aria-labelledby="form-dialog-title"
            aria-describedby="modal-modal-description"
            maxWidth="90%"
            maxHeight="90%"
            onClose={props.handleclose}
            disableBackdropClick
        >
            <MDBox>
                <DialogTitle>SaleOder Details</DialogTitle>
            </MDBox>
            <DialogContent sx={{ minWidth: 500 }}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={4} xl={4}>
                                <MDInput
                                    label="Customer Name"
                                    value={props.details.Customer.CustomerName}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={4} xl={4}>
                                <MDInput
                                    label="Customer Code"
                                    value={props.details.Customer.CustomerCode}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} md={4} xl={4}>
                                <MDInput
                                    label="Date"
                                    value={moment(props.details.createdAt).format("DD-MM-YYYY")}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid item>
                        <DataTable
                            table={{
                                columns: [
                                    { Header: "Item", accessor: "name", align: "center" },
                                    { Header: "Rate", accessor: "rate", align: "center" },
                                    { Header: "Quantity Ordered", accessor: "quantityodered", align: "center" },
                                    { Header: "Amount", accessor: "amount", align: "center" },
                                    { Header: "Quantity Delivered", accessor: "quantitydelivered", align: "center" },
                                    { Header: "Available", accessor: "available", align: "center" },
                                    { Header: "Delivery Amount", accessor: "dlyrate", align: "center" },
                                ],
                                rows: formsaleorders(SaleOrdersItem)
                            }}
                            showTotalEntries={false}
                            isSorted={false}
                            noEndBorder
                            entriesPerPage={false}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <MDButton variant="gradient" color="warning" onClick={() => { updateSaleOrderDetails(true) }}
                    disabled={loading || (props.details.OrderStatus.OrderStatusCode === "CMPL")}>
                    {/* <Icon>bill</Icon>&nbsp; */}
                    Close Order
                </MDButton>
                <MDButton variant="gradient" color="error" onClick={props.handleclose}
                    disabled={loading}>
                    <Icon>close</Icon>&nbsp;
                    Cancel
                </MDButton>
                <MDButton variant="gradient" color="success" onClick={() => { updateSaleOrderDetails(false) }}
                    disabled={loading || (props.details.OrderStatus.OrderStatusCode === "CMPL")}>
                    <Icon>save</Icon>&nbsp;
                    Update
                </MDButton>
            </DialogActions>
        </Dialog>

    )
};
