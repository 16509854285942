import Lottie from 'react-lottie';
import loader from 'assets/lottie/loader.json';

export default function Loader() {
    const defaultOptions = {
        loop: true, // Set to true if you want the animation to loop
        autoplay: true, // Set to true to start the animation when the component mounts
        animationData: loader, // The animation data
    }
    return (
        
            <div style={{
                display: 'flex',
                justifyContent: 'center', // Center horizontally
                alignItems: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 999, // Adjust the z-index value as needed
            }}>
                <Lottie options={defaultOptions} height={250} width={250} />
            </div>
    );
}