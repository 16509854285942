// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from '@mui/material/Stack';
import Icon from "@mui/material/Icon";

import * as XLSX from 'xlsx';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import TableData from "./data/tableColumns";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import MDButton from "components/MDButton";
import { getBills, getSuppliers } from "network/service";
import { useLoading } from "context/loading.context";
import { useMaterialUIController } from "context";

import moment from "moment";
import ViewBillDetailsPopup from "./components/ViewBillPopup";
import MDInput from "components/MDInput";
import { Autocomplete } from "@mui/material";


function Bills() {
    const { showLoader, hideLoader } = useLoading();
    const { billHeaders, sampleRows, formBillRows } = TableData();
    const [controller, dispatch] = useMaterialUIController();
    const [from, setFrom] = useState(moment().subtract(30, 'days').toDate());
    const [to, setTo] = useState(new Date());
    const [selSupplier, setSelSupplier] = useState(null);
    const [suppliers, setSuppliers] = useState([]);
    const [detailsPopup, setDetailsPopup] = useState({ open: false, details: null });
    const { sidenavColor } = controller;
    const navigate = useNavigate();
    const [bills, setBills] = useState([]);

    // useEffect(() => {
    //     showLoader();
    //     getBills().then((resp) => {
    //         setBills(resp.data);
    //         hideLoader();
    //     }).catch((err) => {
    //         hideLoader();
    //         console.log(err);
    //     })
    // }, [setBills])

    useEffect(() => {
        getSuppliers().then((resp) => {
            setSuppliers(resp.data);
        }).catch((err) => {
            console.log(err);
        })
    }, [setSuppliers])

    useEffect(() => {
        let param = ``;
        if (from) {
            param += `BillDate_gte=${moment(from).format("YYYY-MM-DD")}`
        }
        if (to) {
            if (param.length > 0) param += `&`
            param += `BillDate_lte=${moment(to).format("YYYY-MM-DD")} 23:59:59`
        }
        if (selSupplier) {
            if (param.length > 0) param += `&`
            param += `SupplierID=${selSupplier._id}`
        }
        console.log("Framed param", param);
        showLoader();
        getBills(param).then((resp) => {
            setBills(resp.data);
            hideLoader();
        }).catch((err) => {
            hideLoader();
            console.log(err);
        })
    }, [setBills, from, to, selSupplier])



    const exportToExcel = (data, filename) => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, `${filename}.xlsx`);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                {detailsPopup.open && <ViewBillDetailsPopup isopen={detailsPopup.open} details={detailsPopup.details} handleclose={() => { setDetailsPopup({ open: false, details: null }) }} />}
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor={sidenavColor}
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Bills
                                </MDTypography>
                            </MDBox>

                            <Grid container spacing={2} alignItems="center" p={3}>
                                <Grid item xs={12} md={3} xl={3}>
                                    <MDInput
                                        fullWidth
                                        type="date"
                                        label={"From"}
                                        value={moment(from).format("YYYY-MM-DD")}
                                        onChange={(event) => setFrom(event.target.valueAsDate)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} xl={3}>
                                    <MDInput
                                        fullWidth
                                        type="date"
                                        label={"To"}
                                        value={moment(to).format("YYYY-MM-DD")}
                                        onChange={(event) => setTo(event.target.valueAsDate)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Autocomplete
                                        label={"Supplier"}
                                        sx={{ flexGrow: 1 }}
                                        options={suppliers}
                                        getOptionLabel={(option) => option.SupplierName || ''}
                                        renderInput={(params) => <MDInput {...params} label={"Supplier"} fullWidth />}
                                        onChange={(event, newValue) => setSelSupplier(newValue)}
                                        value={selSupplier}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} xl={3}>
                                    <Stack direction="row" spacing={2} justifyContent="flex-end">
                                        {/* <MDButton
                                            variant="gradient"
                                            color="warning"
                                            onClick={() => exportToExcel(formBillRows([]), moment().unix())}
                                        >
                                            <Icon>download</Icon>&nbsp;
                                            Export
                                        </MDButton> */}
                                        <MDButton
                                            variant="gradient"
                                            color="success"
                                            onClick={() => navigate('/purchases/bills/add')}
                                        >
                                            <Icon>add</Icon>&nbsp;
                                            Purchase
                                        </MDButton>
                                    </Stack>
                                </Grid>
                            </Grid>

                            <MDBox sx={{ maxHeight: "50vh", overflow: 'auto' }}>
                                <DataTable
                                    table={{
                                        columns: billHeaders,
                                        rows: formBillRows(bills, setDetailsPopup)
                                    }}
                                    noEndBorder
                                    canSearch
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Bills;
