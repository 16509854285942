/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

import prodcutIcons from "../src/assets/images/icons/Icons/product.png"
import LocalDrinkIcon from '@mui/icons-material/LocalDrink';
// @mui icons
import Icon from "@mui/material/Icon";
import Products from "layouts/inventory/products";
import AddProduct from "layouts/inventory/products/components/AddProduct";
import Recipes from "layouts/inventory/recipes";
import Suppliers from "layouts/purchases/suppliers";
import Customers from "layouts/orders/Customers";
import Bills from "layouts/purchases/bills";
import AddBill from "layouts/purchases/bills/components/AddBill";
import SaleOrders from "layouts/orders/saleoders";
import Replenishments from "layouts/inventory/replenishment";
import ProductionLots from "layouts/production/lots";
import ProductionLotDetails from "layouts/production/details";
import LotsList from "layouts/production/lotlists";

import AddSaleOrders from "layouts/orders/saleoders/compoents/AddSaleOrders";
import Equipments from "layouts/Machineries/equipments";
import Logs from "layouts/Machineries/maintenance/logs";
import Schedules from "layouts/Machineries/maintenance/schedules";

import ProductDashboard from "layouts/inventory/products/ProductDashboard"


//Iocns
import Dashboard_icon from "./assets/images/icons/dashboard.jpg"
import iconcart from "./assets/images/icons/product_icon.png"
import recipi_icon from "./assets/images/icons/recipes_icons.png"
import supplier_iocns from "./assets/images/icons/suppliers_icon.png"
import replenishment_icons from "./assets/images/icons/rep_icon.png"
import Biils_icons from "./assets/images/icons/Biils_icons.png"
import Customers_iocns from "./assets/images/icons/customer_icons.png"
import saleorders_icons from "./assets/images/icons/saleorder_icons.png"
import lotdashbaord_icon from "./assets/images/icons/lotdashboard_icon.png"
import lotlist_icon from "./assets/images/icons/lotlist_icons.png"
import equipments_icon from "./assets/images/icons/eqpimet_icons.png"
import log_icon from "./assets/images/icons/log_icon.png"
import Schedules_icons from "./assets/images/icons/schedule_icon.png"
import incidnet_icon from "assets/images/icons/incident_icon.png"



import Incidents from "layouts/Machineries/maintenance/Incidents";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "/home/dashboard",
    icon: <img src={Dashboard_icon} width={20}></img>,
    route: "/home/dashboard",
    component: <Dashboard />,
  },
  {
    type: "divider",
    name: "divider",
  },
  
  {
    type: "title",
    title: "Inventory",
  },
  {
    type: "hidden",
    name: "Item",
    key: "/products",
    route: "/inventory/products/item",
    component: <ProductDashboard />,
  },
  {
    type: "collapse",
    name: "Products",
    key: "products",
    icon: <img src={iconcart} width={30}></img>,
    route: "/inventory/products",
    component: <Products/>,
  },
  {
    type: "hidden",
    name: "Products/add",
    key: "products/add",
    icon: <></>,
    route: "/inventory/products/add",
    component: <AddProduct />,
  },
  {
    type: "collapse",
    name: "Recipes",
    key: "recipes",
    icon: <img src={recipi_icon} width={26}></img>,
    route: "/inventory/recipes",
    component: <Recipes />,
  },
  {
    type: "collapse",
    name: "Replenishment",
    key: "replenishment",
    icon: <img src={replenishment_icons} width={34}></img>,
    route: "/inventory/replenishment",
    component: <Replenishments />,
  },
  {
    type: "divider",
    name: "divider",
  },
  {
    type: "title",
    title: "Expenses",
  },
  {
    type: "collapse",
    name: "Suppliers",
    key: "suppliers",
    icon: <img src={supplier_iocns} width={30}></img>,
    route: "/suppliers",
    component: <Suppliers />,
  },
  {
    type: "collapse",
    name: "Bills",
    key: "bills",
    icon: <img src={Biils_icons} width={28}></img>,
    route: "/purchases/bills",
    component: <Bills />,
  },
  {
    type: "hidden",
    name: "Bills/Add",
    key: "Bills/add",
    icon: <></>,
    route: "/purchases/bills/add",
    component: <AddBill />,
  },
  {
    type: "divider",
    name: "divider",
  },
  {
    type: "title",
    title: "Sales",
  },
  {
    type: "collapse",
    name: "Customers",
    key: "customers",
    icon: <img src={Customers_iocns} width={34}></img>,
    route: "/customers",
    component: <Customers />,
  },
  {
    type: "collapse",
    name: "Saleorder",
    key: "saleorder",
    icon: <img src={saleorders_icons} width={28}></img>,
    route: "/saleorders",
    component: <SaleOrders />,
  },
  {
    type: "hidden",
    name: "saleorder/add",
    key: "saleorder/add",
    icon: <></>,
    route: "/saleorders/add",
    component: <AddSaleOrders />,
  },
  {
    type: "divider",
    name: "divider",
  },
  {
    type: "title",
    title: "Production",
  },
  {
    type: "collapse",
    name: "Lot Dashboard",
    key: "production/lots/dashboard",
    icon: <img src={lotdashbaord_icon} width={22}></img>,
    route: "/production/lots/dashboard",
    component: <ProductionLots />,
  },
  {
    type: "collapse",
    name: "Lots List",
    key: "production/lots/list",
    icon: <img src={lotlist_icon} width={24}></img>,
    route: "/production/lots/list",
    component: <LotsList />,
  },
  {
    type: "hidden",
    name: "Lot Details",
    key: "production/details",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/production/lots/list/details",
    component: <ProductionLotDetails />,
  },
  {
    type: "divider",
    name: "divider",
  },
  {
    type: "title",
    title: "Maintenance",
  },
  {
    type: "collapse",
    name: "Equipments",
    key: "equipments",
    icon: <img src={equipments_icon} width={26}></img>,
    route: "/equipments",
    component: <Equipments />,
  },
  {
    type: "collapse",
    name: "Logs",
    key: "logs",
    icon: <img src={log_icon} width={26}></img>,
    route: "/logs",
    component: <Logs />,
  },
  {
    type: "collapse",
    name: "Schedules",
    key: "schedules",
    icon: <img src={Schedules_icons} width={26}></img>,
    route: "/schedules",
    component: <Schedules />,
  },
  {
    type: "collapse",
    name: "Incidents",
    key: "incidents",
    icon: <img src={incidnet_icon} width={28}></img>,
    route: "/incidents",
    component: <Incidents />,
  },
  // {
  //   type: "divider",
  //   name: "divider",
  // },
  // {
  //   type: "collapse",
  //   name: "Notifications",
  //   key: "notifications",
  //   icon: <Icon fontSize="small">notifications</Icon>,
  //   route: "/notifications",
  //   component: <Notifications />,
  // },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   icon: <Icon fontSize="small">table_view</Icon>,
  //   route: "/tables",
  //   component: <Tables />,
  // },
  // ,
  // {
  //   type: "collapse",
  //   name: "Billing",
  //   key: "billing",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/billing",
  //   component: <Billing />,
  // },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
];


export const un_auth_routes = [
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
]
export default routes;
