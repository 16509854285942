// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { Bar } from "react-chartjs-2";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";




export default function LotMainDetailsCard(props) {

    const [controller] = useMaterialUIController();
    const { sidenavColor, darkMode } = controller;
    const [menu, setMenu] = useState(null);

    const openMenu = ({ currentTarget }) => setMenu(currentTarget);
    const closeMenu = () => setMenu(null);

    const getTimeDiff = () => {
        let givenDate = new Date();
        let now = new Date();
        let differenceInMilliseconds = now - givenDate;
        let differenceInHours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
        let differenceInMinutes = Math.floor((differenceInMilliseconds / (1000 * 60)) % 60);
        let formattedDifference = `${differenceInHours} hours ${differenceInMinutes} minutes`;
        return formattedDifference;
    }

    return (
        <Card sx={{ boxShadow: 6 }}>
            <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
                <MDBox
                    variant="gradient"
                    bgColor={sidenavColor}
                    color={sidenavColor === "light" ? "dark" : "white"}
                    coloredShadow={sidenavColor}
                    borderRadius="xl"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="4rem"
                    height="4rem"
                    mt={-3}
                >
                    <Icon fontSize="medium" color="inherit">
                        {props.icon}
                    </Icon>
                </MDBox>
            </MDBox>
            <MDBox pt={1} py={2} px={3}>
                <Grid container spacing={2}>
                    <Grid item xl={6} md={12} xs={6} sx={{ textAlign: 'left', textOverflow: 'ellipsis' }}>
                        <MDTypography variant="button" fontWeight="light" color="text">
                            {"LoT Number"}
                        </MDTypography>
                        <MDTypography variant="h6">{"ABC Recipe"}</MDTypography>
                    </Grid>
                    <Grid item xl={6} md={12} xs={6} sx={{ textAlign: 'right', textOverflow: 'ellipsis' }}>
                        <MDTypography variant="button" fontWeight="light" color="text">
                            {"Date"}
                        </MDTypography>
                        <MDTypography variant="h6">{`45 Ltrs`}</MDTypography>
                    </Grid>
                    <Grid item xl={6} md={12} xs={6} sx={{ textAlign: 'left', textOverflow: 'ellipsis' }}>
                        <MDTypography variant="button" fontWeight="light" color="text">
                            {"Recipe"}
                        </MDTypography>
                        <MDTypography variant="h6">{"ABC Recipe"}</MDTypography>
                    </Grid>
                    <Grid item xl={6} md={12} xs={6} sx={{ textAlign: 'right', textOverflow: 'ellipsis' }}>
                        <MDTypography variant="button" fontWeight="light" color="text">
                            {"Quantity"}
                        </MDTypography>
                        <MDTypography variant="h6">{`45 Ltrs`}</MDTypography>
                    </Grid>
                </Grid>
            </MDBox>
            <MDBox pb={2} px={2}>
                <MDTypography component="p" variant="button" color="text" display="flex">
                    &nbsp;{"Total Run time: "}
                    <MDTypography
                        component="span"
                        variant="button"
                        fontWeight="bold"
                        color={sidenavColor}
                    >
                        {getTimeDiff()}
                    </MDTypography>
                </MDTypography>
            </MDBox>
        </Card>);
}