import { createContext, useState } from "react";
import { useCookies } from "react-cookie";

const tokenCookieName = "app_pulse_token";
const roleCookieName = "app_pulse_role";
const user_doc = "fizzflow_doc";

export const useAuthToken = () => {
  const [cookies, setCookie, removeCookie] = useCookies([tokenCookieName, roleCookieName]);
  const setToken = (value) => setCookie(tokenCookieName, value);
  const removeToken = () => removeCookie(tokenCookieName);
  const setRole = (value) => setCookie(roleCookieName, value);
  const removeRole = () => removeCookie(roleCookieName);
  return [
    cookies[tokenCookieName],
    setToken,
    removeToken,
    cookies[roleCookieName],
    setRole,
    removeRole,
  ];
};

function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  const removeValue = (key) => {
    setStoredValue();
    window.localStorage.removeItem(key);
  };

  const setValue = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue, removeValue];
}

export const AuthContext = createContext({});

const AuthContProvider = (props) => {
  const [authToken, setAuthToken, removeAuthToken, authRole, setAuthRole, removeAuthRole] =
    useAuthToken();
  const [userDoc, setUserDoc] = useLocalStorage(user_doc, {});
  const [isAdmin, setisAdmin] = useState(false);
  // const [authenticated, setAuthenticated] = useState(true);
  const [authenticated, setAuthenticated] = useState(window.localStorage.getItem(user_doc) ? true : false);

  const setRole = (role) => {
    setAuthRole(role);
    setisAdmin(false);
  };

  const setLogin = (token) => {
    setAuthenticated(true);
    window.localStorage.setItem("jwt", token);
    setAuthToken(token);
  };

  const logout = (token) => {
    setisAdmin(false);
    removeAuthRole();
    setAuthenticated(false);
    removeAuthToken();
    window.localStorage.clear();
  };

  const defaultContext = {
    authenticated,
    setLogin,
    setRole,
    logout,
    isAdmin,
    userDoc,
    setUserDoc,
    authToken,
  };

  // eslint-disable-next-line react/prop-types
  return <AuthContext.Provider value={defaultContext}>{props.children}</AuthContext.Provider>;
};

export default AuthContProvider;
