import { Icon } from '@mui/material';
import MDButton from 'components/MDButton';

    
export default function CustomerTable() {
    

    const formCustomersRows = (data, setCustomersPopUp) => {
        let result = [];
        for (let dt of data) {
            result.push({
                name: dt.CustomerName,
                code: dt.CustomerCode,
                    contactperson: dt.ContactPerson,
                    contactnumber: dt.ContactNumber,
                    email: dt.Email,
                    address: dt.Address,
                action: (
                    <MDButton variant="outlined" color="info" iconOnly onClick={() => {
                        setCustomersPopUp({ open: true, details: dt });
                    }}>
                        <Icon>edit</Icon>
                    </MDButton>
                ),
            });
        }
        return result;
    };

    return {
        formCustomersRows,
        customersHeaders: [
            { Header: "CUSTOMER NAME", accessor: "name", align: "center" },
            { Header: "CODE", accessor: "code", align: "center" },
            { Header: "CONTACT PERSON", accessor: "contactperson", align: "center" },
            { Header: "CONTACT NUMBER", accessor: "contactnumber", align: "center" },
            { Header: "EMAIL", accessor: "email", align: "center" },
            { Header: "ADDRESS", accessor: "address", align: "center" },
            { Header: "ACTION", accessor: "action", align: "center" },
        ],
        sampleRows: [
            {
                supplierName: "Nandam Traders",
                code: "NT-001",
                contactPerson: "Nandam Kumar",
                contactNumber: "987654321",
                email: "nandam.traders@example.com",
                address: "sample address 1"
            },
            {
                supplierName: "Nandam Enterprises",
                code: "NE-002",
                contactPerson: "Nandam Raju",
                contactNumber: "987654322",
                email: "nandam.enterprises@example.com",
                address: "sample address 2"
            },
            {
                supplierName: "Nandam traders",
                code: "NE-004",
                contactPerson: "Nandam Raju",
                contactNumber: "987654322",
                email: "nandam.enterprises@example.com",
                address: "sample address 3"
            },
        ],
       
    };
}
