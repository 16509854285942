/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
// import Modal from "@mui/material/Modal";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Divider from "@mui/material/Divider";
import { Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import { stopLot } from "network/service";
import { useNotification } from "context/notification.context";
import moment from "moment";


const material_headers = [
    { Header: "Item", accessor: "item", align: "center" },
    { Header: "Required", accessor: "required", align: "center" },
    { Header: "Available", accessor: "available", align: "center" },
    { Header: "Taken", accessor: "taken", align: "center" },
    // { Header: "UOM", accessor: "uom", align: "center" }
]



export default function StopLotPopup(props) {
    const [loading, setLoading] = useState(false);
    const { showNotification } = useNotification();
    const [output, setOutput] = useState("0");



    useEffect(() => {

    }, []);


    const submit = () => {
        stopLot(props.details._id, { quantity: parseInt(output) }).then((resp) => {
            console.log(resp.data);
            showNotification("success", "Success", "Stopped the lot successfully");
            props.handlesuccess();
        }).catch((err) => {
            showNotification("error", "Failed", "Unable to stop the lot");
            console.log(err);
        });
    }

    return (
        <Dialog
            id="modal-display"
            open={props.isopen}
            aria-labelledby="form-dialog-title"
            aria-describedby="modal-modal-description"
            maxWidth="90%"
            maxHeight="90%"
            onClose={props.handleclose}
            disableBackdropClick
        >
            <MDBox>
                <DialogTitle>Stop Lot</DialogTitle>
            </MDBox>
            <DialogContent sx={{ minWidth: 500 }}>
                <Grid container direction="column" spacing={2} px={2}>
                    <Grid item xs={12} xl={12} md={12} >
                        <Grid container spacing={2}>
                            <Grid item xl={4} md={4} xs={4}>
                                <MDInput label={"Lot No."} fullWidth disabled value={props.details.LotNumber} />
                            </Grid>
                            <Grid item xl={4} md={4} xs={4}>
                                <MDInput label={"Product"} fullWidth disabled value={props.details.Inventory.Product.ProductName} />
                            </Grid>
                            <Grid item xl={4} md={4} xs={4}>
                                <MDInput label={"Produced"} fullWidth type="number" value={output} onChange={(e) => {
                                    setOutput(e.target.value);
                                }} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <MDButton variant="gradient" color="error" onClick={props.handleclose}
                    disabled={loading}>
                    <Icon>close</Icon>&nbsp;
                    Close
                </MDButton>
                <MDButton variant="gradient" color="success"
                    disabled={loading}
                    onClick={() => { submit() }}>
                    <Icon>save</Icon>&nbsp;
                    Save
                </MDButton>
            </DialogActions>
        </Dialog >
    );
}